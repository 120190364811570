export const Profession = ({ value, setValue, professions, setValueText }) => {
  return (
    <div className="flex flex-col">
      <div className="text-[#253238] text-md font-bold">Profession</div>
      <select
        className="rounded-xl border-solid border-2 border-gray-200 py-2 px-3"
        value={value}
        placeholder="Select your profession"
        onChange={(e) => {
          setValue(e.target.value);
          setValueText(e.target.options[e.target.selectedIndex].text);
        }}
      >
        {professions.map((item) => {
          return <option value={item.value}>{item.label}</option>;
        })}
      </select>
    </div>
  );
};

export const Name = ({ firstName, lastName, setFirstname, setLastname }) => (
  <div className="flex">
    <div className="flex justify-between flex-1 gap-4 tablet:flex-col">
      <div className="flex-1 flex flex-col">
        <div className="text-[#253238] flex-1 text-md font-bold">
          First Name
        </div>
        <input
          className="rounded-xl border-solid border-2 border-gray-200 p-2 px-3"
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstname(e.target.value);
          }}
          placeholder="Your given name"
        ></input>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="text-[#253238] flex-1 text-md font-bold">Last Name</div>
        <input
          className="rounded-xl flex-1 border-solid border-2 border-gray-200 p-2 px-3"
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastname(e.target.value);
          }}
          placeholder="Your surname"
        ></input>
      </div>
    </div>
  </div>
);

export const Email = ({ email, setEmail }) => (
  <div className="flex flex-col">
    <div className="text-[#253238] text-md font-bold">Email Address</div>
    <input
      className="rounded-xl border-solid border-2 border-gray-200 p-2 px-3"
      type="text"
      value={email}
      onChange={(e) => {
        setEmail(e.target.value);
      }}
      placeholder="Your email address"
    ></input>
  </div>
);

export const Password = ({ password, setPassword, isReadable }) => (
  <div className="flex flex-col">
    <div className="text-[#253238] text-md font-bold">Password</div>
    <div className="flex flex-row">
      <input
        className="rounded-xl flex-1 border-solid border-2 border-gray-200 p-2 px-3"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      ></input>
    </div>
  </div>
);

export const Password2 = ({ password2, setPassword2, isReadable }) => (
  <div className="flex flex-col">
    <div className="text-[#253238] text-md font-bold">Confirm Password</div>
    <div className="flex flex-row">
      <input
        className="rounded-xl flex-1 border-solid border-2 border-gray-200 py-2 px-3"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
      ></input>
    </div>
  </div>
);

export const Actions = ({ onAdd, title }) => (
  <>
    <button
      onClick={onAdd}
      className="text-xl text-white bg-lumi-blue rounded-tl-[32px] rounded-br-[32px] px-1 py-2 font-bold"
    >
      <span className="drop-shadow-md">{title}</span>
    </button>
  </>
);

export const ErrorMessage = ({ error }) => (
  <p className="text-red-500">{error}</p>
);
