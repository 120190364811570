import React, { useState } from "react";
import usePasswordEye from "../../hooks/usePasswordEye";
import Header from "../../components/Header/index.js";
import Footer from "../../components/Footer/index.js";
import { Link } from "react-router-dom";
import { useUserStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useLoader from "../../hooks/useLoader";
import { validateEmail } from "../../utils/utilityFunctions";
import useEnterKey from "../../hooks/useEnterKey";
import HeroAuthSection from "../../components/HeroAuthSection/index.js";
import {
  Email,
  Password,
  ErrorMessage,
  Actions,
} from "../../components/forms/AuthForm/form.js";

function PractitionerLogin() {
  const { icon, clickHandler, isReadable } = usePasswordEye();
  const { showLoader, closeMessages, showError } = useLoader();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const doLogin = useUserStore((state) => state.doLogin);
  const loginCallback = async () => {
    try {
      setError("");

      if (!email || !validateEmail(email)) {
        setError("Please provide a valid email.");
        return;
      }

      if (!password) {
        setError("Please provide a password.");
        return;
      }

      showLoader("Logging in. Please wait..");
      const res = await doLogin(email, password, "practitioner");
      closeMessages();
      if (res.error) {
        showError(res.message);
        return;
      }

      navigate(`/${res.result.profile.role}/dashboard`);
    } catch (error) {
      closeMessages();
      showError("Something went wrong.");
    }
  };
  useEnterKey(loginCallback);

  return (
    <>
      <Header currentPage={"login"} />
      <HeroAuthSection>
        <div className="flex flex-1 align-center justify-center">
          <div className="flex w-[552px] tablet:w-full mx-8 flex-col align-center justify-center gap-3">
            <h3 className="text-[#69C1BE] font-title text-center font-medium">
              Practitioner Login
            </h3>
            <div className="h-2" />
            <Email email={email} setEmail={setEmail} />
            <Password
              password={password}
              setPassword={setPassword}
              icon={icon}
              clickHandler={clickHandler}
              isReadable={isReadable}
            />
            <ErrorMessage error={error} />
            <Actions onAdd={loginCallback} title={"Log In"} />
            <p className="font-body text-center font-bold">
              <Link
                to="/practitioner-signup"
                className="text-lumi-rose text-bold"
              >
                Sign Up
              </Link>{" "}
              if you don't have an account yet.
            </p>
          </div>
        </div>
      </HeroAuthSection>
      <Footer />
    </>
  );
}

export default PractitionerLogin;
