export function performLogout() {
  localStorage.clear();
  sessionStorage.clear();
  // window.location.href = "/signin";
  window.location.href = "/practitioner-login";
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function formattedDateProvider(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return {
    date:
      date.getDate() +
      " " +
      months[date.getMonth()] +
      ", " +
      date.getFullYear(),
    time: formatAMPM(date),
    day: days[date.getDay()],
  };
}

export function getTimestampData(timestamp) {
  const date = new Date(timestamp * 1000);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return {
    date: date.getDate(),
    month: months[date.getMonth()],
    year: date.getFullYear(),
    time: formatAMPM(date),
    day: days[date.getDay()],
  };
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

//Remove null values from object.
export function cleanObject(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function getUserSessionObject() {
  try {
    const obj = JSON.parse(localStorage.getItem("user-store"));
    return obj.state;
  } catch (e) {
    return null;
  }
}

export function flattenValuesFromSelect(arr) {
  return arr.map((item) => item.value);
}

export function inflateValuesFromSelect(arr, options) {
  return arr.map((item) => {
    let label = item;

    if (options) {
      label = options.filter((obj) => {
        return obj.value === item;
      });
      label = label[0].label;
    }

    return {
      value: item,
      label: label,
    };
  });
}

export function translateStatus(status) {
  if (!status) return "N/A";

  let result = status;

  const map = {
    pending: "Unverified",
    active: "Active/Verified",
    inactive: "Inactive/Suspended",
  };

  result = map[status];

  return result ? result : status;
}

export function isEmptyObject(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

export function zeroPadDateString(dateString) {
  try {
    if (dateString.includes("NaN") || dateString.includes("null"))
      return dateString;

    const pieces = dateString.split("-");
    const result = pieces
      .map((item) => {
        let currentItem = parseInt(item);
        if (currentItem < 10) currentItem = "0" + currentItem;
        return currentItem;
      })
      .join("-");
    return result;
  } catch (e) {
    return dateString;
  }
}

//Delete read-only parameters.
export function sanitizeUserUpdateParams(params) {
  delete params.photo;
  delete params.email;
  delete params.firstname;
  delete params.lastname;
  return params;
}

export function getDayName(date) {
  return date.toLocaleDateString("en-US", { weekday: "long" });
}

export function formatUniversalDate(date) {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [
    date.getFullYear(),
    "-",
    (mm > 9 ? "" : "0") + mm,
    "-",
    (dd > 9 ? "" : "0") + dd,
  ].join("");
}
