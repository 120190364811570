import Editor from "../../../components/QuillEditor";
import { useUserStore } from "../../../store/userStore";

function CoachSettingForm() {

  const doProcessCheckout= useUserStore(
    (state) => state.doProcessCheckout
  );

    return (
      <div className="dash-client-settings-container">
        <div className="dash-client-settings-left">
          <div className="personal-details-box">
            <div className="personal-details-form">
              <div className="input-wrapper-100">
                <div className="personal-details-form-label">Coaching Plan</div>
                <input
                  className="personal-details-form-input"
                  placeholder="Coaching plan A"
                ></input>
              </div>
              <div className="input-wrapper-50">
                <div className="personal-details-form-label">
                  Billing Period
                </div>
                <input
                  className="personal-details-form-input"
                  placeholder="Monthly"
                ></input>
              </div>
              <div className="input-wrapper-50">
                <div className="personal-details-form-label">Amount</div>
                <input
                  className="personal-details-form-input"
                  placeholder="3 USD"
                ></input>
              </div>
            </div>
          </div>

          <div className="description-box">
            <h2>Say something about the plan (Description)</h2>
            <Editor
              placeholder={
                "15% - Paid by the Practitioner + 1.75% and 30c Stripe surcharge"
              }
            ></Editor>
          </div>

          <button
            onClick={async () => {
              
                let data = {};
                const response = await doProcessCheckout(data);
                console.log("response:::", response);

            }}
            className="save-button lumi-btn"
          >
            Save Changes
          </button>
          <div className="client-settings-separator"></div>
        </div>
        <div className="dash-client-settings-right"></div>
      </div>
    );
}

export default CoachSettingForm;