function FrameWCard({
  toggleFrameW,
  frameW,
  toggleFrameX,
  frameX,
  selectW1, 
  toggleSelectW1,
  selectW2, 
  toggleSelectW2,
  selectW3, 
  toggleSelectW3,
  selectW4, 
  toggleSelectW4,
  selectW5, 
  toggleSelectW5,
  selectW6, 
  toggleSelectW6,
  selectW7, 
  toggleSelectW7,
  selectW8, 
  toggleSelectW8,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
     <h1>Do you have any preferences for your practitioner?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          
          </div>
          <div className="description"></div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectW1 ? "li-selected" : ""} onClick={toggleSelectW1} id='no-preference-practitioner'>No Preference</li>
            <li className={selectW2 ? "li-selected" : ""} onClick={toggleSelectW2} id='male-preference-practitioner'>Male</li>
            <li className={selectW3 ? "li-selected" : ""} onClick={toggleSelectW3} id='female-preference-practitioner'>Female</li>
            <li className={selectW4 ? "li-selected" : ""} onClick={toggleSelectW4} id='age-over-30-preference-practitioner'>Age over 30</li>
            <li className={selectW5 ? "li-selected" : ""} onClick={toggleSelectW5} id='age-under-30-preference-practitioner'>Age under 30</li>
            <li className={selectW6 ? "li-selected" : ""} onClick={toggleSelectW6} id='age-over-50-preference-practitioner'>Age over 50</li>
            <li className={selectW7 ? "li-selected" : ""} onClick={toggleSelectW7} id='age-under-50-preference-practitioner'>Age under 50</li>
            <li className={selectW8 ? "li-selected" : ""} onClick={toggleSelectW8} id='non-binary-preference-practitioner'>Non Binary</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
            if (
              selectW1 ||
              selectW2 ||
              selectW3 ||
              selectW4 ||
              selectW5 ||
              selectW6 ||
              selectW7 ||
              selectW8
            ) {
              updateProgressStatus()
              toggleFrameW();
              toggleFrameX();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameWCard;
