import DashboardLayout from "../../layouts/DashboardLayout";

function Dashboard({ Component: component }) {
  return (
    <>
      <DashboardLayout>
        <div className="dashboard">Content here</div>
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
