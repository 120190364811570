import LumiLogo from "../../assets/images/logo.png";

function DashboardHeader() {
  return (
    <div className="dashboard-header">
      <img className="logo" src={LumiLogo}></img>
    </div>
  );
}

export default DashboardHeader;
