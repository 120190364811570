import Editor from "../../../components/QuillEditor";
import { useUserStore } from "../../../store/userStore";

function CoachSettingForm({quantity, setQuantity, setAmount, updateQuantity, amount, totalamount, calculate}) {

  const doProcessCheckout= useUserStore(
    (state) => state.doProcessCheckout
  );

    return (
      <div className="dash-client-settings-container">
        <div className="dash-client-settings-left">
          <div className="personal-details-box">
            <div className="personal-details-form">
              <div className="input-wrapper-100">
                <div className="personal-details-form-label">Product Name</div>
                <input
                  className="personal-details-form-input"
                  placeholder="Coaching plan A"
                ></input>
              </div>
              <div className="input-wrapper-50">
                <div className="personal-details-form-label">
                  Quantity {quantity}
                </div>
                <input
                  className="personal-details-form-input"
                  onChange={(e) => setQuantity(e.target.value)}
                ></input>
              </div>
              <div className="input-wrapper-50">
                <div className="personal-details-form-label">Amount {amount}</div>
                <input
                  className="personal-details-form-input"
                  placeholder="USD"
                  onChange={(e) => setAmount(e.target.value)}
                ></input>
              </div>
              <div className="input-wrapper-100">
              <div className="personal-details-form-label">
                  Amount {totalamount}
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={async () => {
              
                // let data = {};
                // const response = await doProcessCheckout(data);
                // console.log("response:::", response);

                calculate()

                let data = {};
                let assignment = {total: totalamount}
                let answer = {...data, assignment}
                console.log('answer::',answer);


            }}
            className="save-button lumi-btn"
          >
            Purchase
          </button>
          <div className="client-settings-separator"></div>
        </div>
        <div className="dash-client-settings-right"></div>
      </div>
    );
}

export default CoachSettingForm;