import company1 from "../../../assets/images/logo_australianunity.png";
import company2 from "../../../assets/images/logo_ahm.png";
import company3 from "../../../assets/images/logo_hif.png";
import company4 from "../../../assets/images/logo_phoenix.png";

function InsuranceAndMedicare() {
  return (
    <div id="insurance-and-medicare">
      <h3>Insurance and medicare rebates</h3>
      <div className="links">
        <div>Medicare Rebates Available</div>
        <div>
          <div className="dash"></div>
        </div>
        <div>
          <img src={company1}></img>
        </div>
        <div>
          <img src={company2}></img>
        </div>
        <div>
          <img src={company3}></img>
        </div>
        <div>
          <img src={company4}></img>
        </div>
      </div>
      <div className="buttons">
        <div className="button-outline lumi-btn">
          Have a mental health plan?
        </div>
        <div className="button-fill lumi-btn">
          Can I use my health insurance?
        </div>
      </div>
      <h4>Dont’ have a mental health care plan, but would like one?</h4>
    </div>
  );
}

export default InsuranceAndMedicare;
