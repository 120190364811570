import { useState } from "react";

function useMatchingFrameN() {

    const [frameN, setframeN] = useState(false);
    const toggleFrameN = () => {
        setframeN(prevCheck => !prevCheck);
    }
    const [selectN1, setSelectN1] = useState(false);
    const toggleSelectN1 = () => {
        setSelectN1(prevCheck => !prevCheck);
    }
    const [selectN2, setSelectN2] = useState(false);
    const toggleSelectN2 = () => {
        setSelectN2(prevCheck => !prevCheck);
    }
    const [selectN3, setSelectN3] = useState(false);
    const toggleSelectN3 = () => {
        setSelectN3(prevCheck => !prevCheck);
    }
    const [selectN4, setSelectN4] = useState(false);
    const toggleSelectN4 = () => {
        setSelectN4(prevCheck => !prevCheck);
    }
    const [selectN5, setSelectN5] = useState(false);
    const toggleSelectN5 = () => {
        setSelectN5(prevCheck => !prevCheck);
    }
    const [selectN6, setSelectN6] = useState(false);
    const toggleSelectN6 = () => {
        setSelectN6(prevCheck => !prevCheck);
    }
    const [selectN7, setSelectN7] = useState(false);
    const toggleSelectN7 = () => {
        setSelectN7(prevCheck => !prevCheck);
    }
    const [selectN8, setSelectN8] = useState(false);
    const toggleSelectN8 = () => {
        setSelectN8(prevCheck => !prevCheck);
    }
    const [selectN9, setSelectN9] = useState(false);
    const toggleSelectN9 = () => {
        setSelectN9(prevCheck => !prevCheck);
    }

    return {
        toggleFrameN,
        frameN,
        toggleSelectN1,
        selectN1,
        toggleSelectN2,
        selectN2,
        toggleSelectN3,
        selectN3,
        toggleSelectN4,
        selectN4,
        toggleSelectN5,
        selectN5,
        toggleSelectN6,
        selectN6,
        toggleSelectN7,
        selectN7,
        toggleSelectN8,
        selectN8,
        toggleSelectN9,
        selectN9,
    }
}

export default useMatchingFrameN;
