export const Name = ({
  firstName,
  lastName,
  onFirstNameChange,
  onLastNameChange,
}) => (
  <>
    <div className="fullname-container row">
      <div className="col-6 pl-0">
        <div className="labels mt-5">First Name</div>
        <input
          type="text"
          value={firstName}
          onChange={(e) => onFirstNameChange(e.target.value)}
          placeholder="First Name"
        ></input>
      </div>
      <div className="col-6 pr-0">
        <div className="labels mt-5">Last Name</div>
        <input
          type="text"
          value={lastName}
          onChange={(e) => onLastNameChange(e.target.value)}
          placeholder="Your surname"
        ></input>
      </div>
    </div>
  </>
);

export const Email = ({ value, onChange }) => (
  <>
    <div className="labels mt-4">Email Address</div>
    <div className="input-wrapper">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Your email address"
      ></input>
    </div>
  </>
);

export const Actions = ({ onAdd }) => (
  <>
    <button className="lumi-btn" onClick={onAdd}>
      Register
    </button>
  </>
);

export const Password = ({
  value,
  onChange,
  icon,
  clickHandler,
  isReadable,
}) => (
  <>
    <div className="labels mt-4">Password</div>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      ></input>
    </div>
  </>
);

export const Password2 = ({
  value,
  onChange,
  icon,
  clickHandler,
  isReadable,
}) => (
  <>
    <div className="labels mt-4">Confirm Password</div>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        type={isReadable ? "text" : "password"}
        placeholder="Password"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      ></input>
    </div>
  </>
);

export const ErrorMessage = ({ error }) => (
  <>
    <h5 className="error">{error}</h5>
  </>
);
