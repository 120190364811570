import filledStar from "../../assets/images/star_filled.png";
import unfilledStar from "../../assets/images/star_unfilled.png";

function RatingWidget({ rating, maxrating }) {
  if (!rating) return <div className="rating-widget"></div>;

  return (
    <div className="rating-widget">
      {[...Array(maxrating).keys()].map((x, i) => {
        if (x < rating) return <img key={x} src={filledStar}></img>;
        else return <img key={x} src={unfilledStar}></img>;
      })}
    </div>
  );
}
export default RatingWidget;
