import Footer from "../../components/Footer";
import Header from "../../components/Header";
import IconGlobe from "../../assets/images/icon_globe.png";
import Next from "../../assets/images/icon_calendar_next.png";
import Prev from "../../assets/images/icon_calendar_prev.png";
import Select from "react-select";
import {
  durationOptions,
  monthsOptions,
  timezoneOptions,
} from "../../utils/globals";
import { useEffect, useState } from "react";
import { inflateValuesFromSelect } from "../../utils/utilityFunctions";
import Calendar from "react-calendar";
import { getDayName } from "../../utils/utilityFunctions";

function BookPractitioner() {
  const [timezone, setTimezone] = useState();
  const [duration, setDuration] = useState();
  const [value, setValue] = useState(null);
  const [hour, setHour] = useState();
  const daysAvailable = ["Tuesday", "Thursday"];

  const onChange = (data) => {
    setValue(data);
  };

  const getSelectedDate = (date) => {
    if (!date) {
      return "No selected date.";
    }

    const dayName = getDayName(date);
    if (!daysAvailable.includes(dayName)) {
      return "No selected date.";
    }

    return (
      getDayName(date) +
      ", " +
      monthsOptions[date.getMonth()].label +
      " " +
      date.getDate()
    );
  };

  const getHoursList = (duration) => {
    const result = durationOptions.find((item) => {
      return item.value === duration;
    });
    console.log(result.data);
    return result.data;
  };

  const onTileRender = (params) => {
    var className = "custom-calendar-tile";
    var isAvailableThisDay = false;
    const tileDate = new Date(params.date);
    const tileDay = getDayName(tileDate);

    if (daysAvailable.includes(tileDay)) {
      isAvailableThisDay = true;
      className += " is-available-this-day";
    }

    return (
      <div className={className}>
        <div className="date-number">{tileDate.getDate()}</div>
      </div>
    );
  };

  return (
    <>
      <Header></Header>
      <div className="book-practitioner">
        <div className="book-container">
          <div className="separator"></div>
          <div className="row">
            <div className="col-4">
              <h3>Online Clinic Schedule</h3>

              <div className="schedule-message">
                Hi! My regular consultation hours may change on certain
                circumstances, but rest assured you'll be notified 1-2 days
                prior!
              </div>

              <div className="input-wrapper">
                <div>
                  <img className="icon-globe" src={IconGlobe}></img>
                </div>
                <div>
                  <Select
                    placeholder={"Select Timezone"}
                    classNamePrefix={"timezone-select"}
                    options={timezoneOptions}
                    isSearchable={false}
                    value={
                      timezone
                        ? inflateValuesFromSelect([timezone], timezoneOptions)
                        : null
                    }
                    onChange={(e) => {
                      setTimezone(e.value);
                    }}
                  ></Select>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="calendar-container">
                <Calendar
                  calendarType="US"
                  onChange={onChange}
                  value={value}
                  defaultView="month"
                  showNavigation={true}
                  tileContent={onTileRender}
                  tileDisabled={({ date }) =>
                    !daysAvailable.includes(getDayName(date))
                  }
                />
              </div>
            </div>
            <div className="col-4 text-center">
              <div className="session-duration">Session Duration</div>
              <Select
                classNamePrefix={"duration-select"}
                options={durationOptions}
                isSearchable={false}
                value={
                  duration
                    ? inflateValuesFromSelect([duration], durationOptions)
                    : null
                }
                onChange={(e) => {
                  setDuration(e.value);
                }}
              ></Select>

              <div className="selected-date">{getSelectedDate(value)}</div>

              {value && duration && (
                <div className="hour-list">
                  {getHoursList(duration).map((item) => {
                    let className = "session-hour";
                    if (hour === item) className = "session-hour-active";
                    return (
                      <div
                        className={className}
                        onClick={() => {
                          setHour(item);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default BookPractitioner;
