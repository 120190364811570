import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import CoachSettingForm from "./forms/form";
import useProfile from "../../hooks/useProfile";

function CoachSetting() {
  const { _id, role, coach } = useProfile();

  console.log('coach::', coach);

  return (
    <DashboardLayout>
      <div className="client-settings">
        <div className="title-bar">
          <h1>Coach Settings</h1>
          <div>
          <DateTimePill></DateTimePill>
          </div>
        </div>

        <CoachSettingForm></CoachSettingForm>
      </div>
    </DashboardLayout>
  );
}

export default CoachSetting;
