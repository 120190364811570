function HowItem({ icon, title, description, borderColor }) {
  return (
    <div className="how-item flex flex-col items-center">
      <img className={"icon border-" + borderColor} src={icon} />
      <div className="how-text">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
}

export default HowItem;
