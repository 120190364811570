import { useUserStore } from "../store/userStore";
import { usePractitionerStore } from "../store/practitionerStore";
import { useEffect, useState } from "react";

function useUserProfile(_id, role) {
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const doFetchUser = useUserStore((state) => state.doFetchUser);
  const doFetchPractitioner = usePractitionerStore(
    (state) => state.doFetchPractitioner
  );
  //const setProfile = useUserStore((state) => state.setProfile);
  //const profile = useUserStore((state) => state.profile);
  useEffect(() => {
    try {
      let fetcher = null;
      if (role == "practitioner") {
        fetcher = doFetchPractitioner;
      } else {
        fetcher = doFetchUser;
      }

      const fetch = async () => {
        const result = await fetcher({ _id });
        if (result.error) {
          setErrorMessage(result.message);
          setProfile(null);
          return;
        }
        setProfile(result.result[0]);
      };
      fetch();
    } catch (e) {
      console.log("useUserProfile.useEffect", e.message);
    }
  }, []);

  //errorMessage is null when no errors encountered.
  return { profile, errorMessage };
}

export default useUserProfile;
