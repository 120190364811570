import Doctor from "../../../assets/images/dr_1.jpg";
import { Link } from "react-router-dom";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import CalendarIcon from "../../../assets/images/icon_calendar.png";

function PendingApprovalCard({
  firstname,
  lastname,
  role,
  status,
  doAcceptPending,
  id,
  handler,
  showLoader,
  showMessage,
  closeMessages,
  showError,
  accepted,
  hasAccepted,
  redirectToAdmin,
  photo,
}) {
  return (
    <>
      <div className="session-card">
        <div className="row-container">
          <div
            className="half"
            onClick={() => {
              redirectToAdmin(id);
            }}
          >
            <img className="photo" src={photo}></img>
            <h3>
              Dr. {firstname} {lastname}
            </h3>
            <h4>{role}</h4>
          </div>
          <div className="half">
            {/* <span className="date">
              <img className="calendar-icon" src={CalendarIcon}></img>Registration Date
            </span>
            <span className="day-time">Monday, 12.30 pm</span> */}
          </div>
        </div>
        <div className="row-container mt-4">
          <div className="half">
            <button
              onClick={async () => {
                showLoader("Saving changes..");
                let data = {};
                data._id = id;
                data.status = "active";
                const response = await doAcceptPending(data);
                closeMessages();
                if (response.error) {
                  showError(response.message);
                  return;
                }
                hasAccepted(!accepted);
                showMessage("Pending Approval Accepted");
              }}
              className="lumi-btn join-call"
            >
              Accept
            </button>
          </div>
        </div>
        <div className="row-container mt-4">
          <div className="half">
          <Link>Status: {status}</Link>
          </div>
          <div className="half text-right">
            {/* <div className="circle-icon">
              <img src={ContactIcon}></img>
            </div>
            <div className="circle-icon">
              <img src={EmailIcon}></img>
            </div> */}
          </div>
        
        </div>
      </div>
    </>
  );
}

export default PendingApprovalCard;
