import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FrameRCard({
  toggleFrameR,
  frameR,
  toggleFrameE,
  frameE,
  toggleFrameO,
  frameO,
  toggleFrameP,
  frameP,
  toggleFrameQ,
  frameQ,
  toggleFrameS,
  frameS,
  toggleFrameT,
  frameT,
  toggleFrameN,
  frameN,
  selectR1, 
  toggleSelectR1,
  selectR2, 
  toggleSelectR2,
  selectR3, 
  toggleSelectR3,
  selectR4, 
  toggleSelectR4,
  toggleFrameMultiple,
  frameMultiple,
}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Navigating life transitions and changes
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectR1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectR1}>Dealing with divorce</li>
            <li className={selectR2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectR2}>Navigating family breakdowns or dynamics</li>
            <li className={selectR3 ? "li-selected-followup-questions" : ""} onClick={toggleSelectR3}>Parenting</li>
            <li className={selectR4 ? "li-selected-followup-questions" : ""} onClick={toggleSelectR4}>My identity or future path</li>

          </ul>

          <div className="next" onClick={() => {
            if (selectR1 || selectR2 || selectR3 || selectR4) {
              toggleFrameR();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameRCard;
