import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import PendingApprovalCard from "./components/PendingApprovalCard";
import useAdminPendingApproval from "../../hooks/useAdminPendingApproval";
import { useAdminStore } from "../../store/adminStore";
import useProfile from "../../hooks/useProfile";
import useLoader from "../../hooks/useLoader";
import { Link, useParams, useNavigate } from "react-router-dom";

function AdminDashboardDocumentApproval({ Component: component }) {
  const { role } = useProfile();
  const { pendingDocument, redirection, handler, accepted, hasAccepted } = useAdminPendingApproval();
  const doAcceptPending = useAdminStore((state) => state.doAcceptPending);
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const navigate = useNavigate();
  const redirectToAdmin = (id) => navigate(`/admin/practitioner/profilepage/${id}`);

  console.log('pendingDocument::',pendingDocument);

  return (
    <>
      <DashboardLayout>
        <div className="client-dashboard-home">
          <div className="title-bar">
            <h1>Users with uploaded documents</h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>
          <div className="yellow-box">
            {pendingDocument && pendingDocument.length > 0 ? (
              pendingDocument.map((item) => {
                // let display = false;
                // if(item.certificationuploads.length > 0 
                //   || item.certificationuploads.picc > 0
                //   || item.certificationuploads.wcc > 0
                //   || item.certificationuploads.icf > 0
                //   || item.certificationuploads.daa > 0
                // ) {
                //   item.certificationuploads.forEach( (element) => {
                //     if(element.pending_status == 'active') {
                //       display = true;
                //     }
                //   });
                // }
                // if (display == true) {
                  return <PendingApprovalCard 
                  key={item._id}
                  firstname={item.firstname} 
                  lastname={item.lastname}
                  position={item.position}
                  document_status={item.document_status}
                  id={item._id}
                  doAcceptPending={doAcceptPending}
                  handler={handler}
                  showLoader={showLoader}
                  showMessage={showMessage}
                  closeMessages={closeMessages}
                  showError={showError}
                  accepted={accepted}
                  hasAccepted={hasAccepted}
                  redirectToAdmin={redirectToAdmin}
                  certificationuploads={item.certificationuploads}
                  picc={item.picc}
                  icf={item.icf}
                  daa={item.daa}
                  wwc={item.wwc}
                />
                // }
                // display = false;
              })
            ) : (
              <div align="center"> No matches.</div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default AdminDashboardDocumentApproval;
