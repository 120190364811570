function FrameDCard({
  toggleFrameD,
  frameD,
  toggleFrameL,
  frameL,
  selectD1, 
  toggleSelectD1,
  selectD2, 
  toggleSelectD2,
  selectD3, 
  toggleSelectD3,
  selectD4, 
  toggleSelectD4,
  selectD5, 
  toggleSelectD5,
  selectD6, 
  toggleSelectD6,
  selectD7, 
  toggleSelectD7,
  selectD8, 
  toggleSelectD8,
  selectD9, 
  toggleSelectD9,
  selectD10, 
  toggleSelectD10,
  selectD11, 
  toggleSelectD11,
  selectD12, 
  toggleSelectD12,
  selectD13, 
  toggleSelectD13,
  selectD14, 
  toggleSelectD14,
  selectD15, 
  toggleSelectD15,
  selectD16, 
  toggleSelectD16,
  selectD17, 
  toggleSelectD17,
  toggleAnswersFrameA,
  answersFrameA,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
     <h1>Who requires support?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          It's for my child
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectD1 ? "li-selected" : ""} onClick={toggleSelectD1} id='behavioral-issues-or-emotional-difficulties'>Behavioral issues or emotional difficulties</li>
            <li className={selectD2 ? "li-selected" : ""} onClick={toggleSelectD2} id='emotional-difficulties'>Emotional difficulties</li>
            <li className={selectD3 ? "li-selected" : ""} onClick={toggleSelectD3} id='school-related-issues-or-academic-concerns'>School-related issues or academic concerns</li>
            <li className={selectD4 ? "li-selected" : ""} onClick={toggleSelectD4} id='social-issues'>Social issues</li>
            <li className={selectD5 ? "li-selected" : ""} onClick={toggleSelectD5} id='trauma-and-stress'>Trauma and stress</li>
            <li className={selectD6 ? "li-selected" : ""} onClick={toggleSelectD6} id='developmental-delays'>Developmental delays</li>
            <li className={selectD7 ? "li-selected" : ""} onClick={toggleSelectD7} id='anxiety-self-esteem-or-identity-issues'>Anxiety, self-esteem or identity issues</li>
            <li className={selectD8 ? "li-selected" : ""} onClick={toggleSelectD8} id='phobias'>Phobias</li>
            <li className={selectD9 ? "li-selected" : ""} onClick={toggleSelectD9} id='feelings-of-depression'>Feelings of depression</li>
            <li className={selectD10 ? "li-selected" : ""} onClick={toggleSelectD10} id='eating-or-feeding-concerns'>Eating or feeding concerns</li>
            <li className={selectD11 ? "li-selected" : ""} onClick={toggleSelectD11} id='grief-and-loss'>Grief and loss</li>
            <li className={selectD12 ? "li-selected" : ""} onClick={toggleSelectD12} id='sibling-rivalry'>Sibling rivalry</li>
            <li className={selectD13 ? "li-selected" : ""} onClick={toggleSelectD13} id='substance-abuse'>Substance abuse</li>
            <li className={selectD14 ? "li-selected" : ""} onClick={toggleSelectD14} id='communication-difficulties'>Communication difficulties</li>
            <li className={selectD15 ? "li-selected" : ""} onClick={toggleSelectD15} id='neurodevelopmental-disorders-children-diagnosed-with-conditions-such-as-autism-spectrum-disorder'>Neurodevelopmental disorders: children diagnosed with conditions such as autism spectrum disorder (ASD), attention-deficit/hyperactivity disorder (ADHD)</li>
            <li className={selectD16 ? "li-selected" : ""} onClick={toggleSelectD16} id='coping-with-life-transitions'>Coping with life transitions</li>
            <li className={selectD17 ? "li-selected" : ""} onClick={toggleSelectD17} id='preventative-and-wellness'>Preventative and wellness</li>
          </ul>

          <div className="next" onClick={() => {
            console.log('answers::',answers);
            if (
              selectD1 ||
              selectD2 ||
              selectD3 ||
              selectD4 ||
              selectD5 ||
              selectD6 ||
              selectD7 ||
              selectD8 ||
              selectD9 ||
              selectD10 ||
              selectD11 ||
              selectD12 ||
              selectD13 ||
              selectD14 ||
              selectD15 ||
              selectD16 ||
              selectD17
            ) {
              updateProgressStatus()
              toggleFrameD();
              toggleFrameL();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameDCard;