import Select from "react-select";

function ProgressCard({
  percentage
}) {
  return (
    <div>
      <div className="progressBar">
        <div className="container">
          <div style={{ width: percentage }} className="progress-bar">
            {percentage == 0 ? "" : percentage}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressCard;
