import { useState } from "react";

function useMatchingFrameW() {

    const [frameW, setframeW] = useState(false);
    const toggleFrameW = () => {
        setframeW(prevCheck => !prevCheck);
    }
    const [selectW1, setSelectW1] = useState(false);
    const toggleSelectW1 = () => {
        setSelectW1(prevCheck => !prevCheck);
    }
    const [selectW2, setSelectW2] = useState(false);
    const toggleSelectW2 = () => {
        setSelectW2(prevCheck => !prevCheck);
    }
    const [selectW3, setSelectW3] = useState(false);
    const toggleSelectW3 = () => {
        setSelectW3(prevCheck => !prevCheck);
    }
    const [selectW4, setSelectW4] = useState(false);
    const toggleSelectW4 = () => {
        setSelectW4(prevCheck => !prevCheck);
    }
    const [selectW5, setSelectW5] = useState(false);
    const toggleSelectW5 = () => {
        setSelectW5(prevCheck => !prevCheck);
    }
    const [selectW6, setSelectW6] = useState(false);
    const toggleSelectW6 = () => {
        setSelectW6(prevCheck => !prevCheck);
    }
    const [selectW7, setSelectW7] = useState(false);
    const toggleSelectW7 = () => {
        setSelectW7(prevCheck => !prevCheck);
    }
    const [selectW8, setSelectW8] = useState(false);
    const toggleSelectW8 = () => {
        setSelectW8(prevCheck => !prevCheck);
    }

    return {
        toggleFrameW,
        frameW,
        toggleSelectW1,
        selectW1,
        toggleSelectW2,
        selectW2,
        toggleSelectW3,
        selectW3,
        toggleSelectW4,
        selectW4,
        toggleSelectW5,
        selectW5,
        toggleSelectW6,
        selectW6,
        toggleSelectW7,
        selectW7,
        toggleSelectW8,
        selectW8,
    }
}

export default useMatchingFrameW;
