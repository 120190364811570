import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Certifications from "./CertificationCard";
import { TbUpload } from "react-icons/tb";

function PdfcertificationCard({
  title,
  desc,
  handleUploadPdf,
  type,
  practitionerProfile,
}) {
  const uploader = Uploader({
    apiKey: "public_12a1z9w8bUMa9Q9NzsbYqAWsiabY",
  });
  const options = { multi: true };
  console.log("practitionerProfile::", practitionerProfile);
  // Configuration options: https://www.bytescale.com/docs/upload-widget/frameworks/react#customize
  // https://www.npmjs.com/package/react-uploader
  return (
    <div className="flex flex-col flex-1">
      <h2>{title}</h2>
      <span>{desc}</span>
      <div>
        <UploadButton
          uploader={uploader}
          options={options}
          onComplete={(files) => {
            if (files) handleUploadPdf({ files, uploadType: type });
          }}
        >
          {({ onClick }) => (
            <div className="inline-flex flex-row items-center join-call lumi-btn p-2 my-2 font-bold gap-2 ">
              <TbUpload size={30} />
              <button onClick={onClick}>
                Upload supporting documents (pdf, jpg, png)
              </button>
            </div>
          )}
        </UploadButton>
        <div className="bold">Uploaded Certifications</div>
        <ul>
          {practitionerProfile && practitionerProfile.length > 0 ? (
            practitionerProfile.map((item) => {
              if (item.pending_status != "pending") {
                return <Certifications cert={item.cert} />;
              }
            })
          ) : (
            <div> No certifications uploaded. </div>
          )}
        </ul>
        {/* <button
        onClick={() => {
          handleUploadPdf({files:'https://upcdn.io/kW15cFs/raw/uploads/2024/10/17/4kGBtewF8r-mejr5kc1.pdf',uploadType:"daa"});
        }}
        >
          Test
        </button> */}
      </div>
    </div>
  );
}

export default PdfcertificationCard;
