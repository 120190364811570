import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import ChargeSettingForm from "./forms/form";
import useProfile from "../../hooks/useProfile";
import useCharge from "../../hooks/useCharge";

function CoachSetting() {
  const { _id, role, coach } = useProfile();
  const { quantity, setQuantity, amount, setAmount, totalamount, calculate } = useCharge();

  return (
    <DashboardLayout>
      <div className="client-settings">
        <div className="title-bar">
          <h1>Test Charging Stripe</h1>
        </div>
        <ChargeSettingForm 
        quantity={quantity}
        setQuantity={setQuantity}
        amount={amount}
        setAmount={setAmount}
        totalamount={totalamount}
        calculate={calculate}
        ></ChargeSettingForm>
      </div>
    </DashboardLayout>
  );
}

export default CoachSetting;
