import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import SessionCard from "./components/SessionCard";

function PractitionerClients({ Component: component }) {
  return (
    <>
      <DashboardLayout>
        <div className="practitioner-clients">
          <div className="title-bar">
            <h1>Clients</h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>

          <div className="yellow-box">
            <SessionCard></SessionCard>
            <SessionCard></SessionCard>
            <SessionCard></SessionCard>
            <SessionCard></SessionCard>
            <SessionCard></SessionCard>
            <SessionCard></SessionCard>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default PractitionerClients;
