export const Email = ({ value, onChange }) => (
    <>
      <input
        className="certification-input"
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Your email address"
      ></input>
    </>
  );