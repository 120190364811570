function AnnouncementBar({ message }) {
  return (
    <div className="flex  justify-center items-center bg-lumi-yellow p-2">
      <p className="m-auto font-body font-semibold text-center tablet:text-xs">
        {message}
      </p>
    </div>
  );
}

export default AnnouncementBar;
