import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FrameOCard({
  toggleFrameO,
  frameO,
  toggleFrameE,
  frameE,
  toggleFrameP,
  frameP,
  toggleFrameQ,
  frameQ,
  toggleFrameR,
  frameR,
  toggleFrameS,
  frameS,
  toggleFrameT,
  frameT,
  toggleFrameN,
  frameN,
  selectO1, 
  toggleSelectO1,
  selectO2, 
  toggleSelectO2,
  selectO3, 
  toggleSelectO3,
  selectO4, 
  toggleSelectO4,
  selectO5, 
  toggleSelectO5,
  selectO6, 
  toggleSelectO6,
  selectO7, 
  toggleSelectO7,
  selectO8, 
  toggleSelectO8,
  selectO9, 
  toggleSelectO9,
  selectO10, 
  toggleSelectO10,
  selectO11, 
  toggleSelectO11,
  toggleFrameMultiple,
  frameMultiple,
}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Professional/Career Growth or Concerns
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectO1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO1}>Professional growth</li>
            <li className={selectO2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO2}>Strategy & goal Setting</li>
            <li className={selectO3 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO3}>Leadership development</li>
            <li className={selectO4 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO4}>Entrepreneurship</li>
            <li className={selectO5 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO5}>Public speaking</li>
            <li className={selectO6 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO6}>Creative block</li>
            <li className={selectO7 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO7}>Burnout/stress management</li>
            <li className={selectO8 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO8}>Conflict management</li>
            <li className={selectO9 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO9}>Self confidence / anxiety / imposter syndrome</li>
            <li className={selectO10 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO10}>Time management / procrastination / overwhelm</li>
            <li className={selectO11 ? "li-selected-followup-questions" : ""} onClick={toggleSelectO11}>Prefer not to say</li>
          </ul>

          <div className="next" onClick={() => {
            if (
              selectO1 ||
              selectO2 ||
              selectO3 ||
              selectO4 ||
              selectO5 ||
              selectO6 ||
              selectO7 ||
              selectO8 ||
              selectO9 ||
              selectO10 ||
              selectO11
            ) {
              toggleFrameO();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameOCard;
