import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  setAppointment,
  getAppointment
} from "../api";

const initialState = {
  appointments: []
};

const store = (set, get) => ({
  //state
  ...initialState,
  doReset: () => set(initialState),
  doSetAppointment: async (data) => {
    try {
      let setResult = await setAppointment(data);
      return setResult;
    } catch(e) {
      const errorMessage = `Appointment Store Error [doAcceptPending]: ${e.message}`;
      console.log("doSetAppointment", errorMessage);
      throw Error(errorMessage);    }
  },
  doFetchAppointment: async (data) => {
    try {
      let getResult = await getAppointment({practitioner_id: data});
      console.log('getResult::',getResult);
      if(getResult.error) {} else {
        set({ appointments: getResult.result });
      }
      return getResult;
    } catch(e) {}
  }
});

export const useAppointmentStore = create(devtools(immer(store)));
