import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FramePCard({
  toggleFrameP,
  frameP,
  toggleFrameE,
  frameE,  
  toggleFrameO,
  frameO,
  toggleFrameQ,
  frameQ,
  toggleFrameR,
  frameR,
  toggleFrameS,
  frameS,
  toggleFrameT,
  frameT,
  toggleFrameN,
  frameN,
  selectP1, 
  toggleSelectP1,
  selectP2, 
  toggleSelectP2,
  selectP3, 
  toggleSelectP3,
  toggleFrameMultiple,
  frameMultiple,
}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Sexual, Sexuality or gender issues
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectP1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectP1}>Intimacy or sexual concerns</li>
            <li className={selectP2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectP2}>Sexuality, gender, LGBTQIA+ issues</li>
            <li className={selectP3 ? "li-selected-followup-questions" : ""} onClick={toggleSelectP3}>Prefer not to say</li>
          </ul>

          <div className="next" onClick={() => {
            if (selectP1 || selectP2 || selectP3) {
              toggleFrameP();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FramePCard;
