import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CropperModal({
  show,
  handleCancel,
  handleConfirm,
  avatarUrl,
  aspectRatio,
}) {
  const [cropper, setCropper] = useState();

  return (
    <>
      <Modal show={show} centered size={"lg"}>
        <Modal.Header>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            src={avatarUrl}
            style={{ height: 600, width: "100%" }}
            initialAspectRatio={aspectRatio}
            aspectRatio={aspectRatio}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            guides={false}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="lumirose"
            onClick={async () => {
              if (cropper) {
                const file = await fetch(cropper.getCroppedCanvas().toDataURL())
                  .then((res) => res.blob())
                  .then((blob) => {
                    return new File([blob], "newAvatar.png", {
                      type: "image/png",
                    });
                  });
                if (file) {
                  handleConfirm({
                    file: file,
                    url: cropper.getCroppedCanvas().toDataURL(),
                  });
                }
              }
            }}
          >
            Crop and Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CropperModal;
