import { useState } from "react";

function useMatchingFrameC() {
    const [frameD, setframeD] = useState(false);
    const toggleFrameD = () => {
        setframeD(prevCheck => !prevCheck);
    }

    const [selectC1, setSelectC1] = useState(false);
    const toggleSelectC1 = () => {
        setSelectC1(prevCheck => !prevCheck);
    }
    const [selectC2, setSelectC2] = useState(false);
    const toggleSelectC2 = () => {
        setSelectC2(prevCheck => !prevCheck);
    }

    return {
        toggleFrameD,
        frameD,
        toggleSelectC1,
        selectC1,
        toggleSelectC2,
        selectC2,
    }
}

export default useMatchingFrameC;
