function FrameXCard({
  toggleFrameX,
  frameX,
  toggleFrameY,
  frameY,
  selectX1, 
  toggleSelectX1,
  selectX2, 
  toggleSelectX2,
  selectX3, 
  toggleSelectX3,
  selectX4, 
  toggleSelectX4,
  selectX5, 
  toggleSelectX5,
  selectX6, 
  toggleSelectX6,
  selectX7, 
  toggleSelectX7,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
     <h1>How do you identify?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          
          </div>
          <div className="description"></div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectX1 ? "li-selected" : ""} onClick={toggleSelectX1} id='how-do-you-identify-male'>Male</li>
            <li className={selectX2 ? "li-selected" : ""} onClick={toggleSelectX2} id='how-do-you-identify-'>Female</li>
            <li className={selectX3 ? "li-selected" : ""} onClick={toggleSelectX3} id='how-do-you-identify-non-binary'>Non-binary</li>
            <li className={selectX4 ? "li-selected" : ""} onClick={toggleSelectX4} id='how-do-you-identify-transgender-male'>Transgender Male</li>
            <li className={selectX5 ? "li-selected" : ""} onClick={toggleSelectX5} id='how-do-you-identify-transgender-female'>Transgender Female</li>
            <li className={selectX6 ? "li-selected" : ""} onClick={toggleSelectX6} id='how-do-you-identify-prefer-not-to-say'>Prefer Not to Say</li>
            <li className={selectX7 ? "li-selected" : ""} onClick={toggleSelectX7} id='how-do-you-identify-other'>Other</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
            if (
              selectX1 ||
              selectX2 ||
              selectX3 ||
              selectX4 ||
              selectX5 ||
              selectX6 ||
              selectX7
            ) {
              updateProgressStatus()
              toggleFrameX();
              toggleFrameY();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameXCard;
