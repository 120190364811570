import { useState, useEffect } from "react";

function useCharge() {
  const [quantity, setQuantity] = useState(0);
  const [amount, setAmount] = useState(1);
  const [totalamount, setTotalAmount] = useState(0);

  const calculate = () => {
    setTotalAmount(amount * quantity);
  }

  useEffect(() => {
    calculate()
  }, [quantity]);

  useEffect(() => {
    calculate()
  }, [amount]);

  return {
    quantity,
    setQuantity,
    amount,
    setAmount,
    totalamount,
    calculate,
  };
}

export default useCharge;
