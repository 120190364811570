import { useState } from "react";

function useMatchingFrameL() {

    const [frameL, setframeL] = useState(false);
    const toggleFrameL = () => {
        setframeL(prevCheck => !prevCheck);
    }

    const [frameM, setframeM] = useState(false);
    const toggleFrameM = () => {
        setframeM(prevCheck => !prevCheck);
    }

    const [selectL1, setSelectL1] = useState(false);
    const toggleSelectL1 = () => {
        setSelectL1(prevCheck => !prevCheck);
    }
    const [selectL2, setSelectL2] = useState(false);
    const toggleSelectL2 = () => {
        setSelectL2(prevCheck => !prevCheck);
    }
    const [selectL3, setSelectL3] = useState(false);
    const toggleSelectL3 = () => {
        setSelectL3(prevCheck => !prevCheck);
    }
    const [selectL4, setSelectL4] = useState(false);
    const toggleSelectL4 = () => {
        setSelectL4(prevCheck => !prevCheck);
    }
    const [selectL5, setSelectL5] = useState(false);
    const toggleSelectL5 = () => {
        setSelectL5(prevCheck => !prevCheck);
    }

    return {
        toggleFrameL,
        frameL,
        toggleFrameM,
        frameM,
        toggleSelectL1,
        selectL1,
        toggleSelectL2,
        selectL2,
        toggleSelectL3,
        selectL3,
        toggleSelectL4,
        selectL4,
        toggleSelectL5,
        selectL5,
    }
}

export default useMatchingFrameL;
