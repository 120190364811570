import QuizBG from "../../../assets/images/hiw_quiz.png";
import ChooseBG from "../../../assets/images/hiw_choose.png";
import BookBG from "../../../assets/images/hiw_book.png";
import ConnectBG from "../../../assets/images/hiw_connect.png";
import HowItem from "./HowItem";

function HowItWorks() {
  return (
    <div id="how-it-works">
      <h1>How It Works</h1>
      <div className="how-items">
        <HowItem
          borderColor={"yellow"}
          icon={QuizBG}
          title={"Complete an optional quiz"}
          description={
            "When was the last time you checked in with yourself? We'll help identify where you most need support."
          }
        ></HowItem>

        <HowItem
          borderColor={"pink"}
          icon={ChooseBG}
          title={"Find your practitioner"}
          description={
            "Based on what you share, we'll match you with a professional who's qualified to guide, support and empower you. Or, browse by category to find your right fit."
          }
        ></HowItem>

        <HowItem
          borderColor={"orange"}
          icon={BookBG}
          title={"Book an Appointment"}
          description={
            "We won't make you wait. Take the first step to wellness by booking your session in a few clicks."
          }
        ></HowItem>

        <HowItem
          borderColor={"rose"}
          icon={ConnectBG}
          title={"Start feeling seen"}
          description={
            "Be heard by someone who really gets you by connecting in-person or online."
          }
        ></HowItem>
      </div>
    </div>
  );
}

export default HowItWorks;
