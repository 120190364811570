import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { usePractitionerStore } from "../../store/practitionerStore";
import { useUserStore } from "../../store/userStore";

import PlusIcon from "../../assets/images/icon_plus_round.png";
import uploadSupportingDocs from "../../assets/images/upload-supporting-docs.png";

import Editor from "../../components/QuillEditor";
import useProfile from "../../hooks/useProfile";
import usePractitionerProfile from "../../hooks/usePractitionerProfile";
import useLoader from "../../hooks/useLoader";
import {
  flattenValuesFromSelect,
  inflateValuesFromSelect,
} from "../../utils/utilityFunctions";
import {
  categoryOptions,
  professionOptions,
  gpOptions,
  specialisedCategoryOptions,
  accuratePersonalityTraitsOptions,
  dieticians,
  life_coach,
} from "../../utils/globals";
import UploadIcon from "../../assets/images/icon_upload.png";
import MedCert from "../../assets/images/med-cert.png";
import CropperModal from "../../components/CropperModal";
import { updateProfilePhoto, updatePdfUpload } from "../../api";
import Certifications from "./components/CertificationCard";
import PdfcertificationCard from "./components/PdfcertificationCard";
import CertificationUploadRequirementCard from "./components/CertificationUploadRequirementCard";
import CertificationsPending from "./components/CertificationCardPending";
import { useNavigate } from "react-router-dom";

function auto_grow() {
  const textareas = document.getElementsByTagName("textarea");

  for (let t of textareas) {
    t.style.height = "5px";
    t.style.height = t.scrollHeight + "px";
  }
}

function PractitionerCerts() {
  const navigate = useNavigate();
  const [professionVal, setProfessionVal] = useState("");
  const [serviceVal, setServiceVal] = useState("");
  const [backgroundVal, setBackgroundVal] = useState("");
  const [certificationsVal, setCertificationsVal] = useState("");

  const { _id } = useProfile();
  const doUpdatePractitioner = usePractitionerStore(
    (state) => state.doUpdatePractitioner
  );

  const setProfile = useUserStore((state) => state.setProfile);

  const { profile: practitionerProfile, errorMessage: fetchMessage } =
    usePractitionerProfile(_id);

  useEffect(() => {
    if (practitionerProfile) {
      setProfessionVal(practitionerProfile.position);
      setServiceVal(practitionerProfile.message);
      setBackgroundVal(practitionerProfile.background);
      setCertificationsVal(practitionerProfile.certifications);
      setSelectedCategories(
        inflateValuesFromSelect(practitionerProfile.categories)
      );
      setSelectedGpCategories(
        inflateValuesFromSelect(practitionerProfile.gpcategories)
      );
      setSelectedSpecialisedCategories(
        inflateValuesFromSelect(practitionerProfile.specialisedcategories)
      );
      setSelectedAccuratePersonalityTraitsCategories(
        inflateValuesFromSelect(
          practitionerProfile.accuratepersonalitytraitscategories
        )
      );
      setSelectedProfession(
        inflateValuesFromSelect([practitionerProfile.position])
      );
      setSelectedWorktype(practitionerProfile.worktype);
      setSelectedAgeScopetype(practitionerProfile.agescopetype);
      setSelectedIcftype(practitionerProfile.icftype);
      setSelectedDaatype(practitionerProfile.daatype);
      setSelectedClienttype(practitionerProfile.clienttype);
      setSelectedQualifiedMentalProftype(
        practitionerProfile.qualifiedmentalproftype
      );
      setSelectedSupportType(practitionerProfile.supporttype);
    }
  }, [practitionerProfile]);

  const professionRef = useRef();
  const [selectCategories, setSelectedCategories] = useState();
  const [selectGpCategories, setSelectedGpCategories] = useState();
  const [
    selectAccuratePersonalityTraitsCategories,
    setSelectedAccuratePersonalityTraitsCategories,
  ] = useState();
  const [selectSpecialisedCategories, setSelectedSpecialisedCategories] =
    useState();
  const [selectedProfession, setSelectedProfession] = useState();
  const [selectWorkType, setSelectedWorktype] = useState(0);
  const [selectAgeScopeType, setSelectedAgeScopetype] = useState(0);
  const [selectIcfType, setSelectedIcftype] = useState(0);
  const [selectDaaType, setSelectedDaatype] = useState(0);
  const [selectClientType, setSelectedClienttype] = useState(0);
  const [selectQualifiedMentalProftype, setSelectedQualifiedMentalProftype] =
    useState(0);
  const [selectSupportType, setSelectedSupportType] = useState(0);

  const handleCategorySelect = (data) => {
    setSelectedCategories(data);
  };

  const handleGpSelect = (data) => {
    setSelectedGpCategories(data);
  };

  const handleaccuratePersonalityTraitsSelect = (data) => {
    setSelectedAccuratePersonalityTraitsCategories(data);
  };

  const handleSpecialisedCategoriesSelect = (data) => {
    setSelectedSpecialisedCategories(data);
  };

  const handleWorkType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedWorktype(value);
    }
  };

  const handleAgeScopeType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedAgeScopetype(value);
    }
  };

  const handleIcfType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedIcftype(value);
    }
  };

  const handleDaaType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedDaatype(value);
    }
  };

  const handleClientType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedClienttype(value);
    }
  };

  const handleQualifiedMentalProfType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedQualifiedMentalProftype(value);
    }
  };

  const handleSupportType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedSupportType(value);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <div className="lumi-drop-indicator">
        <img src={PlusIcon}></img>
      </div>
    );
  };

  useEffect(() => {
    auto_grow();
  }, []);

  useEffect(() => {
    if (fetchMessage) showError(fetchMessage);
  }, [fetchMessage]);

  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const uploadInput = useRef();

  const [showCropper, setShowCropper] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [ageUpload, setAgeUpload] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [cropperFile, setCropperFile] = useState(null);

  const handleUploadPdf = async ({ files, uploadType }) => {
    showLoader("Uploading photo. Please wait...");
    const result = await updatePdfUpload({
      files: files[0].fileUrl,
      uploadType,
      _id: _id,
    });
    if (result.error) {
      showCropper(result.message);
    } else {
      setProfile(result.result);
      showMessage("Profile photo has been updated successfully.");
      navigate(0);
    }
    closeMessages();
  };

  const handleCropperConfirm = async (file) => {
    setShowCropper(false);
    setAvatarUrl(null);
    setCropperFile(file);

    showLoader("Uploading photo. Please wait...");
    const result = await updateProfilePhoto({
      certificationuploads: file,
      uploadType: uploadType,
      _id: _id,
    });
    if (result.error) {
      showCropper(result.message);
    } else {
      setProfile(result.result);
      showMessage("Profile photo has been updated successfully.");
      navigate(0);
    }
    closeMessages();
  };

  const handleCropperCancel = () => {
    setShowCropper(false);
    setCropperFile(null);
    setAvatarUrl(null);
  };

  const getUploadCertificationSource = () => {
    return uploadSupportingDocs;
  };

  const getUploadScopingCertificationSource = () => {
    return uploadSupportingDocs;
  };

  return (
    <DashboardLayout>
      <div className="practitioner-cert-profile">
        <h1 className="font-body text-lumi-blue font-semibold text-2xl">
          Certifications
        </h1>
        <div className="subhead">
          Add your relevant certifications below. These will be reviewed along
          with your profile for approval.
        </div>
        {practitionerProfile ? (
          <div className="dash-prac-profile-container">
            <div className="dash-prac-profile-left">
              <div className="single-select-box">
                <PdfcertificationCard
                  title="Professional Indemnity Certificate of Currency (required)"
                  setUploadType={setUploadType}
                  handleUploadPdf={handleUploadPdf}
                  type="picc"
                  practitionerProfile={
                    typeof practitionerProfile.picc === "undefined"
                      ? []
                      : practitionerProfile.picc
                  }
                />
              </div>

              <div className="single-select-box">
                <PdfcertificationCard
                  title="Working with children"
                  desc="Do you work with youth under age of 16. If so, please upload your working with children or vulnerable people check."
                  setUploadType={setUploadType}
                  handleUploadPdf={handleUploadPdf}
                  type="wwc"
                  practitionerProfile={
                    typeof practitionerProfile.wwc === "undefined"
                      ? []
                      : practitionerProfile.wwc
                  }
                />
              </div>

              {practitionerProfile &&
              practitionerProfile.position === life_coach ? (
                <div className="single-select-box">
                  <PdfcertificationCard
                    title="International Coaching Federation (ICF)"
                    desc="Are you a member of the International Coaching Federation (ICF)? If so, please upload a copy of your membership."
                    setUploadType={setUploadType}
                    handleUploadPdf={handleUploadPdf}
                    type="icf"
                    practitionerProfile={
                      typeof practitionerProfile.icf === "undefined"
                        ? []
                        : practitionerProfile.icf
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}

              {practitionerProfile &&
              practitionerProfile.position === dieticians ? (
                <div className="single-select-box">
                  <PdfcertificationCard
                    title="NSA / DAA Member"
                    desc="Do you hold a membership with either of the following? If yes, please upload a copy of your membership."
                    setUploadType={setUploadType}
                    handleUploadPdf={handleUploadPdf}
                    type="daa"
                    practitionerProfile={
                      typeof practitionerProfile.daa === "undefined"
                        ? []
                        : practitionerProfile.daa
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="single-select-box flex tablet:flex-col desktop:flex-row gap-4">
                <PdfcertificationCard
                  title="Certification Upload"
                  uploadedDesc=""
                  setUploadType={setUploadType}
                  handleUploadPdf={handleUploadPdf}
                  type="certifications"
                  practitionerProfile={
                    typeof practitionerProfile.certificationuploads === "undefined"
                      ? []
                      : practitionerProfile.certificationuploads
                  }
                />
                <CertificationUploadRequirementCard />
              </div>
            </div>
            <div className="clearboth"></div>
            <button
              className="save-button lumi-btn"
              onClick={async () => {
                showLoader("Saving changes..");
                let data = {};
                data.position = selectedProfession.value;
                data.message = serviceVal;
                data.background = backgroundVal;
                data.certifications = certificationsVal;
                data.categories = flattenValuesFromSelect(selectCategories);
                data.gpcategories = flattenValuesFromSelect(selectGpCategories);
                data.accuratepersonalitytraitscategories =
                  flattenValuesFromSelect(
                    selectAccuratePersonalityTraitsCategories
                  );
                data.specialisedcategories = flattenValuesFromSelect(
                  selectSpecialisedCategories
                );
                data.worktype = selectWorkType;
                data.supporttype = selectSupportType;
                data.agescopetype = selectAgeScopeType;
                data.clienttype = selectClientType;
                data.qualifiedmentalproftype = selectQualifiedMentalProftype;
                data._id = _id;
                const response = await doUpdatePractitioner(data);

                closeMessages();

                if (response.error) {
                  showError(response.message);
                  return;
                }

                showMessage("Profile updated successfully!");
              }}
            >
              Save Changes
            </button>
          </div>
        ) : (
          <div>Loading profile</div>
        )}
      </div>

      <CropperModal
        show={showCropper}
        avatarUrl={avatarUrl}
        uploadType={uploadType}
        handleCancel={handleCropperCancel}
        handleConfirm={handleCropperConfirm}
        aspectRatio={1 / 1}
      ></CropperModal>
    </DashboardLayout>
  );
}

export default PractitionerCerts;
