import Select from "react-select";

export const NameFilter = ({ names, name, setName, onChange }) => (
  <>
    <div className="labels">Name</div>
    <div className="selects">
      <Select
        id="nameFilter"
        options={names}
        classNamePrefix="practitioner-select"
        defaultValue={names.find(({ label }) => label === "Select")}
     
        onChange={onChange}
      />
    </div>
  </>
);

export const TypeFilter = ({ specialty }) => (
  <>
    <div className="labels">Type</div>
    <div className="selects">
      <Select
        options={specialty}
        classNamePrefix="practitioner-select"
        defaultValue={specialty.find(({ value }) => value === "Any")}
      />
    </div>
  </>
);
