import DashboardLayout from "../../layouts/DashboardLayout";
import SessionCardNoSchedule from "./components/SessionCardNoSchedule";

function PractitionerDashboardHome({ Component: component }) {
  return (
    <>
      <DashboardLayout>
        <div className="practitioner-dashboard-home">
          <div className="title-bar">
            <h1 className="text-center">Welcome to the LumiLife pre-launch</h1>
          </div>

          <div className="yellow-box">
            {/* <SessionCard></SessionCard> */}
            <SessionCardNoSchedule></SessionCardNoSchedule>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default PractitionerDashboardHome;
