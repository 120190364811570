function FrameMCard({
  toggleFrameM,
  frameM,
  toggleFrameU,
  frameU,
  UploadIcon,
  selectM1,
  toggleSelectM1,
  selectM2,
  toggleSelectM2,
  selectM3,
  toggleSelectM3,
  selectM4,
  toggleSelectM4,
  uploadInput,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
      <h1>What type of support most aligns with you</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
            Do you have any of the following valid care plans from your GP that
            you would like to use for your sessions?
          </div>
          <div className="description">Select all that apply</div>
          <ul className="one-col-ul" onClick={appendAnswers}>
            <li
              className={selectM1 ? "li-selected" : ""}
              onClick={toggleSelectM1}
              id='mental-health-care-plan'
            >
              Mental Health Care Plan
            </li>
            <li
              className={selectM2 ? "li-selected" : ""}
              onClick={toggleSelectM2}
              id='chronic-disease-care-plan'
            >
              Chronic Disease Care Plan
            </li>
            <li
              className={selectM3 ? "li-selected" : ""}
              onClick={toggleSelectM3}
              id='eating-disorder-care-plan'
            >
              Eating Disorder Care Plan
            </li>
            <li
              className={selectM4 ? "li-selected" : ""}
              onClick={toggleSelectM4}
              id='i-dont-have-any-of-the-above-care-plans'
            >
              I don't have any of the above care plans
            </li>
          </ul>
          <div className="bottom-description">
            *LumiLife aims to match you with a practitioner that best suits your
            needs, but please  make sure when booking, you have selected a
            Medicare provider that accepts the care plan provided by your GP.
          </div>
          <div
            className="next"
            onClick={() => {
              console.log('answers::',answers);
              if (selectM1 || selectM2 || selectM3 || selectM4) {
                updateProgressStatus();
                toggleFrameM();
                toggleFrameU();
              }
            }}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameMCard;
