import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  pendingPractitioner,
  updatePractitioner
} from "../api";

const initialState = {
  funnelAnswers: [],
};

const store = (set, get) => ({
  //state
  ...initialState,
  doReset: () => set(initialState),
  doFetchFunnelAnswers: async (e) => {
    set(
      {funnelAnswers: e}
    );
  }
});

export const useFunnelStore = create(devtools(immer(store)));
