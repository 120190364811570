import { useState } from "react";

function useMatchingFrameT() {

    const [frameT, setframeT] = useState(false);
    const toggleFrameT = () => {
        setframeT(prevCheck => !prevCheck);
    }
    const [selectT1, setSelectT1] = useState(false);
    const toggleSelectT1 = () => {
        setSelectT1(prevCheck => !prevCheck);
    }
    const [selectT2, setSelectT2] = useState(false);
    const toggleSelectT2 = () => {
        setSelectT2(prevCheck => !prevCheck);
    }
    const [selectT3, setSelectT3] = useState(false);
    const toggleSelectT3 = () => {
        setSelectT3(prevCheck => !prevCheck);
    }
    const [selectT4, setSelectT4] = useState(false);
    const toggleSelectT4 = () => {
        setSelectT4(prevCheck => !prevCheck);
    }

    return {
        toggleFrameT,
        frameT,
        toggleSelectT1,
        selectT1,
        toggleSelectT2,
        selectT2,
        toggleSelectT3,
        selectT3,
        toggleSelectT4,
        selectT4,
    }
}

export default useMatchingFrameT;
