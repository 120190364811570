import { useEffect, useState } from "react";
import { SideBarMenuData } from "../../../utils/globals";
import { useLocation } from "react-router-dom";

function useDashboardSidebar(role) {
  const location = useLocation();

  let menuItems = SideBarMenuData[role]?.links;
  menuItems ||= [];
  const [activeLink, setActiveLink] = useState(null);

  //Listen for changes in location.pathname.
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  //Look for the active link based on location.pathname.
  menuItems.map((item) => {
    if (item.to == location.pathname) item.active = true;
    else item.active = false;
  });

  return { menuItems };
}

export default useDashboardSidebar;
