import { ToastContainer } from "react-toastify";
import ScrollToTop from "../../components/ScrollToTop";
import useAuthCheck from "../../hooks/useAuthCheck";
import ConfirmModal from "../../components/ConfirmModal";
import { performLogout } from "../../utils/utilityFunctions";
import { useState } from "react";
import useRoleAccessCheck from "../../hooks/useRoleAccessCheck";

function MainContainer({ component: Component, isProtected, role }) {
  useAuthCheck(isProtected);
  useRoleAccessCheck(role);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  return (
    <>
      <ScrollToTop></ScrollToTop>
      <Component></Component>
      <ToastContainer></ToastContainer>
      <ConfirmModal
        show={showLogoutConfirm}
        setShow={setShowLogoutConfirm}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          performLogout();
        }}
        onCancel={() => {}}
      ></ConfirmModal>
    </>
  );
}
export default MainContainer;
