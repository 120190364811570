import { useState } from "react";

function useMatchingFrameG() {
    const [frameH, setframeH] = useState(false);
    const toggleFrameH = () => {
        setframeH(prevCheck => !prevCheck);
    }

    const [selectG1, setSelectG1] = useState(false);
    const toggleSelectG1 = () => {
        setSelectG1(prevCheck => !prevCheck);
    }
    const [selectG2, setSelectG2] = useState(false);
    const toggleSelectG2 = () => {
        setSelectG2(prevCheck => !prevCheck);
    }
    const [selectG3, setSelectG3] = useState(false);
    const toggleSelectG3 = () => {
        setSelectG3(prevCheck => !prevCheck);
    }
    const [selectG4, setSelectG4] = useState(false);
    const toggleSelectG4 = () => {
        setSelectG4(prevCheck => !prevCheck);
    }
    const [selectG5, setSelectG5] = useState(false);
    const toggleSelectG5 = () => {
        setSelectG5(prevCheck => !prevCheck);
    }
    const [selectG6, setSelectG6] = useState(false);
    const toggleSelectG6 = () => {
        setSelectG6(prevCheck => !prevCheck);
    }

    return {
        toggleFrameH,
        frameH,
        toggleSelectG1,
        selectG1,
        toggleSelectG2,
        selectG2,
        toggleSelectG3,
        selectG3,
        toggleSelectG4,
        selectG4,
        toggleSelectG5,
        selectG5,
        toggleSelectG6,
        selectG6,
    }
}

export default useMatchingFrameG;
