function FrameICard({
  toggleFrameI,
  frameI,
  toggleFrameJ,
  frameJ,
  selectI1, 
  toggleSelectI1,
  selectI2, 
  toggleSelectI2,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
    <h1>Are you 16 or under?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          {/* <div className="question">
          Get Matched!
          </div>
          <div className="description">Are you qualified to work with diagnosed mental health conditions or personality disorders?</div> */}
          <ul className="one-col-ul" onClick={appendAnswers}>
            <li className={selectI1 ? "li-selected" : ""} onClick={toggleSelectI1} id='no-not-under-16'>No</li>
            <li className={selectI2 ? "li-selected" : ""} onClick={toggleSelectI2} id='yes-under-16'>Yes - by choosing this option you are confirming that you have the consent of your parent or guardian to use these services</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
              if (
              selectI1 ||
              selectI2
            ) {
              updateProgressStatus()
              toggleFrameJ();
              toggleFrameI();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameICard;
