import { useState } from "react";

function useMatchingFrameQ() {

    const [frameQ, setframeQ] = useState(false);
    const toggleFrameQ = () => {
        setframeQ(prevCheck => !prevCheck);
    }
    const [selectQ1, setSelectQ1] = useState(false);
    const toggleSelectQ1 = () => {
        setSelectQ1(prevCheck => !prevCheck);
    }
    const [selectQ2, setSelectQ2] = useState(false);
    const toggleSelectQ2 = () => {
        setSelectQ2(prevCheck => !prevCheck);
    }
    const [selectQ3, setSelectQ3] = useState(false);
    const toggleSelectQ3 = () => {
        setSelectQ3(prevCheck => !prevCheck);
    }
    const [selectQ4, setSelectQ4] = useState(false);
    const toggleSelectQ4 = () => {
        setSelectQ4(prevCheck => !prevCheck);
    }
    const [selectQ5, setSelectQ5] = useState(false);
    const toggleSelectQ5 = () => {
        setSelectQ5(prevCheck => !prevCheck);
    }

    return {
        toggleFrameQ,
        frameQ,
        toggleSelectQ1,
        selectQ1,
        toggleSelectQ2,
        selectQ2,
        toggleSelectQ3,
        selectQ3,
        toggleSelectQ4,
        selectQ4,
        toggleSelectQ5,
        selectQ5,
    }
}

export default useMatchingFrameQ;
