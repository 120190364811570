function PractitionerCard({ toprated, photo, name, position, message, rate }) {
  return (
    <div className="card-border">
      <div className="practitioner-card">
        <div className="row m-0">
          <div className="col-4">
            <img src={photo}></img>
            {toprated && <div className="top-badge">TOP RATED</div>}
          </div>
          <div className="col-8">
            <div className="name">{name}</div>
            <div className="position">{position}</div>
            <div className="message">{message}</div>
            <div className="rate">
              <span className="figure">AUD ${rate}</span>
              <span className="persession">per session</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PractitionerCard;
