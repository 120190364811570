import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { getInbox, getMessages } from "../api";
import { createJSONStorage } from "zustand/middleware";

const initialState = {
  inbox: null,
  convo: null,
};

const store = (set, get) => ({
  //state
  ...initialState,

  //actions
  clear: () => set(initialState),
  setInbox: (state) => set({ inbox: state }),
  setConvo: (state) => set({ convo: state }),
  getInbox: async () => {
    try {
      let result = await getInbox();

      if (result.error) {
        set(initialState);
      } else {
        set({ inbox: result.result });
      }
      return result;
    } catch (e) {
      const errorMessage = `Message Store Error [getInbox]: ${e.message}`;
      console.log(errorMessage);
      throw Error(errorMessage);
    }
  },
  getConvo: async (recipient) => {
    try {
      let params = {};
      params.recipient = recipient;
      const result = await getMessages(params);

      if (result.error) {
        set({ convo: [] });
      } else {
        set({ convo: result.result.reverse() });
      }
      return result;
    } catch (e) {
      const errorMessage = `Message Store Error [getConvo]: ${e.message}`;
      console.log(errorMessage);
      throw Error(errorMessage);
    }
  },
});

export const useMessageStore = create(devtools(immer(store)));
