import Doctor from "../../../assets/images/dr_1.jpg";
import CalendarIcon from "../../../assets/images/icon_calendar.png";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import { Link } from "react-router-dom";

function SessionCard() {
  return (
    <>
      <div className="session-no-schedule">
        <span><p>While we are getting ready for launch, you can start to fill out your profile and upload your certifications - which are required for your profile to be approved.</p><p>We'll keep you informed as we release new features. If you have any issues, please contact us.</p></span>
      </div>
    </>
  );
}

export default SessionCard;
