import { useState } from "react";

function useMatchingFrameE() {
    const [frameF, setframeF] = useState(false);
    const toggleFrameF = () => {
        setframeF(prevCheck => !prevCheck);
    }

    const [selectE1, setSelectE1] = useState(false);
    const toggleSelectE1 = () => {
        setSelectE1(prevCheck => !prevCheck);
    }
    const [selectE2, setSelectE2] = useState(false);
    const toggleSelectE2 = () => {
        setSelectE2(prevCheck => !prevCheck);
    }
    const [selectE3, setSelectE3] = useState(false);
    const toggleSelectE3 = () => {
        setSelectE3(prevCheck => !prevCheck);
    }
    const [selectE4, setSelectE4] = useState(false);
    const toggleSelectE4 = () => {
        setSelectE4(prevCheck => !prevCheck);
    }
    const [selectE5, setSelectE5] = useState(false);
    const toggleSelectE5 = () => {
        setSelectE5(prevCheck => !prevCheck);
    }
    const [selectE6, setSelectE6] = useState(false);
    const toggleSelectE6 = () => {
        setSelectE6(prevCheck => !prevCheck);
    }
    const [selectE7, setSelectE7] = useState(false);
    const toggleSelectE7 = () => {
        setSelectE7(prevCheck => !prevCheck);
    }
    const [selectE8, setSelectE8] = useState(false);
    const toggleSelectE8 = () => {
        setSelectE8(prevCheck => !prevCheck);
    }
    const [selectE9, setSelectE9] = useState(false);
    const toggleSelectE9 = () => {
        setSelectE9(prevCheck => !prevCheck);
    }
    const [selectE10, setSelectE10] = useState(false);
    const toggleSelectE10 = () => {
        setSelectE10(prevCheck => !prevCheck);
    }
    const [selectE11, setSelectE11] = useState(false);
    const toggleSelectE11 = () => {
        setSelectE11(prevCheck => !prevCheck);
    }

    return {
        toggleFrameF,
        frameF,
        toggleSelectE1,
        selectE1,
        toggleSelectE2,
        selectE2,
        toggleSelectE3,
        selectE3,
        toggleSelectE4,
        selectE4,
        toggleSelectE5,
        selectE5,
        toggleSelectE6,
        selectE6,
        toggleSelectE7,
        selectE7,
        toggleSelectE8,
        selectE8,
        toggleSelectE9,
        selectE9,
        toggleSelectE10,
        selectE10,
        toggleSelectE11,
        selectE11,
    }
}

export default useMatchingFrameE;
