import { useState, useEffect } from "react";
import { formattedDateProvider } from "../utils/utilityFunctions";

function useDateTimePill() {
  const now = formattedDateProvider(new Date());
  const [date, setDate] = useState(now.date);
  const [day, setDay] = useState(now.day);
  const [time, setTime] = useState(now.time);

  const refreshClock = () => {
    const { date, time, day } = formattedDateProvider(new Date());
    setDate(date);
    setDay(day);
    setTime(time);
  };

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return { date, day, time };
}

export default useDateTimePill;
