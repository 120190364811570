import DashboardLayout from "../../layouts/DashboardLayout";
import { useState } from "react";
import { Link } from "react-router-dom";
import Asterisk from "../../assets/images/asterisk_noborder.png";
import Calendar from "react-calendar";
import ReactDatePicker from "react-datepicker";
import useAppointment from "../../hooks/useAppointment";
import useLoader from "../../hooks/useLoader";
import useProfile from "../../hooks/useProfile";
import PractitionerLists from "./components/PractitionerLists";
import { useAppointmentStore } from "../../store/appointmentStore";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import PractitionerSchedule from "../../components/PractitionerSchedule";

function PractitionerCalendar() {
  const { _id } = useProfile();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();

  return (
    <>
      <DashboardLayout>
        <div className="practitioner-schedule">
          <div className="title-bar">
            <h1></h1>
          </div>
          <PractitionerSchedule></PractitionerSchedule>
        </div>
      </DashboardLayout>
    </>
  );
}

export default PractitionerCalendar;
