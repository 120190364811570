import { usePractitionerStore } from "../store/practitionerStore";
import { useEffect, useState } from "react";

function usePractitionerProfile(_id) {
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const doFetchPractitioner = usePractitionerStore(
    (state) => state.doFetchPractitioner
  );
  useEffect(() => {
    try {
      const fetch = async () => {
        const result = await doFetchPractitioner({ _id });
        if (result.error) {
          setErrorMessage(result.message);
          setProfile(null);
          return;
        }
        setProfile(result.result[0]);
      };
      fetch();
    } catch (e) {
      console.log("usePractitionerProfile.useEffect", e.message);
    }
  }, []);

  //errorMessage is null when no errors encountered.
  return { profile, errorMessage };
}

export default usePractitionerProfile;
