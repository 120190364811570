import { Link } from "react-router-dom";

export const Email = ({ value, onChange }) => (
  <>
    <div className="labels mt-5">Email Address</div>
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Your email address"
    ></input>
  </>
);

export const Actions = ({ onAdd }) => (
  <>
    <button className="lumi-btn" onClick={onAdd}>
      Login
    </button>
  </>
);

export const Password = ({
  value,
  onChange,
  isReadable,
  clickHandler,
  icon,
}) => (
  <>
    <div className="labels mt-4">Password</div>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Password"
        type={isReadable ? "text" : "password"}
      ></input>
    </div>
  </>
);

export const SignupLink = (payload) => (
  <>
    <h5>
      <Link to="/signup">Sign Up</Link> if you don't have an account yet.
    </h5>
  </>
);

export const ErrorMessage = ({ error }) => (
  <>
    <h5 className="error">{error}</h5>
  </>
);
