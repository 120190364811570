import { useState } from "react";

function useMatchingFrameK() {

    const [frameK, setframeK] = useState(false);
    const toggleFrameK = () => {
        setframeK(prevCheck => !prevCheck);
    }

    const [frameL, setframeL] = useState(false);
    const toggleFrameL = () => {
        setframeL(prevCheck => !prevCheck);
    }

    const [selectK1, setSelectK1] = useState(false);
    const toggleSelectK1 = () => {
        setSelectK1(prevCheck => !prevCheck);
        setSelectK2(false);
    }
    const [selectK2, setSelectK2] = useState(false);
    const toggleSelectK2 = () => {
        setSelectK1(false);
        setSelectK2(prevCheck => !prevCheck);
    }

    return {
        toggleFrameK,
        frameK,
        toggleFrameL,
        frameL,
        toggleSelectK1,
        selectK1,
        toggleSelectK2,
        selectK2,
    }
}

export default useMatchingFrameK;
