import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { usePractitionerStore } from "../../store/practitionerStore";
import { useUserStore } from "../../store/userStore";

import PlusIcon from "../../assets/images/icon_plus_round.png";
import ClientPhoto from "../../assets/images/dr_1.jpg";
import uploadSupportingDocs from "../../assets/images/upload-supporting-docs.png";

import Editor from "../../components/QuillEditor";
import useProfile from "../../hooks/useProfile";
import usePractitionerProfile from "../../hooks/usePractitionerProfile";
import useLoader from "../../hooks/useLoader";
import {
  flattenValuesFromSelect,
  inflateValuesFromSelect,
} from "../../utils/utilityFunctions";
import {
  categoryOptions,
  professionOptions,
  gpOptions,
  specialisedCategoryOptions,
  accuratePersonalityTraitsOptions,
  consultationOptions,
} from "../../utils/globals";
import UploadIcon from "../../assets/images/icon_upload.png";
import MedCert from "../../assets/images/med-cert.png";
import CropperModal from "../../components/CropperModal";
import { updateProfilePhoto } from "../../api";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

function auto_grow() {
  const textareas = document.getElementsByTagName("textarea");

  for (let t of textareas) {
    t.style.height = "5px";
    t.style.height = t.scrollHeight + "px";
  }
}

function PractitionerProfile() {
  const navigate = useNavigate();
  const [professionVal, setProfessionVal] = useState("");
  const [serviceVal, setServiceVal] = useState("");
  const [backgroundVal, setBackgroundVal] = useState("");
  const [certificationsVal, setCertificationsVal] = useState("");

  const { _id, doUpdateProfilePhoto } = useProfile();
  const doUpdatePractitioner = usePractitionerStore(
    (state) => state.doUpdatePractitioner
  );

  const setProfile = useUserStore((state) => state.setProfile);

  const { profile: practitionerProfile, errorMessage: fetchMessage } =
    usePractitionerProfile(_id);

  useEffect(() => {
    if (practitionerProfile) {
      setProfessionVal(practitionerProfile.position);
      setServiceVal(practitionerProfile.message);
      setBackgroundVal(practitionerProfile.background);
      setCertificationsVal(practitionerProfile.certifications);
      setSelectedCategories(
        inflateValuesFromSelect(practitionerProfile.categories)
      );
      setSelectedGpCategories(
        inflateValuesFromSelect(practitionerProfile.gpcategories)
      );
      setSelectedSpecialisedCategories(
        inflateValuesFromSelect(practitionerProfile.specialisedcategories)
      );
      setSelectedConsultationCategories(
        inflateValuesFromSelect(practitionerProfile.consultationcategories)
      );
      setSelectedAccuratePersonalityTraitsCategories(
        inflateValuesFromSelect(
          practitionerProfile.accuratepersonalitytraitscategories
        )
      );
      setSelectedProfession(
        inflateValuesFromSelect([practitionerProfile.position])
      );
      setSelectedLocation(practitionerProfile.location);
      setSelectedConsulationLocation(practitionerProfile.consultationlocation);
      setSelectedWorktype(practitionerProfile.worktype);
      setSelectedAgeScopetype(practitionerProfile.agescopetype);
      setSelectedClienttype(practitionerProfile.clienttype);
      setSelectedConsultationtype(practitionerProfile.consultationtype);
      setSelectedQualifiedMentalProftype(
        practitionerProfile.qualifiedmentalproftype
      );
      setSelectedSupportType(practitionerProfile.supporttype);
    }
  }, [practitionerProfile]);

  const professionRef = useRef();
  const [selectCategories, setSelectedCategories] = useState();
  const [selectGpCategories, setSelectedGpCategories] = useState();
  const [
    selectAccuratePersonalityTraitsCategories,
    setSelectedAccuratePersonalityTraitsCategories,
  ] = useState();
  const [selectSpecialisedCategories, setSelectedSpecialisedCategories] =
    useState();
  const [selectConsultationCategories, setSelectedConsultationCategories] =
    useState();
  const [selectedProfession, setSelectedProfession] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedConsulationLocation, setSelectedConsulationLocation] =
    useState();
  const [selectWorkType, setSelectedWorktype] = useState(0);
  const [selectAgeScopeType, setSelectedAgeScopetype] = useState(0);
  const [selectClientType, setSelectedClienttype] = useState(0);
  const [selectConsultationType, setSelectedConsultationtype] = useState(0);
  const [selectQualifiedMentalProftype, setSelectedQualifiedMentalProftype] =
    useState(0);
  const [selectSupportType, setSelectedSupportType] = useState(0);

  const handleCategorySelect = (data) => {
    setSelectedCategories(data);
  };

  const handleGpSelect = (data) => {
    setSelectedGpCategories(data);
  };

  const handleaccuratePersonalityTraitsSelect = (data) => {
    setSelectedAccuratePersonalityTraitsCategories(data);
  };

  const handleSpecialisedCategoriesSelect = (data) => {
    setSelectedSpecialisedCategories(data);
  };

  const handleConsultationCategoriesSelect = (data) => {
    setSelectedConsultationCategories(data);
  };

  const handleWorkType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedWorktype(value);
    }
  };

  // const handleAgeScopeType = (e) => {
  //   const { nodeName, value } = e.target;
  //   if (nodeName === 'INPUT') {
  //     setSelectedAgeScopetype(value);
  //   }
  // };

  const handleConsultationLocation = (e) => {
    setTimeout(() => {
      setSelectedConsulationLocation(e.target.value);
      closeMessages();
    }, 3000);
  };

  const consultationRef = useRef();

  const handleClientType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedClienttype(value);
    }
  };

  const handleConsultationType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedConsultationtype(value);
    }
  };

  const handleQualifiedMentalProfType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedQualifiedMentalProftype(value);
    }
  };

  const handleSupportType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedSupportType(value);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <div className="lumi-drop-indicator">
        <img src={PlusIcon}></img>
      </div>
    );
  };

  useEffect(() => {
    auto_grow();
  }, []);

  useEffect(() => {
    if (fetchMessage) showError(fetchMessage);
  }, [fetchMessage]);

  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const uploadInput = useRef();

  const [showCropper, setShowCropper] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [cropperFile, setCropperFile] = useState(null);

  const handleCropperConfirm = async (file) => {
    setShowCropper(false);
    setAvatarUrl(null);
    setCropperFile(file);

    showLoader("Uploading photo. Please wait...");
    const result = await updateProfilePhoto({ photo: file.url, _id: _id });
    if (result.error) {
      showCropper(result.message);
    } else {
      setProfile(result.result);
      showMessage("Profile photo has been updated successfully.");
      navigate(0);
    }
    closeMessages();
  };

  const handleCropperCancel = () => {
    setShowCropper(false);
    setCropperFile(null);
    setAvatarUrl(null);
  };

  doUpdateProfilePhoto({ _id });

  const getPhotoSource = () => {
    if (cropperFile) {
      return cropperFile.url;
    }
    if (practitionerProfile.photo) return practitionerProfile.photo;
    else return UploadIcon;
  };

  return (
    <DashboardLayout>
      <div className="practitioner-profile">
        <h1 className="font-body font-semibold text-lumi-blue text-2xl mb-4">
          Profile
        </h1>

        {practitionerProfile ? (
          <>
            <div className="flex">
              <div className="dash-prac-profile-left ">
                <div className="profession-box">
                  <h2>Profession or Title</h2>
                  <span>
                    If you need to change your profession title, please contact
                    us.
                  </span>
                  <Select
                    // options={professionOptions}
                    value={selectedProfession}
                    onChange={setSelectedProfession}
                    placeholder={"Select profession"}
                  />
                </div>

                <div className="description-box">
                  <h2>Description of Service</h2>
                  <span>
                    Tell potential clients who you are, what you specialise in,
                    and how your unique approach can support their journey to
                    wellness.
                  </span>
                  <Editor
                    placeholder={""}
                    value={serviceVal}
                    setValue={setServiceVal}
                  ></Editor>

                  <h2>Professional Background and Experience</h2>
                  <span>
                    Share your qualifications, specialties, and key experiences
                    that shape your unique approach and expertise.
                  </span>
                  <Editor
                    placeholder={""}
                    value={backgroundVal}
                    setValue={setBackgroundVal}
                  ></Editor>
                </div>

                <div className="certifications-box display-hidden">
                  <h2>Certifications</h2>
                  <Editor
                    placeholder={""}
                    value={certificationsVal}
                    setValue={setCertificationsVal}
                  ></Editor>
                  {/*<textarea defaultValue={""} onInput={auto_grow}></textarea>*/}
                </div>

                {/* <div className="multi-select-box">
                <h2>
                  Location
                  <span>Enter your Place of Location</span>
                </h2>

                <input
                  type="text"
                  placeholder="Enter location"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                />
              </div> */}

                <div className="category-box">
                  <h2>
                    Category
                    <span>Choose the service types you’d like to offer.</span>
                  </h2>

                  <Select
                    options={categoryOptions}
                    value={selectCategories}
                    onChange={handleCategorySelect}
                    isMulti={true}
                    classNamePrefix="lumi-category"
                    components={{ DropdownIndicator }}
                    placeholder={"Select categories"}
                  />
                </div>

                {/* <div className="single-select-box">
                <h2>
                  Age scoping
                  <span>Do you work with youth under the age of 16</span>
                </h2>
                <div className="radio-buttons" onChange={handleAgeScopeType}>
                  <label className="radio-button">
                    <input
                      type="radio"
                      name="ageScope"
                      value="no"
                      checked={selectAgeScopeType == "no" ? true : false}
                    />
                    <span className="radio-tick"></span>
                    No
                  </label>
                  <label className="radio-button">
                    <input
                      type="radio"
                      name="ageScope"
                      value="yes"
                      checked={selectAgeScopeType == "yes" ? true : false}
                    />
                    <span className="radio-tick"></span>
                    Yes
                  </label>
                </div>
              </div> */}

                <div className="multi-select-box">
                  <h2>
                    Specialised Category
                    <span>
                      Please select up to 7 of the below categories that you
                      specialise in or you most prefer to work with. Bare with
                      us, we know there is a lot here, but we want to make sure
                      both you and the patient are getting the best fit to
                      ensure a successful match:
                    </span>
                  </h2>

                  <Select
                    options={specialisedCategoryOptions}
                    value={selectSpecialisedCategories}
                    onChange={handleSpecialisedCategoriesSelect}
                    isMulti={true}
                    classNamePrefix="lumi-category"
                    components={{ DropdownIndicator }}
                    placeholder={"Select categories"}
                  />
                </div>

                <div className="single-select-box">
                  <h2>
                    Are you a qualified Mental Health Professional?
                    <span>
                      If registering as a qualified mental health practitioner,
                      please confirm you have a current and valid registration
                      with either PACFA, ACA level 2, 3 or 4, AHPRA, AASW
                      (AMHSW) or APD. Please upload supporting documentation to
                      the Certifications area of your dashboard.
                    </span>
                  </h2>
                  <div
                    className="radio-buttons"
                    onChange={handleQualifiedMentalProfType}
                  >
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="qualifiedMentalProf"
                        value="no"
                        checked={
                          selectQualifiedMentalProftype == "no" ? true : false
                        }
                      />
                      <span className="radio-tick"></span>
                      No
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="qualifiedMentalProf"
                        value="yes"
                        checked={
                          selectQualifiedMentalProftype == "yes" ? true : false
                        }
                      />
                      <span className="radio-tick"></span>
                      Yes
                    </label>
                  </div>
                </div>

                <div className="single-select-box">
                  <h2>
                    Support Type
                    <span>
                      Which of the following statements best describes the type
                      of support you offer - Please tick one:
                    </span>
                  </h2>
                  <div className="radio-buttons" onChange={handleSupportType}>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="supportType"
                        value="talk"
                        checked={selectSupportType == "talk" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Talk based therapy
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="supportType"
                        value="natural"
                        checked={selectSupportType == "natural" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Supporting the mind and body using holistic/Natural
                      therapies
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="supportType"
                        value="diet"
                        checked={selectSupportType == "diet" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Supporting the mind and body as a qualified dietician or
                      nutritionist
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="supportType"
                        value="mentor"
                        checked={selectSupportType == "mentor" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Coaching and mentoring to support my clients set and
                      achieve goals, either personally, professionally or
                      financially
                    </label>
                  </div>
                </div>

                <div className="single-select-box">
                  <h2>
                    Client Type
                    <span>Do you prefer working with clients that are:</span>
                  </h2>
                  <div className="radio-buttons" onChange={handleClientType}>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="clientType"
                        value="<16"
                        checked={selectClientType == "<16" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Youth under 16
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="clientType"
                        value="<30"
                        checked={selectClientType == "<30" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      under 30
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="clientType"
                        value="30-50"
                        checked={selectClientType == "30-50" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      between 30-50
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="clientType"
                        value=">50"
                        checked={selectClientType == ">50" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Over 50
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="clientType"
                        value="np"
                        checked={selectClientType == "np" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      No Preference
                    </label>
                  </div>
                </div>

                <div className="multi-select-box">
                  <h2>
                    Consultations
                    <span>
                      Do you offer In-Person and / or Online consultations?
                    </span>
                  </h2>

                  <Select
                    options={consultationOptions}
                    value={selectConsultationCategories}
                    onChange={handleConsultationCategoriesSelect}
                    isMulti={true}
                    classNamePrefix="lumi-category"
                    components={{ DropdownIndicator }}
                    placeholder={"Select Consultation Type"}
                  />

                  <Autocomplete
                    apiKey="AIzaSyCrOcBM7vchFwXUxtwXwUfyfR-EX_sZNlQ"
                    // apiKey={process.env.process.env.REACT_APP_GOOGLE_MAP_KEY}
                    onPlaceSelected={(place) => {
                      console.log(place);
                    }}
                    options={{
                      types: ["geocode", "establishment"],
                    }}
                    className="autoComplete"
                    value={selectedConsulationLocation}
                    onChange={(e) =>
                      setSelectedConsulationLocation(e.target.value)
                    }
                    ref={consultationRef}
                  />
                </div>

                <div className="multi-select-box">
                  <h2>
                    Accurate Personality Traits
                    <span>
                      Please tick the two most accurate personality traits that
                      best describe you from the list below:
                    </span>
                  </h2>

                  <Select
                    options={accuratePersonalityTraitsOptions}
                    value={selectAccuratePersonalityTraitsCategories}
                    onChange={handleaccuratePersonalityTraitsSelect}
                    isMulti={true}
                    classNamePrefix="lumi-category"
                    components={{ DropdownIndicator }}
                    placeholder={"Select categories"}
                  />
                </div>

                <div className="single-select-box display-hidden">
                  <h2>
                    Work type
                    <span>Do you work with couples or families</span>
                  </h2>
                  <div className="radio-buttons" onChange={handleWorkType}>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="workType"
                        value="yes"
                        checked={selectWorkType == "yes" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      Yes
                    </label>
                    <label className="radio-button">
                      <input
                        type="radio"
                        name="workType"
                        value="no"
                        checked={selectWorkType == "no" ? true : false}
                      />
                      <span className="radio-tick"></span>
                      No
                    </label>
                  </div>
                </div>

                <div className="multi-select-box">
                  <h2>
                    GP Care Plans
                    <span>
                      If you accept GP referred care plans, please select all
                      that you accept below
                    </span>
                  </h2>

                  <Select
                    options={gpOptions}
                    value={selectGpCategories}
                    onChange={handleGpSelect}
                    isMulti={true}
                    classNamePrefix="lumi-category"
                    components={{ DropdownIndicator }}
                    placeholder={"Select categories"}
                  />
                </div>
              </div>
              <div className="dash-prac-profile-right tablet:hidden">
                <div className="photo-box">
                  <h2>Profile Picture</h2>
                  <img
                    className="profile-photo"
                    src={getPhotoSource()}
                    onClick={() => {
                      uploadInput.current.click();
                    }}
                  ></img>
                  <input
                    className="image-file-input"
                    type="file"
                    ref={uploadInput}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => {
                      if (e.target.files) {
                        setAvatarUrl(URL.createObjectURL(e.target.files[0]));
                        setShowCropper(true);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="clearboth"></div>
            </div>
            <button
              className="save-button lumi-btn"
              onClick={async () => {
                showLoader("Saving changes..");
                let data = {};
                data.position = selectedProfession.value;
                data.message = serviceVal;
                data.background = backgroundVal;
                data.certifications = certificationsVal;
                data.categories = flattenValuesFromSelect(selectCategories);
                data.gpcategories = flattenValuesFromSelect(selectGpCategories);
                data.accuratepersonalitytraitscategories =
                  flattenValuesFromSelect(
                    selectAccuratePersonalityTraitsCategories
                  );
                data.specialisedcategories = flattenValuesFromSelect(
                  selectSpecialisedCategories
                );
                data.consultationcategories = flattenValuesFromSelect(
                  selectConsultationCategories
                );
                data.worktype = selectWorkType;
                data.supporttype = selectSupportType;
                data.agescopetype = selectAgeScopeType;
                data.clienttype = selectClientType;
                data.consultationtype = selectConsultationType;
                data.qualifiedmentalproftype = selectQualifiedMentalProftype;
                data._id = _id;
                data.location = selectedLocation;
                data.consultationlocation = consultationRef.current.value;

                const response = await doUpdatePractitioner(data);

                closeMessages();

                if (response.error) {
                  showError(response.message);
                  return;
                }

                showMessage("Profile updated successfully!");
              }}
            >
              Save Changes
            </button>
          </>
        ) : (
          <div>Loading profile</div>
        )}
      </div>

      <CropperModal
        show={showCropper}
        avatarUrl={avatarUrl}
        handleCancel={handleCropperCancel}
        handleConfirm={handleCropperConfirm}
        aspectRatio={1 / 1}
      ></CropperModal>
    </DashboardLayout>
  );
}

export default PractitionerProfile;
