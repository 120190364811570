import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import PendingApprovalCard from "./components/PendingApprovalCard";
import useAdminPendingApproval from "../../hooks/useAdminPendingApproval";
import { useAdminStore } from "../../store/adminStore";
import useProfile from "../../hooks/useProfile";
import useLoader from "../../hooks/useLoader";
import { Link, useParams, useNavigate } from "react-router-dom";

function AdminDashboardApproval({ Component: component }) {
  const { role, firstName } = useProfile();
  const { pending, redirection, handler, accepted, hasAccepted } = useAdminPendingApproval();
  const doAcceptPending = useAdminStore((state) => state.doAcceptPending);
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const navigate = useNavigate();
  const redirectToAdmin = (id) => navigate(`/admin/practitioner/profilepage/${id}`);

  return (
    <>
      <DashboardLayout>
        <div className="client-dashboard-home">
          <div className="title-bar">
            <h1>Admin Pending Approvals <span className="pending-approval">({pending ? pending.length : 0})</span></h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>
          <div className="yellow-box">
            {pending && pending.length > 0 ? (
              pending.map((item) => {
                return <PendingApprovalCard 
                  key={item._id}
                  firstname={item.firstname} 
                  lastname={item.lastname}
                  role={item.role}
                  status={item.status}
                  id={item._id}
                  doAcceptPending={doAcceptPending}
                  handler={handler}
                  showLoader={showLoader}
                  showMessage={showMessage}
                  closeMessages={closeMessages}
                  showError={showError}
                  accepted={accepted}
                  hasAccepted={hasAccepted}
                  redirectToAdmin={redirectToAdmin}
                  photo={item.photo}
                />
              })
            ) : (
              <div align="center"> No user pending.</div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default AdminDashboardApproval;
