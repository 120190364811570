import Doctor from "../../../assets/images/dr_1.jpg";
import CalendarIcon from "../../../assets/images/icon_calendar.png";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import { Link } from "react-router-dom";

function SessionCard() {
  return (
    <>
      <div className="session-card">
        <div className="row-container">
          <div className="half">
            <img className="photo" src={Doctor}></img>
            <h3>Dr. Hazel Helman</h3>
            <h4>
              <span>Online appointment</span>
            </h4>
          </div>
          <div className="half">
            <span className="date">
              <img className="calendar-icon" src={CalendarIcon}></img>16 Apr
              2023
            </span>
            <span className="day-time">12.30 pm</span>
          </div>
        </div>
        <div className="row-container mt-4">
          <div className="half">
            <button className="lumi-btn join-call">Join Call</button>
          </div>
          <div className="half text-right">
            <div className="circle-icon">
              <img src={ContactIcon}></img>
            </div>
            <div className="circle-icon">
              <img src={EmailIcon}></img>
            </div>
          </div>
        </div>
        <div className="text-right mt-4">
          <Link>Cancel Session</Link>
        </div>
      </div>
    </>
  );
}

export default SessionCard;
