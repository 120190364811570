import { useState, useEffect } from "react";

function useBrowsePractitioner({ onChange }) {
  const [mode, setMode] = useState("list");

  useEffect(() => {
    onChange(mode);
  }, [mode]);

  return {
    mode,
    setMode,
  };
}

export default useBrowsePractitioner;
