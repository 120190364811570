import { executeMultipleQuestions } from "../../../helpers/practitionerHelper";

function FrameJCard({
  toggleFrameE,
  frameE,
  toggleFrameJ,
  frameJ,
  toggleFrameN,
  frameN,
  toggleFrameO,
  frameO,
  toggleFrameP,
  frameP,
  toggleFrameQ,
  frameQ,
  toggleFrameR,
  frameR,
  toggleFrameT,
  frameT,
  toggleFrameS,
  frameS,
  selectJ1, 
  toggleSelectJ1,
  selectJ2, 
  toggleSelectJ2,
  selectJ3, 
  toggleSelectJ3,
  selectJ4, 
  toggleSelectJ4,
  selectJ5, 
  toggleSelectJ5,
  selectJ6, 
  toggleSelectJ6,
  selectJ7, 
  toggleSelectJ7,
  selectJ8, 
  toggleSelectJ8,
  selectJ9, 
  toggleSelectJ9,
  selectJ10, 
  toggleSelectJ10,
  selectJ11, 
  toggleSelectJ11,
  selectJ12, 
  toggleSelectJ12,
  selectJ13, 
  toggleSelectJ13,
  selectJ14, 
  toggleSelectJ14,
  selectJ15, 
  toggleSelectJ15,
  selectJ16, 
  toggleSelectJ16,
  selectJ17, 
  toggleSelectJ17,
  toggleFrameMultiple,
  frameMultiple,
  frameFollowupQuestion,
  updateProgressStatus,
}) {
  const multipleFollowupQuestions = (e) => {
    toggleFrameMultiple(e);
  };

  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          {/* <div className="question"></div> */}
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul" onClick={multipleFollowupQuestions}>
            <li className={selectJ1 ? "li-selected" : ""} onClick={toggleSelectJ1} >Feelings of anxiety, overwhelm, depression or self esteem</li>
            <li className={selectJ2 ? "li-selected" : ""} onClick={toggleSelectJ2}>Sleep issues, fatigue or low energy</li>
            <li className={selectJ3 ? "li-selected" : ""} onClick={toggleSelectJ3}>Grief and loss</li>
            <li className={selectJ4 ? "li-selected" : ""} onClick={toggleSelectJ4} id="toggleFrameT()">Support navigating those challenging life stages that us women go through</li>
            <li className={selectJ5 ? "li-selected" : ""} onClick={toggleSelectJ5}>Chronic illness and pain</li>
            <li className={selectJ6 ? "li-selected" : ""} onClick={toggleSelectJ6} id="toggleFrameN()">Relationship, family or parenting issues</li>
            <li className={selectJ7 ? "li-selected" : ""} onClick={toggleSelectJ7}>Phobias, fears or panic attacks</li>
            <li className={selectJ8 ? "li-selected" : ""} onClick={toggleSelectJ8}>Managing anger issues</li>
            <li className={selectJ9 ? "li-selected" : ""} onClick={toggleSelectJ9} id="toggleFrameO()">Professional / career growth or concerns</li>
            <li className={selectJ10 ? "li-selected" : ""} onClick={toggleSelectJ10} id="toggleFrameP()">Sexual, sexuality or gender issues</li>
            <li className={selectJ11 ? "li-selected" : ""} onClick={toggleSelectJ11} id="toggleFrameQ()">Trauma, PTSD, sexual abuse, domestic abuse</li>
            <li className={selectJ12 ? "li-selected" : ""} onClick={toggleSelectJ12} id="toggleFrameR()">Navigating life transitions and changes</li>
            <li className={selectJ13 ? "li-selected" : ""} onClick={toggleSelectJ13}>Addiction or substance abuse</li>
            <li className={selectJ14 ? "li-selected" : ""} onClick={toggleSelectJ14} id="toggleFrameS()">Eating disorders or emotional eating concerns</li>
            <li className={selectJ15 ? "li-selected" : ""} onClick={toggleSelectJ15}>Support with mind-gut connection, building positive relationships with food, diet and nutrition concerns</li>
            <li className={selectJ16 ? "li-selected" : ""} onClick={toggleSelectJ16}>Money mindset, financial planning or managing financial stress</li>
            <li className={selectJ17 ? "li-selected" : ""} onClick={toggleSelectJ17}>Spiritual or religious growth and development</li>
          </ul>

          <div className="next" onClick={() => {
            
            if(frameMultiple.length) {
              updateProgressStatus()
              eval(executeMultipleQuestions(frameMultiple));   
              toggleFrameJ();
              if(frameFollowupQuestion.length && frameMultiple.length == 0) {
                console.log('hasframeFollowupQuestionShow');
              }         
            } else if (selectJ6) {
              updateProgressStatus()
              toggleFrameN();
              toggleFrameJ();
            } else if (selectJ9) {
              updateProgressStatus()
              toggleFrameO();
              toggleFrameJ();
            } else if (selectJ10) {
              updateProgressStatus()
              toggleFrameP();
              toggleFrameJ();
            } else if (selectJ11) {
              updateProgressStatus()
              toggleFrameQ();
              toggleFrameJ();
            } else if (selectJ12) {
              updateProgressStatus()
              toggleFrameR();
              toggleFrameJ();
            } else if (selectJ14) {
              updateProgressStatus()
              toggleFrameS();
              toggleFrameJ();
            } else if (selectJ4) {
              updateProgressStatus()
              toggleFrameT();
              toggleFrameJ();
            } else if (selectJ1) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ2) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ16) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ3) {
              updateProgressStatus()
              toggleFrameE();
            } else if (selectJ5) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ7) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ8) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ13) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ17) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else if (selectJ15) {
              updateProgressStatus()
              toggleFrameE();
              toggleFrameJ();
            } else {
             
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameJCard;
