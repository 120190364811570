import React, { useState } from "react";
import usePasswordEye from "../../hooks/usePasswordEye";
import Header from "../../components/Header/index.js";
import Footer from "../../components/Footer/index.js";
import { useUserStore } from "../../store/userStore";
import { Link } from "react-router-dom";
import PractitionerSignUpBody from "../../components/PractitionerSignUpBody";
import { validateEmail } from "../../utils/utilityFunctions";
import useLoader from "../../hooks/useLoader";
import usePractitioner from "../../hooks/usePractitioner";
import { useNavigate } from "react-router-dom";
import HeroAuthSection from "../../components/HeroAuthSection/index.js";
import {
  Profession,
  Name,
  Email,
  Password,
  Password2,
  Actions,
  ErrorMessage,
} from "../../components/forms/AuthForm/form.js";

function PractitionerSignUp() {
  const [profession, setProfession] = useState("Psychologist");
  const [professionText, setProfessionText] = useState("Psychologist");
  const { icon, clickHandler, isReadable } = usePasswordEye();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [role, setRole] = useState("practitioner");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const { professions } = usePractitioner();
  const doRegistration = useUserStore((state) => state.doRegistration);

  return (
    <>
      <Header />
      <HeroAuthSection>
        <div className="flex flex-1 align-center justify-center">
          <div className="flex w-[552px] tablet:w-full mx-8 flex-col align-center justify-center gap-3">
            <h3 className="text-[#69C1BE] font-title text-center font-medium">
              Create a Practitioner Account
            </h3>
            <div className="h-2" />
            <Profession
              value={profession}
              setValue={setProfession}
              professions={professions}
              setValueText={setProfessionText}
            />
            <Name
              firstName={firstname}
              lastName={lastname}
              setFirstname={setFirstname}
              setLastname={setLastname}
            />
            <Email email={email} setEmail={setEmail} />
            <Password
              password={password}
              setPassword={setPassword}
              icon={icon}
              clickHandler={clickHandler}
              isReadable={isReadable}
            />
            <Password2
              password2={password2}
              setPassword2={setPassword2}
              icon={icon}
              clickHandler={clickHandler}
              isReadable={isReadable}
            />

            <ErrorMessage error={error} />
            <div className="flex items-center justify-center gap-2 px-8">
              <input
                type="checkbox"
                className="accent-lumi-rose h-8 w-8 mb-3"
              />
              <p className="text-[#90A4AF] text-sm">
                By creating an account, you agree to have read and understood
                the <Link>Terms and Conditions</Link> before you access the
                LumiLife Platform.
              </p>
            </div>
            <Actions
              title={"Create Practitioner Account"}
              onAdd={async () => {
                try {
                  setError("");

                  if (!profession) {
                    setError("Please set your profession.");
                    return;
                  }

                  if (!firstname) {
                    setError("Please provide your first name.");
                    return;
                  }

                  if (!lastname) {
                    setError("Please provide your last name.");
                    return;
                  }

                  if (!email || !validateEmail(email)) {
                    setError("Please provide a valid email.");
                    return;
                  }

                  if (!password) {
                    setError("Please provide a password.");
                    return;
                  }

                  if (password != password2) {
                    setError("Passwords should match.");
                    return;
                  }

                  showLoader("Processing. Please wait..");
                  const res = await doRegistration(
                    professionText,
                    firstname,
                    lastname,
                    email,
                    password,
                    password2,
                    role
                  );
                  closeMessages();
                  if (res.error) {
                    showError(res.message);
                    return;
                  }

                  setTimeout(() => navigate("/practitioner-login"), 8000);
                  showMessage("Success. Redirecting you to sign in.");
                } catch (error) {
                  console.log(error.message);
                }
              }}
            />

            <p className="font-bold text-center">
              <Link className="text-lumi-rose" to="/practitioner-login">
                Sign In
              </Link>{" "}
              if you have an account.
            </p>
          </div>
        </div>
      </HeroAuthSection>
      <PractitionerSignUpBody />
      <div className="my-24" />
      <Footer />
    </>
  );
}

export default PractitionerSignUp;
