import { useState } from "react";

function useMatchingFrameZ() {

    const [frameZ, setframeZ] = useState(false);
    const toggleFrameZ = () => {
        setframeZ(prevCheck => !prevCheck);
    }

    const [answersFrameA, setAnswersframeA] = useState([]);
    const toggleAnswersFrameA = () => {
        // if(selectD11) {
            setAnswersframeA(['PSYCHOLOGISTS','COUNSELLORS','PSYCHOTHERAPIST','SOCIAL','WORKER','LIFE COACH','NLP PRACTITIONER','NET PRACTITIONER','REIKI HEALER','NET PRACTITIONER','KINESIOLOGISTS','HYPNOTHERAPISTS','HERBAL CHINESE MEDICINE PRACTITIONER','NATUROPATHS','ACUPUNCTURISTS','HOMEOPATHS','AYURVEDIC MEDICINE PRACTITIONERS','WESTERN HERBAL MEDICINE PRACTITIONERS']);
//         }
    }

    const [selectZ1, setSelectZ1] = useState(false);
    const toggleSelectZ1 = () => {
        setSelectZ1(prevCheck => !prevCheck);
    }
    const [selectZ2, setSelectZ2] = useState(false);
    const toggleSelectZ2 = () => {
        setSelectZ2(prevCheck => !prevCheck);
    }
    const [selectZ3, setSelectZ3] = useState(false);
    const toggleSelectZ3 = () => {
        setSelectZ3(prevCheck => !prevCheck);
    }

    const [responseCheck, setResponseCheck] = useState(true);
    const [plainResponse, showSaveResponse] = useState(true);
    const toggleShowSaveResponse = () => {
        showSaveResponse({ isHidden: !plainResponse.isHidden });
        setResponseCheck(prevCheck => !prevCheck);
    }
    const [shareEmail, setShareEmail] = useState(true);
    const toggleShareEmail = () => {
        setShareEmail(prevCheck => !prevCheck);
        console.log('shareEmail:::',shareEmail);
    }

    const [email, setEmail] = useState('');
    
    const style = { display: plainResponse.isHidden ? 'none' : 'block' };

    return {
        toggleFrameZ,
        frameZ,
        toggleSelectZ1,
        selectZ1,
        toggleSelectZ2,
        selectZ2,
        toggleSelectZ3,
        selectZ3,
        toggleAnswersFrameA,
        answersFrameA,
        toggleShowSaveResponse,
        plainResponse,
        style,
        responseCheck,
        setEmail,
        email,
        toggleShareEmail,
        shareEmail

    }
}

export default useMatchingFrameZ;
