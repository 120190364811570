import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import DefaultAvatar from "../../assets/images/icon_default_avatar.png";
import SendIcon from "../../assets/images/icon_send.png";
import { useEffect, useState } from "react";
import useProfile from "../../hooks/useProfile";
import { getTimestampData } from "../../utils/utilityFunctions";
import parse from "html-react-parser";
import { getInbox, getMessages, sendMessage } from "../../api";
import useLoader from "../../hooks/useLoader";
import { useMessageStore } from "../../store/messageStore";

function ClientMessages() {
  const { _id, firstName, lastName, photo } = useProfile();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const [activeContact, setActiveContact] = useState(null);
  const getInbox = useMessageStore((state) => state.getInbox);
  const getConvo = useMessageStore((state) => state.getConvo);
  const setInbox = useMessageStore((state) => state.setInbox);
  const setChats = useMessageStore((state) => state.setConvo);
  const inbox = useMessageStore((state) => state.inbox);
  const chats = useMessageStore((state) => state.convo);

  const initialChats = [
    /*
    {
      _id,
      firstname: "Jane",
      lastname: "Roe",
      message: "Hi!, this is a test message.",
      datetime: 1688974264,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
    },
    {
      _id: "314123123123",
      firstname: "John",
      lastname: "Doe",
      message: "Hi!, this is a reply message.",
      datetime: 1688974353,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
    },
    {
      _id: "314123123123",
      firstname: "John",
      lastname: "Doe",
      message: "Hi!, this is a reply message.",
      datetime: 1688974410,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
    },
    {
      _id: "314123123123",
      firstname: "John",
      lastname: "Doe",
      message: "Hi!, this is a reply message.",
      datetime: 1688974422,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
    },
    {
      _id,
      firstname: "Jane",
      lastname: "Roe",
      message: "Hi!, this is a test message.",
      datetime: 1688974427,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
    },
    {
      _id,
      firstname: "Jane",
      lastname: "Roe",
      message: "Hi!, this is a test message.",
      datetime: 1688974435,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
    },
    {
      _id,
      firstname: "Jane",
      lastname: "Roe",
      message: "Hi!, this is a test message.",
      datetime: 1688974441,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
    },
    {
      _id,
      firstname: "John",
      lastname: "Doe",
      message: "Hi!, this is a reply message.",
      datetime: 1688974448,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
    },
    {
      _id: "314123123123",
      firstname: "John",
      lastname: "Doe",
      message: "Hi!, this is a reply message.",
      datetime: 1688974454,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
    },
    {
      _id: "314123123123",
      firstname: "John",
      lastname: "Doe",
      message: "Hi!, this is a reply message.",
      datetime: 1688974461,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
    },
    {
      _id,
      firstname: "Jane",
      lastname: "Roe",
      message: "Hi!, this is a test message.",
      datetime: 1688974471,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
    },
    {
      _id,
      firstname: "Jane",
      lastname: "Roe",
      message: "Hi!, this is a test message.",
      datetime: 1688974477,
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
    },
  */
  ];

  const initialInbox = [
    /*
    {
      _id: "64a4a35805e03f298c2b31cf",
      firstname: "Practitioner",
      lastname: "Account 3",
      lastmessage: "Hi!, this is a test message.",
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
      unread: 1,
      datetime: 1689024477,
    },
    {
      _id: "644a0203eaef0203e0110c7b",
      firstname: "test",
      lastname: "account",
      lastmessage: "Hello!, this is a dummy reply.",
      photo:
        "https://www.befunky.com/images/prismic/5ddfea42-7377-4bef-9ac4-f3bd407d52ab_landing-photo-to-cartoon-img5.jpeg?auto=avif,webp&format=jpg&width=863",
      unread: 2,
      datetime: 1689014477,
    },
    {
      _id: "1231231244124",
      firstname: "Jane",
      lastname: "Roe",
      lastmessage: "Hi!, this is a test message.",
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
      unread: 0,
      datetime: 1689004477,
    },
    {
      _id: "314123123125",
      firstname: "John",
      lastname: "Doe",
      lastmessage: "Hello!, this is a dummy reply.",
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
      unread: 5,
      datetime: 1688994477,
    },
    {
      _id: "1231231244126",
      firstname: "Jane",
      lastname: "Roe",
      lastmessage: "Hi!, this is a test message.",
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1688637441/lumilife/64a4a35805e03f298c2b31cf.png",
      unread: 0,
      datetime: 1688984477,
    },
    {
      _id: "314123123127",
      firstname: "John",
      lastname: "Doe",
      lastmessage: "Hello!, this is a dummy reply.",
      photo:
        "https://res.cloudinary.com/optionxplicit/image/upload/v1687924755/lumilife/647ee423fdc88c08dc933505.png",
      unread: 0,
      datetime: 1688974477,
    },
  */
  ];
  const [newMessage, setNewMessage] = useState("");

  function auto_grow() {
    const textareas = document.getElementsByTagName("textarea");

    for (let t of textareas) {
      t.style.height = "5px";
      t.style.height = t.scrollHeight + "px";
    }
  }

  useEffect(() => {
    auto_grow();
  }, [newMessage]);

  useEffect(() => {
    getInboxList();
  }, []);

  const getInboxList = async () => {
    const result = await getInbox(null);
    if (result.error) {
      showError(result.message);
    } else {
      setInbox(result.result);
    }
  };

  const addNewChat = () => {
    if (!activeContact) {
      showError("No active contact. Please select a contact first.");
      return;
    }

    let newset = [...chats];
    newset.unshift({
      sender: _id,
      firstname: firstName,
      lastname: lastName,
      content: newMessage.replace(/\n/g, "<br />"),
      date: Date.now(),
      photo: photo,
      sending: true,
    });
    setChats(newset);
    sendNewMessage(activeContact.contact, newMessage.replace(/\n/g, "<br />"));
    setNewMessage("");
  };

  const sendNewMessage = async (recipient, message) => {
    let params = {};
    params.content = message;
    params.recipient = recipient;
    const result = await sendMessage(params);
    if (result.error) {
      showError(result.message);
    } else {
      getConversation(recipient);
    }
  };

  const getConversation = async (recipient) => {
    const result = await getConvo(recipient);
    if (result.error) {
      showError(result.message);
    } else {
      //getConvo();
      //setChats(result.result.reverse());
    }
  };

  return (
    <DashboardLayout>
      <div className="client-messages">
        <div className="title-bar">
          <h1>Messages</h1>
          <div>
            <DateTimePill></DateTimePill>
          </div>
        </div>
        <div className="messages-container">
          <div className="messages-inbox-list">
            {inbox && inbox.length >= 0 ? (
              inbox.length == 0 ? (
                <div className="text-muted text-center mt-4">
                  Your inbox is currently empty.
                </div>
              ) : (
                inbox.map((item) => {
                  let className = "messages-inbox-item";
                  if (item.hasOwnProperty("isactive") && item.isactive)
                    className += " messages-inbox-item-active";

                  const dateTimeData = getTimestampData(
                    new Date(item.date).getTime() / 1000
                  );
                  const nowTimeData = getTimestampData(
                    new Date().getTime() / 1000
                  );

                  return (
                    <div
                      key={item._id}
                      className={className}
                      onClick={() => {
                        let activeItem = null;
                        const newinbox = inbox.map((loopitem) => {
                          loopitem.isactive = false;
                          if (item._id == loopitem._id) {
                            loopitem.isactive = true;
                          }
                          activeItem = loopitem;
                          return loopitem;
                        });
                        setInbox(newinbox);
                        getConversation(item.contact);
                        setChats(null);
                        setActiveContact(item);
                      }}
                    >
                      <img src={item.photo ? item.photo : DefaultAvatar}></img>
                      {item.unread > 0 ? (
                        <div className="messages-inbox-item-badge">
                          <div>{item.unread}</div>
                        </div>
                      ) : (
                        <div className="messages-inbox-item-badge-hidden">
                          <div>{item.unread}</div>
                        </div>
                      )}
                      <div className="messages-inbox-item-name">{`${item.firstname} ${item.lastname}`}</div>
                      <div className="messages-inbox-item-date">
                        {`${dateTimeData.date} ${dateTimeData.month} ${dateTimeData.year} `.replace(
                          nowTimeData.year,
                          ""
                        )}
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              <div className="text-muted text-center mt-4">
                Inbox is loading..
              </div>
            )}
          </div>

          <div className="messages-convo-thread">
            <div className="messages-convo-thread-chatbar">
              <div className="message-input-container">
                <textarea
                  placeholder="Type message"
                  className="message-input"
                  onInput={auto_grow}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                  }}
                  value={newMessage}
                ></textarea>
              </div>
              <img
                className="message-send"
                src={SendIcon}
                onClick={addNewChat}
              ></img>
            </div>

            <div className="messages-chat-items">
              {chats && activeContact && chats.length >= 0 ? (
                chats.length == 0 ? (
                  <div className="text-muted text-center mb-4">No messages</div>
                ) : (
                  chats.map((item) => {
                    let className = "messages-chat-item";

                    if (_id === item.sender) {
                      className += "-self";
                      item.photo = photo;
                      item.firstname = firstName;
                      item.lastname = lastName;
                    } else {
                      item.photo = activeContact.photo;
                      item.firstname = activeContact.firstname;
                      item.lastname = activeContact.lastname;
                    }

                    return (
                      <div className={className} key={item._id}>
                        <img
                          className="messages-chat-avatar"
                          src={item.photo ? item.photo : DefaultAvatar}
                        ></img>
                        <div className="messages-chat-container">
                          <div className="messages-chat-name">{`${item.firstname} ${item.lastname}`}</div>
                          <div className="messages-chat-message">
                            {item.content ? parse(item.content) : item.content}
                          </div>
                          {item.sending && (
                            <div className="sending">Sending...</div>
                          )}
                        </div>
                      </div>
                    );
                  })
                )
              ) : activeContact ? (
                <div className="text-muted text-center mb-4">
                  Loading messages..
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ClientMessages;
