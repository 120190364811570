import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import SessionCard from "./components/SessionCard";
import useProfile from "../../hooks/useProfile";
import usePractitioner from "../../hooks/usePractitioner";
import PractitionerCard from "../AdminPractitionerList/components/PractitionerCard";
import { useNavigate } from "react-router-dom";

function ClientDashboardHome({ Component: component }) {
  const { role } = useProfile();
  const { nameOrder } = usePractitioner();
  const redirectToAdmin = (id) =>
    navigate(`/admin/practitioner/profilepage/${id}`);
  const redirectToCalendar = (id) => navigate(`/client/bookcalendar/${id}`);
  const navigate = useNavigate();
  console.log("role::", role);

  return (
    <>
      <DashboardLayout>
        <div className="client-dashboard-home">
          <div className="title-bar">
            <h1>Welcome to the LumiLife pre-launch</h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>
          <div className="yellow-box">
            {nameOrder && nameOrder.length > 0 ? (
              nameOrder.map((item) => {
                return (
                  <PractitionerCard
                    key={item._id}
                    firstname={item.firstname}
                    lastname={item.lastname}
                    role={item.role}
                    status={item.status}
                    message={item.message}
                    specialties={item.specialties}
                    id={item._id}
                    redirectToAdmin={redirectToAdmin}
                    redirectToCalendar={redirectToCalendar}
                  />
                );
              })
            ) : (
              <div align="center"> No matches.</div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default ClientDashboardHome;
