
function FrameACard({ 
    toggleFrameA,
    toggleFrameB,
    toggleFrameI,
    selectA1, 
    toggleSelectA1,
    selectA2, 
    toggleSelectA2,
    selectA3, 
    toggleSelectA3,
    toggleFrameD,
    updateProgressStatus,
    toggleAnswers,
    answers
}) 

{
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
      <div className="main-description">Help us match you with the perfect wellbeing practitioner for your needs</div>
      <h1>Who requires support</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          {/* <div className="question">
            Get Matched!
          </div>
          <div className="description">Do you work with youth under the age of 16?</div> */}
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li
              className={selectA1 ? "li-selected" : ""}
              onClick={toggleSelectA1}
              id="myself"
            >
              Myself
            </li>{" "}
            {/*no redirection yet for myself*/}
            <li
              className={selectA2 ? "li-selected" : ""}
              onClick={toggleSelectA2}
              id="its-for-us-myself-and-my partner"
            >
              It’s for us; myself & my partner
            </li>
            <li
              className={selectA3 ? "li-selected" : ""}
              onClick={toggleSelectA3}
              id="its-for-my-child"
            >
              It’s for my child
            </li>
          </ul>

          <div
            className="next"
            onClick={() => {
              console.log('answers::',answers);
              if (selectA2) {
                updateProgressStatus()
                toggleFrameB(); // Show
                toggleFrameA(); // Hide
              } else if (selectA1) {
                updateProgressStatus()
                toggleFrameI(); // Show
                toggleFrameA(); // Hide
              } else if (selectA3) {
                updateProgressStatus()
                toggleFrameD(); // Show
                toggleFrameA(); // Hide
              }
            }}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameACard;
