import DashboardHeader from "../../components/DashboardHeader";
import DashboarSidebar from "../../components/DashboardSidebar";
import useProfile from "../../hooks/useProfile";

function DashboardLayout({ Component: component, children }) {
  const { role } = useProfile();

  return (
    <div className="dashboard">
      <div className="top-bar"></div>

      <div className="layout">
        <div className="left">
          <DashboarSidebar role={role}></DashboarSidebar>
        </div>
        <div className="right">
          <DashboardHeader></DashboardHeader>
          <div className="dashboard-content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
