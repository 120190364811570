import { usePractitionerStore } from "../store/practitionerStore";
import { useEffect } from "react";

function useUser() {
  const nameOrder = usePractitionerStore((state) => state.nameOrder);
  const professions = usePractitionerStore((state) => state.professions);
  const users = usePractitionerStore((state) => state.users);
  const doFetch = usePractitionerStore((state) => state.doFetch);
  const doFetchProfessions =  usePractitionerStore((state) => state.doFetchProfessions);
  const doFetchUsers = usePractitionerStore((state) => state.doFetchUsers);

  useEffect(() => {
    doFetch();
  }, []);

  useEffect(() => {
    doFetchProfessions();
  }, []);

  useEffect(() => {
    doFetchUsers();
  }, []);

  return { nameOrder, doFetch, doFetchProfessions, professions, users };
}

export default useUser;
