function FrameVCard({
  toggleFrameV,
  frameV,
  toggleFrameW,
  frameW,
  selectV1, 
  toggleSelectV1,
  selectV2, 
  toggleSelectV2,
  selectV3, 
  toggleSelectV3,
  selectV4, 
  toggleSelectV4,
  selectV5, 
  toggleSelectV5,
  selectV6, 
  toggleSelectV6,
  selectV7, 
  toggleSelectV7,
  selectV8, 
  toggleSelectV8,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
     <h1>What personality traits most appaels to you in a practitioner?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Type of therapist would you like to engage with
          </div>
          <div className="description"></div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectV1 ? "li-selected" : ""} onClick={toggleSelectV1} id='light-hearted'>Light hearted</li>
            <li className={selectV2 ? "li-selected" : ""} onClick={toggleSelectV2} id='compassionate-and-warm'>Compassionate and warm</li>
            <li className={selectV3 ? "li-selected" : ""} onClick={toggleSelectV3} id='spiritual'>Spiritual</li>
            <li className={selectV4 ? "li-selected" : ""} onClick={toggleSelectV4} id='a-parent'>A Parent</li>
            <li className={selectV5 ? "li-selected" : ""} onClick={toggleSelectV5} id='mentor-and-motivator'>Mentor and motivator</li>
            <li className={selectV6 ? "li-selected" : ""} onClick={toggleSelectV6} id='existential'>Existential</li>
            <li className={selectV7 ? "li-selected" : ""} onClick={toggleSelectV7} id='direct-but-supportive'>Direct but supportive</li>
            <li className={selectV8 ? "li-selected" : ""} onClick={toggleSelectV8} id='not-really-sure'>Not really sure</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
            if (
              selectV1 ||
              selectV2 ||
              selectV3 ||
              selectV4 ||
              selectV5 ||
              selectV6 ||
              selectV7 ||
              selectV8
            ) {
              updateProgressStatus()
              toggleFrameV();
              toggleFrameW();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameVCard;
