import React from 'react';
import { Helmet } from 'react-helmet';

function Meta() {
    return (
      <>
        <Helmet
          titleTemplate="%s - Lumilife"
          defaultTitle="Lumilife Online Consultation"
        >
          <meta
            name="description"
            content="Online Consultation Application"
          />
        </Helmet>
      </>
    );
}

export default Meta;