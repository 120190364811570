function SpecialtyItem({ src, color, name }) {
  return (
    <li>
      <img src={src} className="image"></img>
      <div className={"name " + color}>
        <span>{name}</span>
      </div>
    </li>
  );
}

export default SpecialtyItem;
