function FrameGCard({
  toggleFrameG,
  frameG,
  selectG1, 
  toggleSelectG1,
  selectG2, 
  toggleSelectG2,
  selectG3, 
  toggleSelectG3,
  selectG4, 
  toggleSelectG4,
  selectG5, 
  toggleSelectG5,
  selectG6, 
  toggleSelectG6,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Get Matched!
          </div>
          <div className="description">Do you prefer working with clients that are:</div>
          <ul onClick={appendAnswers}>
            <li className={selectG1 ? "li-selected" : ""} onClick={toggleSelectG1} id='monday-preferably-to-be-scheduled'>Monday</li>
            <li className={selectG2 ? "li-selected" : ""} onClick={toggleSelectG2} id='tuesday-preferably-to-be-scheduled'>Tuesday</li>
            <li className={selectG3 ? "li-selected" : ""} onClick={toggleSelectG3} id='wednesday-preferably-to-be-scheduled'>Wednesday</li>
            <li className={selectG4 ? "li-selected" : ""} onClick={toggleSelectG4} id='thursday-preferably-to-be-scheduled'>Thursday</li>
            <li className={selectG5 ? "li-selected" : ""} onClick={toggleSelectG5} id='friday-preferably-to-be-scheduled'>Friday</li>
            <li className={selectG6 ? "li-selected" : ""} onClick={toggleSelectG6} id='saturday-preferably-to-be-scheduled'>Saturday</li>
          </ul>

          <div className="next" onClick={() => {
            console.log('answers::',answers);
            if (
              selectG1 ||
              selectG2 ||
              selectG3 ||
              selectG4 ||
              selectG5 ||
              selectG6
            ) {
              updateProgressStatus()
              toggleFrameG();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameGCard;
