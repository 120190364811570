import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

import Doctor1 from "../../assets/images/dr_1.jpg";
import Doctor2 from "../../assets/images/dr_2.jpg";
import PractitionerCard from "./components/PractitionerCard";
import QuoteBox from "../../components/QuoteBox";
import UploadIcon from "../../assets/images/icon_upload.png";

import FrameACard from "./frames/FrameACard";
import FrameBCard from "./frames/FrameBCard";
import FrameCCard from "./frames/FrameCCard";
import FrameDCard from "./frames/FrameDCard";
import FrameECard from "./frames/FrameECard";
import FrameFCard from "./frames/FrameFCard";
import FrameGCard from "./frames/FrameGCard";
import FrameICard from "./frames/FrameICard";
import FrameJCard from "./frames/FrameJCard";
import FrameKCard from "./frames/FrameKCard";
import FrameLCard from "./frames/FrameLCard";
import FrameMCard from "./frames/FrameMCard";
import FrameNCard from "./frames/FrameNCard";
import FrameOCard from "./frames/FrameOCard";
import FramePCard from "./frames/FramePCard";
import FrameQCard from "./frames/FrameQCard";
import FrameRCard from "./frames/FrameRCard";
import FrameSCard from "./frames/FrameSCard";
import FrameTCard from "./frames/FrameTCard";
import FrameUCard from "./frames/FrameUCard";
import FrameVCard from "./frames/FrameVCard";
import FrameWCard from "./frames/FrameWCard";
import FrameXCard from "./frames/FrameXCard";
import FrameYCard from "./frames/FrameYCard";
import FrameZCard from "./frames/FrameZCard";
import AnswersACard from "./frames/AnswersACard";
import ProgressCard from "./frames/ProgressCard";
import useMatchingFrameA from "../../hooks/useMatchingFrameA";
import useMatchingFrameB from "../../hooks/useMatchingFrameB";
import useMatchingFrameC from "../../hooks/useMatchingFrameC";
import useMatchingFrameD from "../../hooks/useMatchingFrameD";
import useMatchingFrameE from "../../hooks/useMatchingFrameE";
import useMatchingFrameF from "../../hooks/useMatchingFrameF";
import useMatchingFrameG from "../../hooks/useMatchingFrameG";
import useMatchingFrameI from "../../hooks/useMatchingFrameI";
import useMatchingFrameJ from "../../hooks/useMatchingFrameJ";
import useMatchingFrameK from "../../hooks/useMatchingFrameK";
import useMatchingFrameL from "../../hooks/useMatchingFrameL";
import useMatchingFrameM from "../../hooks/useMatchingFrameM";
import useMatchingFrameN from "../../hooks/useMatchingFrameN";
import useMatchingFrameO from "../../hooks/useMatchingFrameO";
import useMatchingFrameP from "../../hooks/useMatchingFrameP";
import useMatchingFrameQ from "../../hooks/useMatchingFrameQ";
import useMatchingFrameR from "../../hooks/useMatchingFrameR";
import useMatchingFrameS from "../../hooks/useMatchingFrameS";
import useMatchingFrameT from "../../hooks/useMatchingFrameT";
import useMatchingFrameU from "../../hooks/useMatchingFrameU";
import useMatchingFrameV from "../../hooks/useMatchingFrameV";
import useMatchingFrameW from "../../hooks/useMatchingFrameW";
import useMatchingFrameX from "../../hooks/useMatchingFrameX";
import useMatchingFrameY from "../../hooks/useMatchingFrameY";
import useMatchingFrameZ from "../../hooks/useMatchingFrameZ";
import useMatchingFrameMultiple from "../../hooks/useMatchingFrameMultiple";
import useCollectAnswers from "../../hooks/useCollectAnswers";
import useProgressStatus from "../../hooks/useProgressStatus";
import useAnswersFrameA from "../../hooks/useAnswersFrameA";


function GetStarted() {
  const { 
    toggleFrameA,
    selectA1, 
    frameA,
    toggleSelectA1,
    selectA2, 
    toggleSelectA2,  
    selectA3, 
    toggleSelectA3,  
  } = useMatchingFrameA();
  const { 
    toggleFrameB,
    frameB,
    toggleFrameE,
    frameE,
    selectB1, 
    toggleSelectB1,
    selectB2, 
    toggleSelectB2,  
    selectB3, 
    toggleSelectB3,  
    selectB4, 
    toggleSelectB4,  
    selectB5, 
    toggleSelectB5,  
    selectB6, 
    toggleSelectB6,  
    selectB7, 
    toggleSelectB7,  
    selectB8, 
    toggleSelectB8,  
    selectB9, 
    toggleSelectB9,  
    selectB10, 
    toggleSelectB10,  
    selectB11, 
    toggleSelectB11,  
    selectB12, 
    toggleSelectB12,  
    selectB13, 
    toggleSelectB13,  
    selectB14, 
    toggleSelectB14,  
    selectB15, 
    toggleSelectB15,  
    selectB16, 
    toggleSelectB16,  
    selectB17, 
    toggleSelectB17,  
  } = useMatchingFrameB();

  const {
    toggleFrameD,
    frameD,
}
  = useMatchingFrameC();

  const {
    selectD1, 
    toggleSelectD1,  
    selectD2, 
    toggleSelectD2,  
    selectD3, 
    toggleSelectD3,  
    selectD4, 
    toggleSelectD4,  
    selectD5, 
    toggleSelectD5,  
    selectD6, 
    toggleSelectD6,  
    selectD7, 
    toggleSelectD7,  
    selectD8, 
    toggleSelectD8,  
    selectD9, 
    toggleSelectD9,  
    selectD10, 
    toggleSelectD10,  
    selectD11, 
    toggleSelectD11,  
    selectD12, 
    toggleSelectD12,  
    selectD13, 
    toggleSelectD13,  
    selectD14, 
    toggleSelectD14,  
    selectD15, 
    toggleSelectD15,  
    selectD16, 
    toggleSelectD16,  
    selectD17, 
    toggleSelectD17,  
  } = useMatchingFrameD();

  const {
    toggleFrameF,
    frameF,
    toggleSelectE1,
    selectE1,
    toggleSelectE2,
    selectE2,
    toggleSelectE3,
    selectE3,
    toggleSelectE4,
    selectE4,
    toggleSelectE5,
    selectE5,
    toggleSelectE6,
    selectE6,
    toggleSelectE7,
    selectE7,
    toggleSelectE8,
    selectE8,
    toggleSelectE9,
    selectE9,
    toggleSelectE10,
    selectE10,
    toggleSelectE11,
    selectE11,
}
  = useMatchingFrameE();

  const {
    toggleFrameG,
    frameG,
    toggleSelectF1,
    selectF1,
    toggleSelectF2,
    selectF2,
    toggleSelectF3,
    selectF3,
    toggleSelectF4,
    selectF4,
    toggleSelectF5,
    selectF5,
}
  = useMatchingFrameF();

  const {
    toggleSelectG1,
    selectG1,
    toggleSelectG2,
    selectG2,
    toggleSelectG3,
    selectG3,
    toggleSelectG4,
    selectG4,
    toggleSelectG5,
    selectG5,
    toggleSelectG6,
    selectG6,
}
  = useMatchingFrameG();

  const {
    toggleFrameI,
    frameI,
    toggleSelectI1,
    selectI1,
    toggleSelectI2,
    selectI2,
}
  = useMatchingFrameI();

  const {
    toggleFrameJ,
    frameJ,
    toggleSelectJ1,
    selectJ1,
    toggleSelectJ2,
    selectJ2,
    toggleSelectJ3,
    selectJ3,
    toggleSelectJ4,
    selectJ4,
    toggleSelectJ5,
    selectJ5,
    toggleSelectJ6,
    selectJ6,
    toggleSelectJ7,
    selectJ7,
    toggleSelectJ8,
    selectJ8,
    toggleSelectJ9,
    selectJ9,
    toggleSelectJ10,
    selectJ10,
    toggleSelectJ11,
    selectJ11,
    toggleSelectJ12,
    selectJ12,
    toggleSelectJ13,
    selectJ13,
    toggleSelectJ14,
    selectJ14,
    toggleSelectJ15,
    selectJ15,
    toggleSelectJ16,
    selectJ16,
    toggleSelectJ17,
    selectJ17,
    toggleSelectJ18,
    selectJ18,
    toggleSelectJ19,
    selectJ19,
    toggleSelectJ20,
    selectJ20,
    
  }
  = useMatchingFrameJ();

  const {
    toggleFrameK,
    frameK,
    toggleFrameL,
    frameL,
    toggleSelectK1,
    selectK1,
    toggleSelectK2,
    selectK2,
  } 
  = useMatchingFrameK();

  const {
    toggleFrameM,
    frameM,
    toggleSelectL1,
    selectL1,
    toggleSelectL2,
    selectL2,
    toggleSelectL3,
    selectL3,
    toggleSelectL4,
    selectL4,
    toggleSelectL5,
    selectL5,
  } 
  = useMatchingFrameL();

  const {
    toggleSelectM1,
    selectM1,
    toggleSelectM2,
    selectM2,
    toggleSelectM3,
    selectM3,
    toggleSelectM4,
    selectM4,
    uploadInput,
  } 
  = useMatchingFrameM();

  const {
    toggleFrameN,
    frameN,
    toggleSelectN1,
    selectN1,
    toggleSelectN2,
    selectN2,
    toggleSelectN3,
    selectN3,
    toggleSelectN4,
    selectN4,
    toggleSelectN5,
    selectN5,
    toggleSelectN6,
    selectN6,
    toggleSelectN7,
    selectN7,
    toggleSelectN8,
    selectN8,
    toggleSelectN9,
    selectN9,
}
  = useMatchingFrameN();

  const {
    toggleFrameO,
    frameO,
    toggleSelectO1,
    selectO1,
    toggleSelectO2,
    selectO2,
    toggleSelectO3,
    selectO3,
    toggleSelectO4,
    selectO4,
    toggleSelectO5,
    selectO5,
    toggleSelectO6,
    selectO6,
    toggleSelectO7,
    selectO7,
    toggleSelectO8,
    selectO8,
    toggleSelectO9,
    selectO9,
    toggleSelectO10,
    selectO10,
    toggleSelectO11,
    selectO11,
}
  = useMatchingFrameO();

  const {
    toggleFrameP,
    frameP,
    toggleSelectP1,
    selectP1,
    toggleSelectP2,
    selectP2,
    toggleSelectP3,
    selectP3,
}
  = useMatchingFrameP();

  const {
    toggleFrameQ,
    frameQ,
    toggleSelectQ1,
    selectQ1,
    toggleSelectQ2,
    selectQ2,
    toggleSelectQ3,
    selectQ3,
    toggleSelectQ4,
    selectQ4,
    toggleSelectQ5,
    selectQ5,
}
  = useMatchingFrameQ();

  const {
    toggleFrameR,
    frameR,
    toggleSelectR1,
    selectR1,
    toggleSelectR2,
    selectR2,
    toggleSelectR3,
    selectR3,
    toggleSelectR4,
    selectR4,
}
  = useMatchingFrameR();

  const {
    toggleFrameS,
    frameS,
    toggleSelectS1,
    selectS1,
    toggleSelectS2,
    selectS2,
}
  = useMatchingFrameS();

const {
    toggleFrameT,
    frameT,
    toggleSelectT1,
    selectT1,
    toggleSelectT2,
    selectT2,
    toggleSelectT3,
    selectT3,
    toggleSelectT4,
    selectT4,
}
  = useMatchingFrameT();

  const {
    toggleFrameU,
    frameU,
    toggleSelectU1,
    selectU1,
    toggleSelectU2,
    selectU2,
    toggleSelectU3,
    selectU3,
    toggleSelectU4,
    selectU4,
    toggleSelectU5,
    selectU5,
    toggleSelectU6,
    selectU6,
    toggleSelectU7,
    selectU7,
    toggleSelectU8,
    selectU8,
    toggleSelectU9,
    selectU9,
}
  = useMatchingFrameU();

  const {
    toggleFrameV,
    frameV,
    toggleSelectV1,
    selectV1,
    toggleSelectV2,
    selectV2,
    toggleSelectV3,
    selectV3,
    toggleSelectV4,
    selectV4,
    toggleSelectV5,
    selectV5,
    toggleSelectV6,
    selectV6,
    toggleSelectV7,
    selectV7,
    toggleSelectV8,
    selectV8,
    toggleSelectV9,
    selectV9,
}
  = useMatchingFrameV();

  const {
    toggleFrameW,
    frameW,
    toggleSelectW1,
    selectW1,
    toggleSelectW2,
    selectW2,
    toggleSelectW3,
    selectW3,
    toggleSelectW4,
    selectW4,
    toggleSelectW5,
    selectW5,
    toggleSelectW6,
    selectW6,
    toggleSelectW7,
    selectW7,
    toggleSelectW8,
    selectW8,
    toggleSelectW9,
    selectW9,
}
  = useMatchingFrameW();

  const {
    toggleFrameX,
    frameX,
    toggleSelectX1,
    selectX1,
    toggleSelectX2,
    selectX2,
    toggleSelectX3,
    selectX3,
    toggleSelectX4,
    selectX4,
    toggleSelectX5,
    selectX5,
    toggleSelectX6,
    selectX6,
    toggleSelectX7,
    selectX7,
}
  = useMatchingFrameX();

  const {
    toggleFrameY,
    frameY,
    toggleSelectY1,
    selectY1,
    toggleSelectY2,
    selectY2,
    toggleSelectY3,
    selectY3,
    toggleSelectY4,
    selectY4,
    toggleSelectY5,
    selectY5,
    toggleSelectY6,
    selectY6,
    toggleSelectY7,
    selectY7,
}
  = useMatchingFrameY();

  const {
    toggleFrameZ,
    frameZ,
    toggleSelectZ1,
    selectZ1,
    toggleSelectZ2,
    selectZ2,
    toggleSelectZ3,
    selectZ3,
    toggleAnswersFrameA,
    answersFrameA,
    toggleShowSaveResponse,
    plainResponse,
    style,
    responseCheck,
    setEmail,
    email,
    toggleShareEmail,
    shareEmail
    }
  = useMatchingFrameZ();

  const {
    toggleFrameMultiple,
    frameMultiple,
    frameFollowupQuestion
  }
   = useMatchingFrameMultiple();

   const {
    toggleAnswers,
    answers,
  }
   = useCollectAnswers();

   const {
    updateProgressStatus,
    percentage,
   } = useProgressStatus();

  return (
    <>
      <Header></Header>
      <div className="get-started">

      <ProgressCard
      percentage={percentage}
      />

        {frameA === true ? (
          <FrameACard
          toggleFrameA={toggleFrameA}
          toggleFrameB={toggleFrameB}
          toggleFrameI={toggleFrameI}
          selectA1={selectA1}
          toggleSelectA1={toggleSelectA1}
          selectA2={selectA2}
          toggleSelectA2={toggleSelectA2}
          selectA3={selectA3}
          toggleSelectA3={toggleSelectA3}
          toggleFrameD={toggleFrameD}
          updateProgressStatus={updateProgressStatus}
          percentage={percentage}
          toggleAnswers={toggleAnswers}
          answers={answers}
        ></FrameACard>
    ) : null}

        {frameB === true ? (
          <FrameBCard
            toggleFrameK={toggleFrameK}
            frameK={frameK}
            toggleFrameB={toggleFrameB}
            frameB={frameB}
            selectB1={selectB1}
            toggleSelectB1={toggleSelectB1}
            selectB2={selectB2}
            toggleSelectB2={toggleSelectB2}
            selectB3={selectB3}
            toggleSelectB3={toggleSelectB3}
            selectB4={selectB4}
            toggleSelectB4={toggleSelectB4}
            selectB5={selectB5}
            toggleSelectB5={toggleSelectB5}
            toggleSelectB6={toggleSelectB6}
            selectB6={selectB6}
            toggleSelectB7={toggleSelectB7}
            selectB7={selectB7}
            toggleSelectB8={toggleSelectB8}
            selectB8={selectB8}
            toggleSelectB9={toggleSelectB9}
            selectB9={selectB9}
            toggleSelectB10={toggleSelectB10}
            selectB10={selectB10}
            toggleSelectB11={toggleSelectB11}
            selectB11={selectB11}
            toggleSelectB12={toggleSelectB12}
            selectB12={selectB12}
            toggleSelectB13={toggleSelectB13}
            selectB13={selectB13}
            toggleSelectB14={toggleSelectB14}
            selectB14={selectB14}
            toggleSelectB15={toggleSelectB15}
            selectB15={selectB15}
            toggleSelectB16={toggleSelectB16}
            selectB16={selectB16}
            toggleSelectB17={toggleSelectB17}
            selectB17={selectB17}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameBCard>
        ) : null}

        {/* {frameC === true ? (
          <FrameCCard
            toggleFrameC={toggleFrameC}
            frameC={frameC}
            toggleFrameD={toggleFrameD}
            frameD={frameD}
            selectC1={selectC1}
            toggleSelectC1={toggleSelectC1}
            selectC2={selectC2}
            toggleSelectC2={toggleSelectC2}
        ></FrameCCard>
        ) : null} */}

        {frameD === true ? (
          <FrameDCard
            toggleFrameD={toggleFrameD}
            frameD={frameD}
            toggleFrameL={toggleFrameL}
            frameL={frameL}
            selectD1={selectD1}
            toggleSelectD1={toggleSelectD1}
            selectD2={selectD2}
            toggleSelectD2={toggleSelectD2}
            selectD3={selectD3}
            toggleSelectD3={toggleSelectD3}
            selectD4={selectD4}
            toggleSelectD4={toggleSelectD4}
            selectD5={selectD5}
            toggleSelectD5={toggleSelectD5}
            selectD6={selectD6}
            toggleSelectD6={toggleSelectD6}
            selectD7={selectD7}
            toggleSelectD7={toggleSelectD7}
            selectD8={selectD8}
            toggleSelectD8={toggleSelectD8}
            selectD9={selectD9}
            toggleSelectD9={toggleSelectD9}
            selectD10={selectD10}
            toggleSelectD10={toggleSelectD10}
            selectD11={selectD11}
            toggleSelectD11={toggleSelectD11}
            selectD12={selectD12}
            toggleSelectD12={toggleSelectD12}
            selectD13={selectD13}
            toggleSelectD13={toggleSelectD13}
            selectD14={selectD14}
            toggleSelectD14={toggleSelectD14}
            selectD15={selectD15}
            toggleSelectD15={toggleSelectD15}
            selectD16={selectD16}
            toggleSelectD16={toggleSelectD16}
            selectD17={selectD17}
            toggleSelectD17={toggleSelectD17}
            toggleAnswersFrameA={toggleAnswersFrameA}
            answersFrameA={answersFrameA}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameDCard>
        ) : null}

        {frameE === true ? (
          <FrameECard
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameK={toggleFrameK}
            frameK={frameK}
            selectE1={selectE1}
            toggleSelectE1={toggleSelectE1}
            selectE2={selectE2}
            toggleSelectE2={toggleSelectE2}
            selectE3={selectE3}
            toggleSelectE3={toggleSelectE3}
            selectE4={selectE4}
            toggleSelectE4={toggleSelectE4}
            selectE5={selectE5}
            toggleSelectE5={toggleSelectE5}
            selectE6={selectE6}
            toggleSelectE6={toggleSelectE6}
            selectE7={selectE7}
            toggleSelectE7={toggleSelectE7}
            selectE8={selectE8}
            toggleSelectE8={toggleSelectE8}
            selectE9={selectE9}
            toggleSelectE9={toggleSelectE9}
            selectE10={selectE10}
            toggleSelectE10={toggleSelectE10}
            selectE11={selectE11}
            toggleSelectE11={toggleSelectE11}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameECard>
        ) : null}

        {frameF === true ? (
          <FrameFCard
            toggleFrameF={toggleFrameF}
            frameF={frameF}
            toggleFrameG={toggleFrameG}
            frameG={frameG}
            selectF1={selectF1}
            toggleSelectF1={toggleSelectF1}
            selectF2={selectF2}
            toggleSelectF2={toggleSelectF2}
            selectF3={selectF3}
            toggleSelectF3={toggleSelectF3}
            selectF4={selectF4}
            toggleSelectF4={toggleSelectF4}
            selectF5={selectF5}
            toggleSelectF5={toggleSelectF5}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
        ></FrameFCard>
        ) : null}

        {frameG === true ? (
          <FrameGCard
            toggleFrameG={toggleFrameG}
            frameG={frameG}
            selectG1={selectG1}
            toggleSelectG1={toggleSelectG1}
            selectG2={selectG2}
            toggleSelectG2={toggleSelectG2}
            selectG3={selectG3}
            toggleSelectG3={toggleSelectG3}
            selectG4={selectG4}
            toggleSelectG4={toggleSelectG4}
            selectG5={selectG5}
            toggleSelectG5={toggleSelectG5}
            selectG6={selectG6}
            toggleSelectG6={toggleSelectG6}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
        ></FrameGCard>
        ) : null}

        {frameI === true ? (
          <FrameICard
            toggleFrameI={toggleFrameI}
            frameI={frameI}
            toggleFrameJ={toggleFrameJ}
            frameJ={frameJ}
            selectI1={selectI1}
            toggleSelectI1={toggleSelectI1}
            selectI2={selectI2}
            toggleSelectI2={toggleSelectI2}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
        ></FrameICard>
        ) : null}

        {frameJ === true ? (
          <FrameJCard
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameJ={toggleFrameJ}
            frameJ={frameJ}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectN1={selectN1}
            toggleSelectN1={toggleSelectN1}
            selectJ1={selectJ1}
            toggleSelectJ1={toggleSelectJ1}
            selectJ2={selectJ2}
            toggleSelectJ2={toggleSelectJ2}
            selectJ3={selectJ3}
            toggleSelectJ3={toggleSelectJ3}
            selectJ4={selectJ4}
            toggleSelectJ4={toggleSelectJ4}
            selectJ5={selectJ5}
            toggleSelectJ5={toggleSelectJ5}
            selectJ6={selectJ6}
            toggleSelectJ6={toggleSelectJ6}
            selectJ7={selectJ7}
            toggleSelectJ7={toggleSelectJ7}
            selectJ8={selectJ8}
            toggleSelectJ8={toggleSelectJ8}
            selectJ9={selectJ9}
            toggleSelectJ9={toggleSelectJ9}
            selectJ10={selectJ10}
            toggleSelectJ10={toggleSelectJ10}
            selectJ11={selectJ11}
            toggleSelectJ11={toggleSelectJ11}
            selectJ12={selectJ12}
            toggleSelectJ12={toggleSelectJ12}
            selectJ13={selectJ13}
            toggleSelectJ13={toggleSelectJ13}
            selectJ14={selectJ14}
            toggleSelectJ14={toggleSelectJ14}
            selectJ15={selectJ15}
            toggleSelectJ15={toggleSelectJ15}
            selectJ16={selectJ16}
            toggleSelectJ16={toggleSelectJ16}
            selectJ17={selectJ17}
            toggleSelectJ17={toggleSelectJ17}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            frameFollowupQuestion={frameFollowupQuestion}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameJCard>
        ) : null}

        {frameK === true ? (
            <FrameKCard
              toggleFrameK={toggleFrameK}
              frameK={frameK}
              toggleFrameL={toggleFrameL}
              frameL={frameL}
              selectK1={selectK1}
              toggleSelectK1={toggleSelectK1}
              selectK2={selectK2}
              toggleSelectK2={toggleSelectK2}
              updateProgressStatus={updateProgressStatus}
              percentage={percentage}  
              toggleAnswers={toggleAnswers}
              answers={answers}
                ></FrameKCard>
        ) : null}

        {frameL === true ? (
            <FrameLCard
            toggleFrameL={toggleFrameL}
            frameL={frameL}
            toggleFrameM={toggleFrameM}
            frameM={frameM}
            selectL1={selectL1}
            toggleSelectL1={toggleSelectL1}
            selectL2={selectL2}
            toggleSelectL2={toggleSelectL2}
            selectL3={selectL3}
            toggleSelectL3={toggleSelectL3}
            selectL4={selectL4}
            toggleSelectL4={toggleSelectL4}
            selectL5={selectL5}
            toggleSelectL5={toggleSelectL5}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameLCard>
        ) : null}

       {frameM === true ? (
            <FrameMCard
            toggleFrameM={toggleFrameM}
            frameM={frameM}
            toggleFrameU={toggleFrameU}
            frameU={frameU}
            UploadIcon={UploadIcon}
            selectM1={selectM1}
            toggleSelectM1={toggleSelectM1}
            selectM2={selectM2}
            toggleSelectM2={toggleSelectM2}
            selectM3={selectM3}
            toggleSelectM3={toggleSelectM3}
            selectM4={selectM4}
            toggleSelectM4={toggleSelectM4}
            uploadInput={uploadInput}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameMCard>
        ) : null}

        {frameN === true ? (
          <FrameNCard
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectN1={selectN1}
            toggleSelectN1={toggleSelectN1}
            selectN2={selectN2}
            toggleSelectN2={toggleSelectN2}
            selectN3={selectN3}
            toggleSelectN3={toggleSelectN3}
            selectN4={selectN4}
            toggleSelectN4={toggleSelectN4}
            selectN5={selectN5}
            toggleSelectN5={toggleSelectN5}
            selectN6={selectN6}
            toggleSelectN6={toggleSelectN6}
            selectN7={selectN7}
            toggleSelectN7={toggleSelectN7}
            selectN8={selectN8}
            toggleSelectN8={toggleSelectN8}
            selectN9={selectN9}
            toggleSelectN9={toggleSelectN9}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameNCard>
        ) : null}

        {frameO === true ? (
          <FrameOCard
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectO1={selectO1}
            toggleSelectO1={toggleSelectO1}
            selectO2={selectO2}
            toggleSelectO2={toggleSelectO2}
            selectO3={selectO3}
            toggleSelectO3={toggleSelectO3}
            selectO4={selectO4}
            toggleSelectO4={toggleSelectO4}
            selectO5={selectO5}
            toggleSelectO5={toggleSelectO5}
            selectO6={selectO6}
            toggleSelectO6={toggleSelectO6}
            selectO7={selectO7}
            toggleSelectO7={toggleSelectO7}
            selectO8={selectO8}
            toggleSelectO8={toggleSelectO8}
            selectO9={selectO9}
            toggleSelectO9={toggleSelectO9}
            selectO10={selectO10}
            toggleSelectO10={toggleSelectO10}
            selectO11={selectO11}
            toggleSelectO11={toggleSelectO11}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameOCard>
        ) : null}

      {frameP === true ? (
          <FramePCard
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectP1={selectP1}
            toggleSelectP1={toggleSelectP1}
            selectP2={selectP2}
            toggleSelectP2={toggleSelectP2}
            selectP3={selectP3}
            toggleSelectP3={toggleSelectP3}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FramePCard>
        ) : null}

      {frameQ === true ? (
          <FrameQCard
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectQ1={selectQ1}
            toggleSelectQ1={toggleSelectQ1}
            selectQ2={selectQ2}
            toggleSelectQ2={toggleSelectQ2}
            selectQ3={selectQ3}
            toggleSelectQ3={toggleSelectQ3}
            selectQ4={selectQ4}
            toggleSelectQ4={toggleSelectQ4}
            selectQ5={selectQ5}
            toggleSelectQ5={toggleSelectQ5}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameQCard>
      ) : null}

      {frameR === true ? (
          <FrameRCard
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectR1={selectR1}
            toggleSelectR1={toggleSelectR1}
            selectR2={selectR2}
            toggleSelectR2={toggleSelectR2}
            selectR3={selectR3}
            toggleSelectR3={toggleSelectR3}
            selectR4={selectR4}
            toggleSelectR4={toggleSelectR4}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameRCard>
      ) : null}

      {frameS === true ? (
          <FrameSCard
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            selectS1={selectS1}
            toggleSelectS1={toggleSelectS1}
            selectS2={selectS2}
            toggleSelectS2={toggleSelectS2}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameSCard>
      ) : null}

      {frameT === true ? (
          <FrameTCard
            toggleFrameT={toggleFrameT}
            frameT={frameT}
            toggleFrameE={toggleFrameE}
            frameE={frameE}
            toggleFrameN={toggleFrameN}
            frameN={frameN}
            toggleFrameO={toggleFrameO}
            frameO={frameO}
            toggleFrameP={toggleFrameP}
            frameP={frameP}
            toggleFrameQ={toggleFrameQ}
            frameQ={frameQ}
            toggleFrameR={toggleFrameR}
            frameR={frameR}
            toggleFrameS={toggleFrameS}
            frameS={frameS}
            selectT1={selectT1}
            toggleSelectT1={toggleSelectT1}
            selectT2={selectT2}
            toggleSelectT2={toggleSelectT2}
            selectT3={selectT3}
            toggleSelectT3={toggleSelectT3}
            selectT4={selectT4}
            toggleSelectT4={toggleSelectT4}
            toggleFrameMultiple={toggleFrameMultiple}
            frameMultiple={frameMultiple}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameTCard>
      ) : null}

      {frameU === true ? (
          <FrameUCard
            toggleFrameU={toggleFrameU}
            frameU={frameU}
            toggleFrameV={toggleFrameV}
            frameV={frameV}
            selectU1={selectU1}
            toggleSelectU1={toggleSelectU1}
            selectU2={selectU2}
            toggleSelectU2={toggleSelectU2}
            selectU3={selectU3}
            toggleSelectU3={toggleSelectU3}
            selectU4={selectU4}
            toggleSelectU4={toggleSelectU4}
            selectU5={selectU5}
            toggleSelectU5={toggleSelectU5}
            selectU6={selectU6}
            toggleSelectU6={toggleSelectU6}
            selectU7={selectU7}
            toggleSelectU7={toggleSelectU7}
            selectU8={selectU8}
            toggleSelectU8={toggleSelectU8}
            selectU9={selectU9}
            toggleSelectU9={toggleSelectU9}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameUCard>
      ) : null}

      {frameV === true ? (
          <FrameVCard
            toggleFrameV={toggleFrameV}
            frameV={frameV}
            toggleFrameW={toggleFrameW}
            frameW={frameW}
            selectV1={selectV1}
            toggleSelectV1={toggleSelectV1}
            selectV2={selectV2}
            toggleSelectV2={toggleSelectV2}
            selectV3={selectV3}
            toggleSelectV3={toggleSelectV3}
            selectV4={selectV4}
            toggleSelectV4={toggleSelectV4}
            selectV5={selectV5}
            toggleSelectV5={toggleSelectV5}
            selectV6={selectV6}
            toggleSelectV6={toggleSelectV6}
            selectV7={selectV7}
            toggleSelectV7={toggleSelectV7}
            selectV8={selectV8}
            toggleSelectV8={toggleSelectV8}
            selectV9={selectV9}
            toggleSelectV9={toggleSelectV9}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameVCard>
      ) : null}

      {frameW === true ? (
          <FrameWCard
            toggleFrameW={toggleFrameW}
            frameW={frameW}
            toggleFrameX={toggleFrameX}
            frameX={frameX}
            selectW1={selectW1}
            toggleSelectW1={toggleSelectW1}
            selectW2={selectW2}
            toggleSelectW2={toggleSelectW2}
            selectW3={selectW3}
            toggleSelectW3={toggleSelectW3}
            selectW4={selectW4}
            toggleSelectW4={toggleSelectW4}
            selectW5={selectW5}
            toggleSelectW5={toggleSelectW5}
            selectW6={selectW6}
            toggleSelectW6={toggleSelectW6}
            selectW7={selectW7}
            toggleSelectW7={toggleSelectW7}
            selectW8={selectW8}
            toggleSelectW8={toggleSelectW8}
            selectW9={selectW9}
            toggleSelectW9={toggleSelectW9}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameWCard>
      ) : null}

      {frameX === true ? (
          <FrameXCard
            toggleFrameX={toggleFrameX}
            frameX={frameX}
            toggleFrameY={toggleFrameY}
            frameY={frameY}
            selectX1={selectX1}
            toggleSelectX1={toggleSelectX1}
            selectX2={selectX2}
            toggleSelectX2={toggleSelectX2}
            selectX3={selectX3}
            toggleSelectX3={toggleSelectX3}
            selectX4={selectX4}
            toggleSelectX4={toggleSelectX4}
            selectX5={selectX5}
            toggleSelectX5={toggleSelectX5}
            selectX6={selectX6}
            toggleSelectX6={toggleSelectX6}
            selectX7={selectX7}
            toggleSelectX7={toggleSelectX7}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameXCard>
      ) : null}

    {frameY === true ? (
          <FrameYCard
            toggleFrameY={toggleFrameY}
            frameY={frameY}
            toggleFrameZ={toggleFrameZ}
            frameZ={frameZ}
            selectY1={selectY1}
            toggleSelectY1={toggleSelectY1}
            selectY2={selectY2}
            toggleSelectY2={toggleSelectY2}
            selectY3={selectY3}
            toggleSelectY3={toggleSelectY3}
            selectY4={selectY4}
            toggleSelectY4={toggleSelectY4}
            selectY5={selectY5}
            toggleSelectY5={toggleSelectY5}
            selectY6={selectY6}
            toggleSelectY6={toggleSelectY6}
            selectY7={selectY7}
            toggleSelectY7={toggleSelectY7}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
          ></FrameYCard>
      ) : null}

      {frameZ === true ? (
          <FrameZCard
            toggleFrameZ={toggleFrameZ}
            frameZ={frameZ}
            selectZ1={selectZ1}
            toggleSelectZ1={toggleSelectZ1}
            selectZ2={selectZ2}
            toggleSelectZ2={toggleSelectZ2}
            selectZ3={selectZ3}
            toggleSelectZ3={toggleSelectZ3}
            toggleAnswersFrameA={toggleAnswersFrameA}
            answersFrameA={answersFrameA}
            updateProgressStatus={updateProgressStatus}
            percentage={percentage}
            toggleAnswers={toggleAnswers}
            answers={answers}
            toggleShowSaveResponse={toggleShowSaveResponse}
            plainResponse={plainResponse}
            style={style}
            responseCheck={responseCheck}
            setEmail={setEmail}
            email={email}
            toggleShareEmail={toggleShareEmail}
            shareEmail={shareEmail}
            ></FrameZCard>
      ) : null}

       {answersFrameA.length !== 0 ? (
          <AnswersACard
          pracLists={answersFrameA}
          updateProgressStatus={updateProgressStatus}
          percentage={percentage}
          answers={answers}
        ></AnswersACard>
        ) : null}



        {/* <h2>We're here for you</h2>

        <div className="row m-0 p-5 practitioners">
          <div className="col-4 p-3">
            <PractitionerCard
              toprated={true}
              photo={Doctor1}
              name="Dr. Lara Smith"
              position="Counsellor"
              rate="100"
              message="Hi! I'm Kath, a qualified and experienced Psychologist. I
                      have over ten years experience working with adults and
                      adolesc"
            ></PractitionerCard>
          </div>

          <div className="col-4 p-3">
            <PractitionerCard
              toprated={true}
              photo={Doctor2}
              name="Dr. Lara Smith"
              position="Counsellor"
              rate="100"
              message="Hi! I'm Kath, a qualified and experienced Psychologist. I
                      have over ten years experience working with adults and
                      adolesc"
            ></PractitionerCard>
          </div>

          <div className="col-4 p-3">
            <PractitionerCard
              toprated={true}
              photo={Doctor2}
              name="Dr. Lara Smith"
              position="Counsellor"
              rate="100"
              message="Hi! I'm Kath, a qualified and experienced Psychologist. I
                      have over ten years experience working with adults and
                      adolesc"
            ></PractitionerCard>
          </div>
        </div>

        <Link className="find" to={"/browse-practitioners"}>
          Find your practitioner
        </Link> */}

        <QuoteBox></QuoteBox>
      </div>

      <Footer></Footer>
    </>
  );
}

export default GetStarted;
