import { useState } from "react";

function useCollectAnswers() {
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);

  const toggleAnswers = (arr) => {
    if (arr.target.id) {
      setAnswers(updateQuestion(answers, arr.target.id));
    } else {
      updateQuestions(updateQuestion(questions, arr.target.textContent));
    }
  };

  const updateQuestion = (arr, val) => {
    var i = arr.indexOf(val);
    if (i === -1)
        arr.push(val);
    else
        arr.splice(i,1);
        return arr;
}

const updateQuestions = (arr, val) => {
    var i = arr.indexOf(val);
    if (i === -1)
        arr.push(val);
    else
        arr.splice(i,1);
        return arr;
}

  return {
    toggleAnswers,
    answers,
  };
}

export default useCollectAnswers;
