import { useState } from "react";

function useMatchingFrameS() {

    const [frameS, setframeS] = useState(false);
    const toggleFrameS = () => {
        setframeS(prevCheck => !prevCheck);
    }
    const [selectS1, setSelectS1] = useState(false);
    const toggleSelectS1 = () => {
        setSelectS1(prevCheck => !prevCheck);
    }
    const [selectS2, setSelectS2] = useState(false);
    const toggleSelectS2 = () => {
        setSelectS2(prevCheck => !prevCheck);
    }
    const [selectS3, setSelectS3] = useState(false);
    const toggleSelectS3 = () => {
        setSelectS3(prevCheck => !prevCheck);
    }
    const [selectS4, setSelectS4] = useState(false);
    const toggleSelectS4 = () => {
        setSelectS4(prevCheck => !prevCheck);
    }

    return {
        toggleFrameS,
        frameS,
        toggleSelectS1,
        selectS1,
        toggleSelectS2,
        selectS2,
    }
}

export default useMatchingFrameS;
