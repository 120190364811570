import { useState,useRef } from "react";

function useMatchingFrameM() {

    const [frameM, setframeM] = useState(false);
    const toggleFrameM = () => {
        setframeM(prevCheck => !prevCheck);
    }

    const [selectM1, setSelectM1] = useState(false);
    const toggleSelectM1 = () => {
        setSelectM1(prevCheck => !prevCheck);
    }
    const [selectM2, setSelectM2] = useState(false);
    const toggleSelectM2 = () => {
        setSelectM2(prevCheck => !prevCheck);
    }

    const [selectM3, setSelectM3] = useState(false);
    const toggleSelectM3 = () => {
        setSelectM3(prevCheck => !prevCheck);
    }

    const [selectM4, setSelectM4] = useState(false);
    const toggleSelectM4 = () => {
        setSelectM4(prevCheck => !prevCheck);
    }

    const uploadInput = useRef();

    return {
        toggleFrameM,
        frameM,
        toggleSelectM1,
        selectM1,
        toggleSelectM2,
        selectM2,
        toggleSelectM3,
        selectM3,
        toggleSelectM4,
        selectM4,
        uploadInput,
    }
}

export default useMatchingFrameM;
