import Select from "react-select";
import { Link } from "react-router-dom";
import { months, days, years } from "../../../helpers/practitionerHelper";
import {
  Email,
} from "./forms/form";
import { useUserStore } from "../../../store/userStore";

function FrameZCard({
  toggleFrameZ,
  frameZ,
  selectZ1, 
  toggleSelectZ1,
  selectZ2, 
  toggleSelectZ2,
  selectZ3, 
  toggleSelectZ3,
  toggleAnswersFrameA,
  answersFrameA,
  updateProgressStatus,
  toggleShowSaveResponse,
  plainResponse,
  style,
  responseCheck,
  setEmail,
  email,
  toggleShareEmail,
  shareEmail
}) {
  const doAddCrmEmail = useUserStore(
    (state) => state.doAddCrmEmail
  );
  return (
    <div>
      <h1>What is your date of birth?</h1>
      <div className="required-legal-purpose">required for legal purposes</div>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">Enter your Birthday</div>
          <div className="description"></div>

          <div className="form-wrapper">
             <div className="input-wrapper">
                <Select
                  classNamePrefix={"duration-select"}
                  options={months}
                  isSearchable={false}
                  placeholder={<div>MM</div>}
                  onChange={toggleSelectZ1}
                ></Select>
                <Select
                  classNamePrefix={"duration-select"}
                  options={days}
                  isSearchable={false}
                  placeholder={<div>DD</div>}
                  onChange={toggleSelectZ2}
                ></Select>
                <Select
                  classNamePrefix={"duration-select"}
                  options={years}
                  isSearchable={false}
                  placeholder={<div>YY</div>}
                  onChange={toggleSelectZ3}
                ></Select>
              </div>
          </div>

          <div className="terms">
          <input type="checkbox" checked={responseCheck} onChange={toggleShowSaveResponse} /> 
          I would like to share my responses to this quiz with my practitioner
          <p>to better assist with the support I receive </p>
          <p><input type="checkbox" checked={shareEmail} onChange={toggleShareEmail} /> I'd like to receive occasional updates and insights from LumiLife.<br /></p>
          <p><br /></p>
            <div style={style}>
              <p>To ensure your responses are linked correctly, please use the same <br />email address entered below when you create your LumiLife profile.</p>
              <p><br /></p>
              <Email onChange={setEmail} value={email} />
            </div>

            </div>

       
          <div className="next-long " onClick={async() => {
            if (
              selectZ1 ||
              selectZ2 ||
              selectZ3
            ) {
              updateProgressStatus()
              toggleFrameZ(); // hide
              toggleAnswersFrameA(); // show
              if(shareEmail) {
                let data = {};
                data.email = email;
                const response = await doAddCrmEmail(
                  data
                );
                console.log('response:::',response);
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameZCard;
