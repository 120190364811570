import { Link } from "react-router-dom";

function GetMatched() {
  return (
    <div id="get-matched">
      <h1>Your mind is a powerful tool.</h1>
      <h2>Use it to rewrite your reality</h2>
      <p>
        We know that, at times, life can be rough. And, you may feel like you’re
        not living to your full potential. We want to change that.
      </p>
      <p>
        LumiLife is here for you through your good days and the not so good
        ones. Our qualified practitioners are passionate about supporting women
        like you to navigate life’s hurdles through proven mindset and wellness
        therapies. By understanding your needs and goals, we’ll match you with
        practitioners who will listen, guide, and support you to live the life
        you deserve.
      </p>
          <a className="lumi-btn">Match me</a>
    </div>
  );
}

export default GetMatched;
