import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FrameTCard({
  toggleFrameT,
  frameT,
  toggleFrameE,
  frameE,
  toggleFrameO,
  frameO,
  toggleFrameP,
  frameP,
  toggleFrameQ,
  frameQ,
  toggleFrameR,
  frameR,
  toggleFrameS,
  frameS,
  toggleFrameN,
  frameN,
  selectT1, 
  toggleSelectT1,
  selectT2, 
  toggleSelectT2,
  selectT3, 
  toggleSelectT3,
  selectT4, 
  toggleSelectT4,
  toggleFrameMultiple,
  frameMultiple,
}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Support navigating those challenging life stages that us women go through
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectT1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectT1}>Menarche or the onset of menstruation</li>
            <li className={selectT2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectT2}>Fertility, Pregnancy, Pre or Post Natal support</li>
            <li className={selectT3 ? "li-selected-followup-questions" : ""} onClick={toggleSelectT3}>All stages of menopause</li>
            <li className={selectT4 ? "li-selected-followup-questions" : ""} onClick={toggleSelectT4}>Hormonal imbalances or support</li>

          </ul>

          <div className="next" onClick={() => {
            if (selectT1 || selectT2 || selectT3 || selectT4) {
              toggleFrameT();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameTCard;
