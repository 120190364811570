import { useState } from "react";

function useMatchingFrameU() {

    const [frameU, setframeU] = useState(false);
    const toggleFrameU = () => {
        setframeU(prevCheck => !prevCheck);
    }
    const [selectU1, setSelectU1] = useState(false);
    const toggleSelectU1 = () => {
        setSelectU1(prevCheck => !prevCheck);
    }
    const [selectU2, setSelectU2] = useState(false);
    const toggleSelectU2 = () => {
        setSelectU2(prevCheck => !prevCheck);
    }
    const [selectU3, setSelectU3] = useState(false);
    const toggleSelectU3 = () => {
        setSelectU3(prevCheck => !prevCheck);
    }
    const [selectU4, setSelectU4] = useState(false);
    const toggleSelectU4 = () => {
        setSelectU4(prevCheck => !prevCheck);
    }
    const [selectU5, setSelectU5] = useState(false);
    const toggleSelectU5 = () => {
        setSelectU5(prevCheck => !prevCheck);
    }
    const [selectU6, setSelectU6] = useState(false);
    const toggleSelectU6 = () => {
        setSelectU6(prevCheck => !prevCheck);
    }
    const [selectU7, setSelectU7] = useState(false);
    const toggleSelectU7 = () => {
        setSelectU7(prevCheck => !prevCheck);
    }
    const [selectU8, setSelectU8] = useState(false);
    const toggleSelectU8 = () => {
        setSelectU8(prevCheck => !prevCheck);
    }
    const [selectU9, setSelectU9] = useState(false);
    const toggleSelectU9 = () => {
        setSelectU9(prevCheck => !prevCheck);
    }

    return {
        toggleFrameU,
        frameU,
        toggleSelectU1,
        selectU1,
        toggleSelectU2,
        selectU2,
        toggleSelectU3,
        selectU3,
        toggleSelectU4,
        selectU4,
        toggleSelectU5,
        selectU5,
        toggleSelectU6,
        selectU6,
        toggleSelectU7,
        selectU7,
        toggleSelectU8,
        selectU8,
        toggleSelectU9,
        selectU9,
    }
}

export default useMatchingFrameU;
