import DashboardLayoutNoSession from "../../layouts/DashboardLayout/index_no_session";
import DateTimePill from "../../components/DateTimePill";
import Select from "react-select";
import LockIcon from "../../assets/images/lock.svg";
import useProfile from "../../hooks/useProfile";
import useLoader from "../../hooks/useLoader";
import useUserProfile from "../../hooks/useUserProfile";
import { useUserStore } from "../../store/userStore";
import {
  inflateValuesFromSelect,
  sanitizeUserUpdateParams,
  zeroPadDateString,
} from "../../utils/utilityFunctions";
import { monthsOptions, stateOptions } from "../../utils/globals";
import { BirthDate, Password } from "./forms/form";
import usePasswordEye from "../../hooks/usePasswordEye";
import Editor from "../../components/QuillEditor";
import { updateUserProfile } from "../../api";
import FaqForm from "./forms/";
import Asterisk from "../../assets/images/plus-icon.png";
import { FiPlus } from "react-icons/fi";

function Faq() {

  return (
    <DashboardLayoutNoSession>
      <div className="faqs">
        <div className="title-bar"></div>
        <div className="dash-faq-container">
          <div className="dash-faq-heading">FAQs</div>
          <div className="dash-faq-left">
            Providing honest answers will help you and us find the best
            practitioner for you.
          </div>
          <div className="faqs-section">
            <FaqForm />
          </div>
        </div>
      </div>
    </DashboardLayoutNoSession>
  );
}

export default Faq;
