import axios from "axios";
import checkResponseStatusCode from "../utils/checkResponseStatusCode";
import checkResponseObject from "../utils/checkResponseObject";
import { getUserSessionObject } from "../utils/utilityFunctions";

export const postLogin = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/login", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    return error.data;
  }
};

export const patchUserProfile = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .patch(
        process.env.REACT_APP_API_URL + "/api/practitioners/profile",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updatePractitionerSettings.catch", error.data);
    return error.data;
  }
};

export const postRegistration = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/register", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("postRegistration.catch", error.data);
    return error.data;
  }
};

export const postAddCrm = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/post-non-logged-hubspot-contact", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("postRegistration.catch", error.data);
    return error.data;
  }
};

export const postPayment = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/payment-checkout", params)
      .catch((error) => {
        console.log('error:',error);
      });

    console.log('res::',res);

    return res.data;
  } catch (error) {
    console.log("postRegistration.catch", error.data);
    return error.data;
  }
};

export const findPractitioner = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/practitioners/get", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("postRegistration.catch", error.data);
    return error.data;
  }
};

export const findPractitionerProfile = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/practitioners/get", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("findPractitionerProfile.catch", error.data);
    return error.data;
  }
};

export const updatePractitioner = async (params) => {
  console.log('params:::',params);
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .patch(
        process.env.REACT_APP_API_URL + "/api/practitioners/update",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updatePractitioner.catch", error.data);
    return error.data;
  }
};

export const fetchAllUsers = async () => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/all")
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {}
}

export const fetchProfessions = async () => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/practitioners/professions")
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {}
};

export const findUserProfile = async (params) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/get", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("findUserProfile.catch", error.data);
    return error.data;
  }
};

export const updatePdfUpload = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };

    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/pdfupload", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updateProfilePhoto.catch", error.data);
    return error.data;
  }
};

export const updateProfilePhoto = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };

    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/users/photo", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updateProfilePhoto.catch", error.data);
    return error.data;
  }
};

export const pendingPractitioner = async (params) => {
  try {
    const temp = { status: "pending" };
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/practitioners/get-pending-document", params)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("pendingPractitioner.catch", error.data);
  }
};

export const updatePractitionerVerificationPhoto = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/practitioners/verificationphoto",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updatePractitionerVerificationPhoto.catch", error.data);
    return error.data;
  }
};

export const updatePractitionerSettings = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .patch(
        process.env.REACT_APP_API_URL + "/api/practitioners/settings",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updatePractitionerSettings.catch", error.data);
    return error.data;
  }
};

export const getUserProfile = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/users/profile",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("getUserProfile.catch", error.data);
    return error.data;
  }
};

export const setAppointment = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/practitioners/appointment/set",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("getUserProfile.catch", error.data);
    return error.data;
  }
};

export const getAppointment = async (params) => {
  try {
    
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/practitioners/appointment/get",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("getPractitionerAppointment.catch", error.data);
    return error.data;
  }
}

export const updateUserProfile = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .patch(
        process.env.REACT_APP_API_URL + "/api/users/profile",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("updateUserProfile.catch", error.data);
    return error.data;
  }
};

export const sendMessage = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/messages/send",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("sendMessage.catch", error.data);
    return error.data;
  }
};

export const getMessages = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/api/messages/get", params, config)
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("getMessages.catch", error.data);
    return error.data;
  }
};

export const getInbox = async (params) => {
  try {
    const { jwtData } = getUserSessionObject();
    const config = {
      headers: { Authorization: jwtData },
    };
    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/api/messages/inbox",
        params,
        config
      )
      .catch((error) => {
        checkResponseStatusCode(error.response);
        let responseError = new Error(error.response.data.message);
        responseError.data = error.response.data;
        throw responseError;
      });

    checkResponseObject(res.data);

    return res.data;
  } catch (error) {
    console.log("getInbox.catch", error.data);
    return error.data;
  }
};
