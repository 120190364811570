import DashboardLayout from "../../layouts/DashboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import PractitionerSchedule from "../../components/PractitionerSchedule";
import useProfile from "../../hooks/useProfile";
import useLoader from "../../hooks/useLoader";
import { useAppointmentStore } from "../../store/appointmentStore";
import useAppointment from "../../hooks/useAppointment";
import { Link, useParams, useNavigate } from "react-router-dom";

function ClientBookCalendar() {
  const { id } = useParams();
  const { role, _id } = useProfile();
  const { schedules } = useAppointment(id);
  const doSetAppointment = useAppointmentStore(
    (state) => state.doSetAppointment
  );
  const { showLoader, showMessage, closeMessages, showError } = useLoader();

  console.log("schedules::", schedules);

  return (
    <>
      <DashboardLayout>
        <div className="practitioner-schedule">
          <div className="title-bar">
            <h1></h1>
          </div>
          <PractitionerSchedule
            clientId={_id}
            practitionerId={id}
            doSetAppointment={doSetAppointment}
            showLoader={showLoader}
            showMessage={showMessage}
            closeMessages={closeMessages}
            showError={showError}
            appointments={schedules}
          />
        </div>
      </DashboardLayout>
    </>
  );
}

export default ClientBookCalendar;
