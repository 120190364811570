import { useState, useEffect } from "react";
import { useAppointmentStore } from "../store/appointmentStore";
import "react-toastify/dist/ReactToastify.css";

function useAppointment(id) {
  const [datesUnavailable, setDatesUnavailable] = useState([
    /* Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1), */
    29,
  ]);

  const [dates, setDates] = useState([
    /* Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1),
    Math.floor(Math.random() * (30 - 1 + 1) + 1), */
    28,
  ]);

  const schedules = useAppointmentStore((state) => state.appointments);
  const doFetchPractitionerAppointment = useAppointmentStore(
    (state) => state.doFetchAppointment
  );

  useEffect(() => {
    doFetchPractitionerAppointment(id);
  }, []);

  return {
    dates,
    datesUnavailable,
    schedules,
    doFetchPractitionerAppointment,
  };
}

export default useAppointment;
