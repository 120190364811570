import lumilifeQuickAccessLogo from "../../assets/images/lumi-life-quick-access-logo.png";
import facebook from "../../assets/images/icon_facebook.png";
import instagram from "../../assets/images/icon_instagram.png";
import { useState } from "react";
import { useUserStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../ConfirmModal";
import { performLogout } from "../../utils/utilityFunctions";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";

function Footer() {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const jwtData = useUserStore((state) => state.jwtData);
  const loginLogoutAction = (e) => {
    e.preventDefault();
    console.log(jwtData);
    if (jwtData) setShowLogoutConfirm(true);
    else navigate("/practitioner-login");
  };

  return (
    <div className="mb-2">
      <div className="bg-lumi-pink mb-4 tablet:mt-8 flex desktop:flex-row tablet:flex-col items-center tablet:p-4 justify-center gap-2">
        <Heart className="h-[20px] w-[20px]" />
        <p className="text-lumi-body-text mt-3">
          If you are feeling suicidal or are in crisis call 000 (AU) or use
          these resources to get immediate help.
        </p>
      </div>
      <div className="flex desktop:flex-row items-center tablet:flex-col tablet:gap-2">
        <div className="flex flex-1 justify-center">
          <img
            alt="LumiLife Logo"
            src={lumilifeQuickAccessLogo}
            className="w-[240px] h-[120px] mr-16 tablet:mr-0"
          ></img>
        </div>
        <div className="flex flex-1 flex-row justify-between gap-8">
          <div className="flex flex-col gap-2 ml-8 tablet:ml-0">
            <h4 className="text-lumi-blue font-bold text-base">Quick Access</h4>
            <ul className="gap-1 flex flex-col">
              <li>
                <a href="#" className="text-lumi-body-text text-base">
                  Browse Practitioners
                </a>
              </li>
              <li>
                <a href="#" className="text-lumi-body-text text-base">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="text-lumi-body-text">
                  Medicate Rebates
                </a>
              </li>
              <li>
                <a href="/faq" className="text-lumi-body-text">
                  FAQS
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2 mr-8 ablet:mr-0">
            <h4 className="text-lumi-rose font-bold text-base">
              For Practitioners
            </h4>
            <ul className="flex flex-col gap-1">
              <li>
                <a
                  href={""}
                  className="text-lumi-body-text font-body"
                  onClick={loginLogoutAction}
                >
                  {jwtData ? "Logout" : "Login"}
                </a>
              </li>
              <li>
                <a href="#" className="text-lumi-body-text">
                  Forgot Password
                </a>
              </li>
              <li>
                <a href="/faq" className="text-lumi-body-text">
                  FAQS
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-1 gap-4 justify-center">
          <a href="#" className="h-9 w-9">
            <img src={facebook} className="w-full h-full"></img>
          </a>
          <a href="#" className="h-9 w-9">
            <img src={instagram} className="w-full h-full"></img>
          </a>
        </div>
        <div className="flex flex-1 flex-col justify-center tablet:items-center tablet:mt-4 tablet:px-4">
          <p className="text-lumi-gray">Copyright 2024 LumiLife PTY LTD</p>
          <p className="text-lumi-gray">
            Use of <span className="text-lumi-blue">lumilife.com.au</span> is
            subject to our <span className="text-lumi-rose">Terms of Use</span>{" "}
            and <span className="text-lumi-rose">Privacy Policy</span>
          </p>
        </div>
      </div>
      <ConfirmModal
        show={showLogoutConfirm}
        setShow={setShowLogoutConfirm}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          performLogout();
        }}
        onCancel={() => {}}
      ></ConfirmModal>
    </div>
  );
}

export default Footer;
