import { useState } from "react";

function useMatchingFrameF() {
    const [frameG, setframeG] = useState(false);
    const toggleFrameG = () => {
        setframeG(prevCheck => !prevCheck);
    }

    const [selectF1, setSelectF1] = useState(false);
    const toggleSelectF1 = () => {
        setSelectF1(prevCheck => !prevCheck);
    }
    const [selectF2, setSelectF2] = useState(false);
    const toggleSelectF2 = () => {
        setSelectF2(prevCheck => !prevCheck);
    }
    const [selectF3, setSelectF3] = useState(false);
    const toggleSelectF3 = () => {
        setSelectF3(prevCheck => !prevCheck);
    }
    const [selectF4, setSelectF4] = useState(false);
    const toggleSelectF4 = () => {
        setSelectF4(prevCheck => !prevCheck);
    }
    const [selectF5, setSelectF5] = useState(false);
    const toggleSelectF5 = () => {
        setSelectF5(prevCheck => !prevCheck);
    }

    return {
        toggleFrameG,
        frameG,
        toggleSelectF1,
        selectF1,
        toggleSelectF2,
        selectF2,
        toggleSelectF3,
        selectF3,
        toggleSelectF4,
        selectF4,
        toggleSelectF5,
        selectF5,

    }
}

export default useMatchingFrameF;
