function EmailForm() {
  return (
    <div id="emailform">
      <h3>
        We publish regular weekly topics, so make sure you get them first via
        email.
      </h3>
      <div id="subscribe-form">
        <div className="label">Stay up to date</div>
        <div>
          <input type="text" placeholder="Your email address"></input>
        </div>
        <button className="lumi-btn">Subscribe</button>
      </div>
    </div>
  );
}

export default EmailForm;
