import { useState } from "react";

function useMatchingFrameY() {

    const [frameY, setframeY] = useState(false);
    const toggleFrameY = () => {
        setframeY(prevCheck => !prevCheck);
    }
    const [selectY1, setSelectY1] = useState(false);
    const toggleSelectY1 = () => {
        setSelectY1(prevCheck => !prevCheck);
    }
    const [selectY2, setSelectY2] = useState(false);
    const toggleSelectY2 = () => {
        setSelectY2(prevCheck => !prevCheck);
    }
    const [selectY3, setSelectY3] = useState(false);
    const toggleSelectY3 = () => {
        setSelectY3(prevCheck => !prevCheck);
    }
    const [selectY4, setSelectY4] = useState(false);
    const toggleSelectY4 = () => {
        setSelectY4(prevCheck => !prevCheck);
    }
    const [selectY5, setSelectY5] = useState(false);
    const toggleSelectY5 = () => {
        setSelectY5(prevCheck => !prevCheck);
    }
    const [selectY6, setSelectY6] = useState(false);
    const toggleSelectY6 = () => {
        setSelectY6(prevCheck => !prevCheck);
    }
    const [selectY7, setSelectY7] = useState(false);
    const toggleSelectY7 = () => {
        setSelectY7(prevCheck => !prevCheck);
    }

    return {
        toggleFrameY,
        frameY,
        toggleSelectY1,
        selectY1,
        toggleSelectY2,
        selectY2,
        toggleSelectY3,
        selectY3,
        toggleSelectY4,
        selectY4,
        toggleSelectY5,
        selectY5,
        toggleSelectY6,
        selectY6,
        toggleSelectY7,
        selectY7,
    }
}

export default useMatchingFrameY;
