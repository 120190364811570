import { usePractitionerStore } from "../store/practitionerStore";
import { useEffect } from "react";

function usePractitioner() {
  const nameOrder = usePractitionerStore((state) => state.nameOrder);
  const professions = usePractitionerStore((state) => state.professions);
  const doFetch = usePractitionerStore((state) => state.doFetch);
  const doFetchProfessions =  usePractitionerStore((state) => state.doFetchProfessions);

  useEffect(() => {
    doFetch();
  }, []);

  useEffect(() => {
    doFetchProfessions();
  }, []);

  return { nameOrder, doFetch, doFetchProfessions, professions };
}

export default usePractitioner;
