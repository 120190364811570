import { useEffect, useState } from "react";
import Select from "react-select";
import { monthsOptions } from "../../../utils/globals";
import {
  inflateValuesFromSelect,
  zeroPadDateString,
} from "../../../utils/utilityFunctions";

export const Password = ({
  value,
  onChange,
  isReadable,
  clickHandler,
  icon,
}) => (
  <>
    <div className="password-wrapper">
      <img className="eye" src={icon} onClick={clickHandler} />
      <input
        className="password"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Password"
        type={isReadable ? "text" : "password"}
      ></input>
    </div>
  </>
);

//Assumes date is in YYYY-MM-DD format.
export const BirthDate = ({ date, callback }) => {
  const [birthYear, setBirthYear] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthDay, setBirthDay] = useState(null);

  useEffect(() => {
    if (date) {
      const pieces = date.split("-");
      setBirthYear(pieces[0]);
      setBirthMonth(pieces[1]);
      setBirthDay(parseInt(pieces[2]));
    }
  }, [date]);

  useEffect(() => {
    if (!birthYear || birthYear.length < 4) return;

    if (!birthMonth || parseInt(birthMonth) > 12) return;

    if (!birthDay || parseInt(birthDay) > 31) return;

    callback(zeroPadDateString(birthYear + "-" + birthMonth + "-" + birthDay));
  }, [birthYear, birthMonth, birthDay]);

  return (
    <div className="input-wrapper-50">
      <div className="personal-details-form-label">Date of Birth</div>
      <div className="dob-flex">
        <div>
          <Select
            placeholder={"Month"}
            classNamePrefix={"dob-select"}
            options={monthsOptions}
            isSearchable={false}
            value={
              birthMonth
                ? inflateValuesFromSelect(
                    [parseInt(birthMonth).toString()],
                    monthsOptions
                  )
                : null
            }
            onChange={(e) => {
              setBirthMonth(e.value);
            }}
          ></Select>
        </div>
        <div>
          <input
            className="personal-details-form-input"
            placeholder="Date"
            value={birthDay ? birthDay : ""}
            onChange={(e) => {
              if (e.target.value <= 31) setBirthDay(e.target.value);
            }}
            type="number"
            maxlength="2"
          ></input>
        </div>
        <div>
          <input
            className="personal-details-form-input"
            placeholder="Year"
            value={birthYear ? birthYear : ""}
            onChange={(e) => {
              if (e.target.value.length <= 4) setBirthYear(e.target.value);
            }}
            type="number"
            maxlength="4"
          ></input>
        </div>
      </div>
    </div>
  );
};
