import { useState } from "react";

function useMatchingFrameP() {

    const [frameP, setframeP] = useState(false);
    const toggleFrameP = () => {
        setframeP(prevCheck => !prevCheck);
    }
    const [selectP1, setSelectP1] = useState(false);
    const toggleSelectP1 = () => {
        setSelectP1(prevCheck => !prevCheck);
    }
    const [selectP2, setSelectP2] = useState(false);
    const toggleSelectP2 = () => {
        setSelectP2(prevCheck => !prevCheck);
    }
    const [selectP3, setSelectP3] = useState(false);
    const toggleSelectP3 = () => {
        setSelectP3(prevCheck => !prevCheck);
    }

    return {
        toggleFrameP,
        frameP,
        toggleSelectP1,
        selectP1,
        toggleSelectP2,
        selectP2,
        toggleSelectP3,
        selectP3,

    }
}

export default useMatchingFrameP;
