function FrameFCard({
  toggleFrameG,
  frameG,
  toggleFrameF,
  frameF,
  selectF1, 
  toggleSelectF1,
  selectF2, 
  toggleSelectF2,
  selectF3, 
  toggleSelectF3,
  selectF4, 
  toggleSelectF4,
  selectF5, 
  toggleSelectF5,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Get Matched!
          </div>
          <div className="description">Do you prefer working with clients that are:</div>
          <ul onClick={appendAnswers}>
            <li className={selectF1 ? "li-selected" : ""} onClick={toggleSelectF1} id='youth-under-16'>Youth under 16</li>
            <li className={selectF2 ? "li-selected" : ""} onClick={toggleSelectF2} id='under-30'>Under 30</li>
            <li className={selectF3 ? "li-selected" : ""} onClick={toggleSelectF3} id='between-30-50'>Between 30-50</li>
            <li className={selectF4 ? "li-selected" : ""} onClick={toggleSelectF4} id='over-50'>Over 50</li>
            <li className={selectF5 ? "li-selected" : ""} onClick={toggleSelectF5} id='no-preference'>No preference</li>
          </ul>

          <div className="next" onClick={() => {
            console.log('answers::',answers);
            if (
              selectF1 ||
              selectF2 ||
              selectF3 ||
              selectF4 ||
              selectF5
            ) {
              updateProgressStatus()
              toggleFrameF();
              toggleFrameG();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameFCard;
