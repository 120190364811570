import Asterisk from "../../../assets/images/asterisk_colored.png";
function SpecializationStar() {
  return (
    <div className="specialization-star">
      <div className="content">
        <div className="star-row">
          <div className="star-item">
            <h1 className="blue">Mental Health</h1>
            <h2>
              At times, having the support to make impactful steps towards deep,
              lasting change is exactly what you need.
            </h2>
          </div>
          <div className="star-middle"></div>
          <div className="star-item">
            <h1 className="rose">Holistic Health</h1>
            <h2>
              When everything feels out of balance, a holistic approach can help
              you find your center again.
            </h2>
          </div>
        </div>

        <div className="star-row middle">
          <div className="star-item push-left">
            <h1 className="yellow">Mindset Coaching</h1>
            <h2>
              Sometimes, you just need to break through those barriers to gain
              clarity and direction to take that next step.
            </h2>
          </div>
          <div className="star-middle">
            <img src={Asterisk}></img>
          </div>
          <div className="star-item push-right">
            <h1 className="pink">Nutritional Wellbeing</h1>
            <h2>
              Every now and then, having personalised nutrition advice can help
              you feel your best, inside and out.
            </h2>
          </div>
        </div>

        <div className="star-row">
          <div className="star-item">
            <h1 className="orange">Parenting and Relationships</h1>
            <h2>
              When family life gets overwhelming, a fresh perspective can help
              resolve conflicts and build stronger connections.
            </h2>
          </div>
          <div className="star-item desktop:ml-24">
            <h1 className="lightgreen">Financial Wellbeing</h1>
            <h2>
              When you want to shift your money mindset or tackle those
              financial goals, getting the right support can make changes
              happen.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecializationStar;
