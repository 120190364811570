import React from "react";

function CertificationUploadRequirementCard() {
  return (
    <div className="flex flex-col flex-1 max-w-[500px] p-8 bg-lumi-yellow-light font-body">
      <h3 className="text-base font-bold ">
        Are you a practitioner who is registered with APHRA, PACFA CPC, AASW,
        ACA Level 2, 3 & 4, or APD?
      </h3>
      <p className="my-2">If so, please upload:</p>
      <div className="flex flex-col ml-2">
        <p className="my-0">• Relevant registration certificate</p>
        <p className="my-0">• Driver's license/proof of identity</p>
        <p className="my-0">• Working with Children check (if applicable)</p>
      </div>
      <div className="h-2" />
      <p className="my-2">If you are not, please upload:</p>
      <div className="flex flex-col gap-0 ml-2">
        <p className="my-0">
          • Certificates or accreditations relevant to your profession
        </p>
        <p className="my-0">• Criminal History Check (ACIC)</p>
        <p className="my-0">• Working with Children Check (if applicable)</p>
        <p className="my-0">
          • Proof of Australian Work Rights (if not a citizen)
        </p>
        <p className="my-0">• Driver's licence or other proof of identity.</p>
      </div>
    </div>
  );
}

export default CertificationUploadRequirementCard;
