import React from "react";

function CertificationCard({ cert, isAdmin }) {
  return (
    <>
      <li>
        {isAdmin ? (
          <a
            className="text-lumi-blue"
            href={cert}
            target="_blank"
            rel="noopener"
          >
            {cert.substring(cert.lastIndexOf("/") + 1)}
          </a>
        ) : (
          <a
            className="text-lumi-blue"
          >
            {cert.substring(cert.lastIndexOf("/") + 1)}
          </a>
        )}
        {/* {!isAdmin && (
          <button
            className="lumi-btn join-call padding-horizontal"
            onClick={() =>
              download(cert, cert.substring(cert.lastIndexOf("/") + 1))
            }
          >
            Download
          </button>
        )} */}
      </li>
    </>
  );
}

export default CertificationCard;
