import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PractitionersFilter from "../../components/PractitionersFilter";
import PractitionersList from "../../components/PractitionersList";
import usePractitioner from "../../hooks/usePractitioner";
import RefineIcon from "../../assets/images/refine_arrow.png";

import { useState } from "react";
import QuoteBox from "../../components/QuoteBox";
import useIsMobile from "../../hooks/useIsMobile";

function Practitioners() {
  const { nameOrder } = usePractitioner();
  const [displayMode, setDisplayMode] = useState("list");

  const [refineSearch, setRefineSearch] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      <Header></Header>
      <div className="practitioners-page">
        <h1>Practitioners</h1>
        <h3>Easily find the best practitioners available</h3>

        <div className="filters-container row m-0">
          <div className="filters col-11">
            <div
              className={refineSearch ? "refine-search open" : "refine-search"}
              onClick={() => setRefineSearch(!refineSearch)}
            >
              Refine Search <img src={RefineIcon}></img>
            </div>
            {(!isMobile || refineSearch) && (
              <PractitionersFilter
                onChange={(mode) => {
                  setDisplayMode(mode);
                }}
              ></PractitionersFilter>
            )}
          </div>
          <div className="list col-11">
            <PractitionersList
              mode={displayMode}
              nameOrder={nameOrder}
            ></PractitionersList>
          </div>
        </div>
        <QuoteBox></QuoteBox>

        <div className="if-you-may-ask">
          <h2>If you may ask</h2>
          <ul>
            <li>
              <div className="question">
                Is online counselling and online therapy effective?
              </div>
              <div className="answer">
                Research shows that online counselling is just as effective as
                in-person counselling. Although therapists were mandated to move
                therapy online during the pandemic, research studies have shown
                that online therapy is equally as effective as in-person
                therapy. Our best rated therapists are now offering online
                therapy and are available for new clients.
              </div>
            </li>
            <li>
              <div className="question">
                Is online counselling and online therapy effective?
              </div>
              <div className="answer">
                If you have a complicated situation, it can be hard to know if
                it’s the right fit. Or you may be someone who feels in-person
                therapy is the only way to go. That is all valid. But the best
                way to know for sure is by having an honest conversation with a
                therapist. That's why on Talked you can test the waters with
                therapists who offer free consultations so that you can find the
                best therapist for your needs.
              </div>
            </li>
            <li>
              <div className="question">
                How will I connect with my online therapist?
              </div>
              <div className="answer">
                We will email you a join link which you can use to connect with
                your therapist using our secure video platform.
              </div>
            </li>
          </ul>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}

export default Practitioners;
