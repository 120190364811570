import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FrameSCard({
  toggleFrameS,
  frameS,
  toggleFrameE,
  frameE,
  toggleFrameO,
  frameO,
  toggleFrameP,
  frameP,
  toggleFrameQ,
  frameQ,
  toggleFrameR,
  frameR,
  toggleFrameT,
  frameT,
  toggleFrameN,
  frameN,
  selectS1, 
  toggleSelectS1,
  selectS2, 
  toggleSelectS2,
  toggleFrameMultiple,
  frameMultiple,

}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Eating disorders or emotional eating concerns
          </div>
          <div className="description">Have you been diagnosed with an eating disorder?</div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectS1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectS1}>Yes</li>
            <li className={selectS2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectS2}>No/Prefer not to say</li>
          </ul>

          <div className="next" onClick={() => {
            if (selectS1 || selectS2) {
              toggleFrameS();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameSCard;
