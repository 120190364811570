import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmModal({ show, title, setShow, message, onConfirm, onCancel }) {
  const handleConfirm = () => {
    setShow(false);
    onConfirm();
  };

  const handleCancel = () => {
    setShow(false);
    onCancel();
  };

  return (
    <>
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="lumirose" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmModal;
