import GoodReadsBG from "../../../assets/images/goodreads.png";
import ArticlePhoto1 from "../../../assets/images/photo_article1.jpg";
import ArticlePhoto2 from "../../../assets/images/photo_article2.jpg";
import ArticlePhoto3 from "../../../assets/images/photo_article3.jpg";

function GoodReads() {
  return (
    <div id="goodreads">
      <div className="row m-0">
        <div className="good-reads-script-container col-3">
          <img className="good-reads-script" src={GoodReadsBG}></img>
        </div>
        <div className="col-9">
          <div className="strips"></div>
        </div>
      </div>
      <div id="articles">
        <div className="article">
          <img className="article-photo" src={ArticlePhoto1}></img>
          <div className="article-card">
            <h3>Signs you're ready for a major life change</h3>
            <div className="divider"></div>
            <span className="article-snippet">
              Feeling stuck in a rut? Discover the undeniable signs that indicate you're ready to embrace a significant transformation in your life.
            </span>
          </div>
        </div>
        <div className="article">
          <img className="article-photo" src={ArticlePhoto2}></img>
          <div className="article-card">
            <h3>
              How caring less can boost your mental health
            </h3>
            <div className="divider"></div>
            <span className="article-snippet">
              Explore the surprising benefits of caring less: how a minimalist approach to worries and stress can significantly enhance your mental health.
            </span>
          </div>
        </div>
        <div className="article">
          <img className="article-photo" src={ArticlePhoto3}></img>
          <div className="article-card">
            <h3>Healing from burnout</h3>
            <div className="divider"></div>
            <span className="article-snippet">
              Explore the journey to recover from burnout, understanding its signs, and strategies to regain balance and wellness.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodReads;
