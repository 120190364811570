function FrameBCard({
  toggleFrameK,
  frameK,
  toggleFrameB,
  frameB,
  selectB1, 
  toggleSelectB1,
  selectB2, 
  toggleSelectB2,
  selectB3, 
  toggleSelectB3,
  selectB4, 
  toggleSelectB4,
  selectB5, 
  toggleSelectB5,
  selectB6, 
  toggleSelectB6,
  selectB7, 
  toggleSelectB7,
  selectB8, 
  toggleSelectB8,
  selectB9, 
  toggleSelectB9,
  selectB10, 
  toggleSelectB10,
  selectB11, 
  toggleSelectB11,
  selectB12, 
  toggleSelectB12,
  selectB13, 
  toggleSelectB13,
  selectB14, 
  toggleSelectB14,
  selectB15, 
  toggleSelectB15,
  selectB16, 
  toggleSelectB16,
  selectB17, 
  toggleSelectB17,
  updateProgressStatus,
  toggleAnswers,
  answers
})
{
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
    <h1>Who requires support</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
        <div className="question">
          It's for us; myself & my partner
          </div>
          <div className="description">Select one or multiple items you'd like support with</div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectB1 ? "li-selected" : ""} onClick={toggleSelectB1} id='communication-problems'>Communication problems</li>
            <li className={selectB2 ? "li-selected" : ""} onClick={toggleSelectB2} id='trust-or-infidelity-issues'>Trust or infidelity issues</li>
            <li className={selectB3 ? "li-selected" : ""} onClick={toggleSelectB3} id='intimacy-issues'>Intimacy issues</li>
            <li className={selectB4 ? "li-selected" : ""} onClick={toggleSelectB4} id='parenting-conflicts'>Parenting conflicts</li>
            <li className={selectB5 ? "li-selected" : ""} onClick={toggleSelectB5} id='financial-issues'>Financial issues</li>
            <li className={selectB6 ? "li-selected" : ""} onClick={toggleSelectB6} id='life-transitions'>Life transitions</li>
            <li className={selectB7 ? "li-selected" : ""} onClick={toggleSelectB7} id='jealousy-and-insecurity'>Jealousy and insecurity</li>
            <li className={selectB8 ? "li-selected" : ""} onClick={toggleSelectB8} id='Cultural or value differences'>Cultural or value differences</li>
            <li className={selectB9 ? "li-selected" : ""} onClick={toggleSelectB9} id='codependency'>Codependency</li>
            <li className={selectB10 ? "li-selected" : ""} onClick={toggleSelectB10} id='anger-and-resentment'>Anger and resentment</li>
            <li className={selectB11 ? "li-selected" : ""} onClick={toggleSelectB11} id='lack-of-affection'>Lack of affection</li>
            <li className={selectB12 ? "li-selected" : ""} onClick={toggleSelectB12} id='power-struggles'>Power struggles</li>
            <li className={selectB13 ? "li-selected" : ""} onClick={toggleSelectB13} id='different-life-goals'>Different life goals</li>
            <li className={selectB14 ? "li-selected" : ""} onClick={toggleSelectB14} id='addiction-issues'>Addiction issues</li>
            <li className={selectB15 ? "li-selected" : ""} onClick={toggleSelectB15} id='trauma'>Trauma</li>
            <li className={selectB16 ? "li-selected" : ""} onClick={toggleSelectB16} id='family-counselling'>Family counselling</li>
            <li className={selectB17 ? "li-selected" : ""} onClick={toggleSelectB17} id='domestic-violence'>Domestic violence</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
            if (
              selectB1 ||
              selectB2 ||
              selectB3 ||
              selectB4 ||
              selectB5 ||
              selectB6 ||
              selectB7 ||
              selectB8 ||
              selectB9 ||
              selectB10 ||
              selectB11 ||
              selectB12 ||
              selectB13 ||
              selectB14 ||
              selectB15 ||
              selectB16 ||
              selectB17
            ) {
              updateProgressStatus()
              toggleFrameB();
              toggleFrameK();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameBCard;
