import { useState } from "react";

function useMatchingFrameMultiple() {

    const [frameMultiple, setframeMultiple] = useState([]);
    const [frameFollowupQuestion, setframeFollowupQuestion] = useState([]);
    const toggleFrameMultiple = (arr) => {
        console.log('toggleFrameMultiple::',arr.target.id);
        if(arr.target.id) {
              let frames = updateFrames(frameMultiple, arr.target.id);
              setframeMultiple(frames);
            } else {
              let followupQuestions = updateFrames(frameFollowupQuestion, arr.target.textContent);
              updateFollowupQuestionFrames(followupQuestions);
            }
    }
    const updateFrames = (arr, val) => {
        var i = arr.indexOf(val);
        if (i === -1)
            arr.push(val);
        else
            arr.splice(i,1);
        
            return arr;
    }

    const updateFollowupQuestionFrames = (arr, val) => {
        var i = arr.indexOf(val);
        if (i === -1)
            arr.push(val);
        else
            arr.splice(i,1);
        
            return arr;
    }

    return {
        toggleFrameMultiple,
        frameMultiple,
        frameFollowupQuestion,
    }
}

export default useMatchingFrameMultiple;
