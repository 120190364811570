import MentalHealthBG from "../../../assets/images/bg_mental_health.png";
import MindsetCoachingBG from "../../../assets/images/bg_mindset_coaching.png";
import ParentingBG from "../../../assets/images/bg_parenting.png";
import HolisticBG from "../../../assets/images/bg_holistic.png";
import WellbeingBG from "../../../assets/images/bg_wellbeing.png";
import FinanceBG from "../../../assets/images/bg_finance.jpg";

import SpecialtyItem from "../components/SpecialtyItem";

function Specialties() {
  return (
    <div id="specialties">
      <ul>
        <SpecialtyItem
          src={MentalHealthBG}
          color={"blue"}
          name={"Mental Health"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={MindsetCoachingBG}
          color={"yellow"}
          name={"Mindset Coaching"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={ParentingBG}
          color={"orange"}
          name={"Parenting & Relationships"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={HolisticBG}
          color={"rose"}
          name={"Holistic Health"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={WellbeingBG}
          color={"pink"}
          name={"Nutritional Wellbeing"}
        ></SpecialtyItem>
        <SpecialtyItem
          src={FinanceBG}
          color={"lightgreen"}
          name={"Financial Wellbeing"}
        ></SpecialtyItem>
        <div className="clear-both"></div>
      </ul>
    </div>
  );
}

export default Specialties;
