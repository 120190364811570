import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import Select from "react-select";
import { useEffect, useState, useRef } from "react";
import LockIcon from "../../assets/images/lock.svg";
import { Modal, Button } from "react-bootstrap";
import useProfile from "../../hooks/useProfile";
import usePractitionerProfile from "../../hooks/usePractitionerProfile";
import useLoader from "../../hooks/useLoader";
import {
  updatePractitionerSettings,
  updatePractitionerVerificationPhoto,
} from "../../api";
import { useUserStore } from "../../store/userStore";
import CropperModal from "../../components/CropperModal";

import {
  translateStatus,
  inflateValuesFromSelect,
  isEmptyObject,
} from "../../utils/utilityFunctions";

function PractitionerSettings() {
  const IDTypes = [
    { label: "Type 1", value: "Type 1" },
    { label: "Type 2", value: "Type 2" },
    { label: "Type 3", value: "Type 3" },
    { label: "Type 4", value: "Type 4" },
    { label: "Type 5", value: "Type 5" },
  ];

  //Special state for rate for the $ sign UI.
  const [rate, setRate] = useState("$ ");
  const setPracRate = (e) => {
    let val = e.target.value;
    if (!val) return "$ ";

    val = val + "";

    stateChangeHandler(
      "hourlyrate",
      val.replaceAll("$", "").replaceAll(" ", "")
    );

    if (val.startsWith("$ ")) return setRate(val);
    else setRate("$ " + val.replaceAll("$", ""));
  };

  //Modal states.
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const handleSuspendClose = () => {
    setShowSuspendModal(false);
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  //Get logged in user profile.
  const { _id } = useProfile();
  const { profile: practitionerProfile, errorMessage: fetchMessage } =
    usePractitionerProfile(_id);
  const setProfile = useUserStore((state) => state.setProfile);

  //Local state.
  const [localState, setLocalState] = useState({
    idtype: null,
    registrationnumber: null,
    verificationphoto: null,
    bankaccountname: null,
    bankaccountnumber: null,
    bsb: null,
    hourlyrate: null,
    sessionduration: null,
  });
  const stateChangeHandler = (key, value) => {
    setLocalState((prev) => {
      return { ...prev, [key]: value };
    });
  };

  //For initial loading of data.
  useEffect(() => {
    if (practitionerProfile) {
      setLocalState(practitionerProfile.verification);
      setPracRate({
        target: { value: practitionerProfile.verification.hourlyrate },
      });
    }
  }, [practitionerProfile]);

  //Photo upload and Cropper states
  const uploadInput = useRef();
  const [showCropper, setShowCropper] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [cropperFile, setCropperFile] = useState(null);
  const handleCropperConfirm = async (file) => {
    setShowCropper(false);
    setAvatarUrl(null);
    setCropperFile(file);

    showLoader("Uploading photo. Please wait...");
    const result = await updatePractitionerVerificationPhoto({
      photo: file.url,
      verificationid: localState._id,
    });
    closeMessages();
    if (result.error) {
      showError(result.message);
    } else {
      setProfile(result.result);
      showMessage("Verification photo has been updated successfully.");
    }
  };
  const handleCropperCancel = () => {
    setShowCropper(false);
    setCropperFile(null);
    setAvatarUrl(null);
  };

  const getPhotoSource = () => {
    if (cropperFile) {
      return cropperFile.url;
    }
    if (practitionerProfile.verification.verificationphoto)
      return practitionerProfile.verification.verificationphoto;
    else return "";
  };

  //Loader hook
  const { showLoader, showMessage, closeMessages, showError } = useLoader();

  //Submit form handler
  const submitForm = async () => {
    showLoader("Saving changes...");
    let params = localState;
    delete params._id;
    const result = await updatePractitionerSettings(params);
    closeMessages();
    if (result.error) {
      showError(result.message);
    } else {
      setProfile(result.result);
      showMessage("Changes have been saved successfully.");
    }
  };

  return (
    <DashboardLayout>
      {practitionerProfile ? (
        <div className="practitioner-settings">
          <div className="title-bar">
            <h1>Settings</h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>

          <div className="dash-prac-settings-container">
            <div className="dash-prac-settings-left">
              <div className="verification-box">
                <h2>Verification</h2>
                <div className="verification-widget">
                  <div className="verification-widget-inner">
                    <span className="verification-widget-text">
                      Status:{" "}
                      <span className="status unverified">
                        {translateStatus(practitionerProfile.status)}
                      </span>
                    </span>
                    <span className="verification-info-text">
                      Please provide required documents for verifcation.
                    </span>
                  </div>
                </div>
                <div className="verification-form">
                  <div className="verification-form-left">
                    <div className="verification-form-label">ID Type</div>
                    <Select
                      classNamePrefix={"verification-select"}
                      options={IDTypes}
                      isSearchable={false}
                      value={
                        localState.idtype
                          ? inflateValuesFromSelect([localState.idtype])
                          : null
                      }
                      onChange={(e) => {
                        stateChangeHandler("idtype", e.value);
                      }}
                    ></Select>
                    <div className="verification-form-label">
                      Registration Number
                    </div>
                    <input
                      className="verification-input"
                      value={
                        localState.registrationnumber
                          ? localState.registrationnumber
                          : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler(
                          "registrationnumber",
                          e.target.value
                        );
                      }}
                    ></input>
                  </div>
                  <div className="verification-form-right">
                    <div className="verification-form-label">
                      Upload a photo
                    </div>
                    <div
                      className="verification-input verification-photo"
                      onClick={() => {
                        uploadInput.current.click();
                      }}
                    >
                      {getPhotoSource() ? (
                        <img
                          className="verification-photo-img"
                          src={getPhotoSource()}
                        ></img>
                      ) : (
                        <div>Take or upload a photo</div>
                      )}
                      <input
                        className="image-file-input"
                        type="file"
                        ref={uploadInput}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => {
                          if (e.target.files) {
                            setAvatarUrl(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setShowCropper(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bank-details-box">
                <h2>Bank Details</h2>
                <div className="bank-details-form">
                  <div className="bank-details-form-left">
                    <div className="bank-details-form-label">Account Name</div>
                    <input
                      className="bank-details-form-input"
                      value={
                        localState.bankaccountname
                          ? localState.bankaccountname
                          : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler("bankaccountname", e.target.value);
                      }}
                    ></input>
                    <div className="bank-details-form-label">
                      Account Number
                    </div>
                    <input
                      className="bank-details-form-input"
                      value={
                        localState.bankaccountnumber
                          ? localState.bankaccountnumber
                          : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler("bankaccountnumber", e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="bank-details-form-right">
                    <div className="bank-details-form-label">BSB</div>
                    <input
                      className="bank-details-form-input"
                      value={localState.bsb ? localState.bsb : ""}
                      onChange={(e) => {
                        stateChangeHandler("bsb", e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="fee-structure-box">
                <h2>Fee Structure</h2>
                <div className="fee-structure-form">
                  <div className="fee-structure-form-left">
                    <div className="fee-structure-form-label">
                      Your Hourly Rate
                    </div>
                    <input
                      onChange={setPracRate}
                      value={rate}
                      className="fee-structure-form-input"
                    ></input>
                  </div>
                  <div className="fee-structure-form-right">
                    <div className="fee-structure-form-label">
                      Duration per session (in minutes)
                    </div>
                    <input
                      className="fee-structure-form-input"
                      value={
                        localState.sessionduration
                          ? localState.sessionduration
                          : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler("sessionduration", e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="credit-card-box">
                <h2>
                  <img src={LockIcon}></img>Credit Card Information
                </h2>
                <div className="credit-card-widget">
                  <div className="credit-card-widget-inner">
                    <span className="credit-card-widget-text">
                      <b>Your credit card details are protected. </b>
                    </span>
                    <span className="credit-card-info-text">
                      We partner with Airpay so that your card details are safe.
                      Lumilife will not have access to your card info.
                    </span>
                  </div>
                  <div className="add-new-card lumi-btn">Add New Card</div>
                </div>
              </div>
              <button className="save-button lumi-btn" onClick={submitForm}>
                Save Changes
              </button>
              <div className="practitioner-settings-separator"></div>
              <div className="practitioner-settings-actions">
                <div
                  className="link"
                  onClick={() => {
                    setShowSuspendModal(true);
                  }}
                >
                  Suspend Account
                </div>{" "}
                or{" "}
                <div
                  className="link"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete Account
                </div>
              </div>
            </div>
            <div className="dash-prac-settings-right"></div>
          </div>
          <Modal
            show={showSuspendModal}
            onHide={handleSuspendClose}
            dialogClassName="practitioner-settings-modal"
            centered={true}
          >
            <Modal.Body>
              <h3>Suspend Account</h3>
              Account suspension will remove your name from being matched and
              seen from the practitioner list. Can be reversed anytime.
              <h4>Enter your username to to confirm account suspension.</h4>
              <input className="practitioner-settings-modal-input"></input>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="lumirose" onClick={handleSuspendClose}>
                Suspend Account
              </Button>
              <Button variant="secondary" onClick={handleSuspendClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showDeleteModal}
            onHide={handleDeleteClose}
            dialogClassName="practitioner-settings-modal"
            centered={true}
          >
            <Modal.Body>
              <h3>Delete Account</h3>
              This action will remove all your account including all the
              information therein.
              <h4>Enter your username to to confirm account deletion.</h4>
              <input className="practitioner-settings-modal-input"></input>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="lumirose" onClick={handleDeleteClose}>
                Delete Account
              </Button>
              <Button variant="secondary" onClick={handleDeleteClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div>Loading Profile Data...</div>
      )}

      <CropperModal
        show={showCropper}
        avatarUrl={avatarUrl}
        handleCancel={handleCropperCancel}
        handleConfirm={handleCropperConfirm}
        aspectRatio={null}
      ></CropperModal>
    </DashboardLayout>
  );
}

export default PractitionerSettings;
