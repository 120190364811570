import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useLoader(mode, message) {
  const showLoader = (message) => {
    toast(message, {
      position: "bottom-center",
      autoClose: false,
      isLoading: true,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showMessage = (message) => {
    toast(message, {
      position: "bottom-center",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const closeMessages = () => {
    toast.dismiss();
  };

  return {
    showLoader,
    showMessage,
    closeMessages,
    showError,
  };
}

export default useLoader;
