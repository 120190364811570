import { useState } from "react";

function useMatchingFrameJ() {
    const [frameJ, setframeJ] = useState(false);
    const toggleFrameJ = () => {
        setframeJ(prevCheck => !prevCheck);
    }
    const [frameN, setframeN] = useState(false);
    const toggleFrameN = () => {
        console.log('xxxxxxxxxxxxx');
        setframeN(prevCheck => !prevCheck);
    }

    const [selectJ1, setSelectJ1] = useState(false);
    const toggleSelectJ1 = () => {
        setSelectJ1(prevCheck => !prevCheck);
    }
    const [selectJ2, setSelectJ2] = useState(false);
    const toggleSelectJ2 = () => {
        setSelectJ2(prevCheck => !prevCheck);
    }
    const [selectJ3, setSelectJ3] = useState(false);
    const toggleSelectJ3 = () => {
        setSelectJ3(prevCheck => !prevCheck);
    }
    const [selectJ4, setSelectJ4] = useState(false);
    const toggleSelectJ4 = () => {
        setSelectJ4(prevCheck => !prevCheck);
    }
    const [selectJ5, setSelectJ5] = useState(false);
    const toggleSelectJ5 = () => {
        setSelectJ5(prevCheck => !prevCheck);
    }
    const [selectJ6, setSelectJ6] = useState(false);
    const toggleSelectJ6 = () => {
        setSelectJ6(prevCheck => !prevCheck);
    }
    const [selectJ7, setSelectJ7] = useState(false);
    const toggleSelectJ7 = () => {
        setSelectJ7(prevCheck => !prevCheck);
    }
    const [selectJ8, setSelectJ8] = useState(false);
    const toggleSelectJ8 = () => {
        setSelectJ8(prevCheck => !prevCheck);
    }
    const [selectJ9, setSelectJ9] = useState(false);
    const toggleSelectJ9 = () => {
        setSelectJ9(prevCheck => !prevCheck);
    }
    const [selectJ10, setSelectJ10] = useState(false);
    const toggleSelectJ10 = () => {
        setSelectJ10(prevCheck => !prevCheck);
    }
    const [selectJ11, setSelectJ11] = useState(false);
    const toggleSelectJ11 = () => {
        setSelectJ11(prevCheck => !prevCheck);
    }
    const [selectJ12, setSelectJ12] = useState(false);
    const toggleSelectJ12 = () => {
        setSelectJ12(prevCheck => !prevCheck);
    }
    const [selectJ13, setSelectJ13] = useState(false);
    const toggleSelectJ13 = () => {
        setSelectJ13(prevCheck => !prevCheck);
    }
    const [selectJ14, setSelectJ14] = useState(false);
    const toggleSelectJ14 = () => {
        setSelectJ14(prevCheck => !prevCheck);
    }
    const [selectJ15, setSelectJ15] = useState(false);
    const toggleSelectJ15 = () => {
        setSelectJ15(prevCheck => !prevCheck);
    }
    const [selectJ16, setSelectJ16] = useState(false);
    const toggleSelectJ16 = () => {
        setSelectJ16(prevCheck => !prevCheck);
    }
    const [selectJ17, setSelectJ17] = useState(false);
    const toggleSelectJ17 = () => {
        setSelectJ17(prevCheck => !prevCheck);
    }
    const [selectJ18, setSelectJ18] = useState(false);
    const toggleSelectJ18 = () => {
        setSelectJ18(prevCheck => !prevCheck);
    }
    const [selectJ19, setSelectJ19] = useState(false);
    const toggleSelectJ19 = () => {
        setSelectJ19(prevCheck => !prevCheck);
    }
    const [selectJ20, setSelectJ20] = useState(false);
    const toggleSelectJ20 = () => {
        setSelectJ20(prevCheck => !prevCheck);
    }

    return {
        toggleFrameJ,
        frameJ,
        toggleFrameN,
        frameN,
        toggleSelectJ1,
        selectJ1,
        toggleSelectJ2,
        selectJ2,
        toggleSelectJ3,
        selectJ3,
        toggleSelectJ4,
        selectJ4,
        toggleSelectJ5,
        selectJ5,
        toggleSelectJ6,
        selectJ6,
        toggleSelectJ7,
        selectJ7,
        toggleSelectJ8,
        selectJ8,
        toggleSelectJ9,
        selectJ9,
        toggleSelectJ10,
        selectJ10,
        toggleSelectJ11,
        selectJ11,
        toggleSelectJ12,
        selectJ12,
        toggleSelectJ13,
        selectJ13,
        toggleSelectJ14,
        selectJ14,
        toggleSelectJ15,
        selectJ15,
        toggleSelectJ16,
        selectJ16,
        toggleSelectJ17,
        selectJ17,
        toggleSelectJ18,
        selectJ18,
        toggleSelectJ19,
        selectJ19,
        toggleSelectJ20,
        selectJ20,
    }
}

export default useMatchingFrameJ;
