import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import usePasswordEye from "../../hooks/usePasswordEye";
import Header from "../../components/Header/index.js";
import Footer from "../../components/Footer";
import {
  Email,
  Password,
  Actions,
  SignupLink,
  ErrorMessage,
} from "./forms/form";

import { useUserStore } from "../../store/userStore";
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLoader from "../../hooks/useLoader";
import { validateEmail } from "../../utils/utilityFunctions";
import useEnterKey from "../../hooks/useEnterKey";

function ClientLogin() {
  const { icon, clickHandler, isReadable } = usePasswordEye();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const navigate = useNavigate();

  /*const email = useStore((state) => state.email);
  const setEmail = useStore((state) => state.setEmail);
  const password = useStore((state) => state.password);
  const setPassword = useStore((state) => state.setPassword);
  const login = useStore((state) => state.login);
  const jwtData = useStore((state) => state.jwtData);
  const error = useStore((state) => state.error);*/

  const doLogin = useUserStore((state) => state.doLogin);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const loginCallback = async () => {
    try {
      console.log("EMAIL", email);
      setError("");

      if (!email || !validateEmail(email)) {
        setError("Please provide a valid email.");
        return;
      }

      if (!password) {
        setError("Please provide a password.");
        return;
      }

      showLoader("Logging in. Please wait..");
      const res = await doLogin(email, password);
      closeMessages();
      if (res.error) {
        showError(res.message);
        return;
      }

      navigate(`/${res.result.profile.role}/dashboard`);
    } catch (error) {
      closeMessages();
      showError("Something went wrong.");
    }
  };
  useEnterKey(loginCallback);

  /*useEffect(() => {
    checkIfAuth(jwtData);
  }, [jwtData]);*/

  return (
    <>
      <Header></Header>
      <div className="client-login">
        <div className="header">
          <div>
            <img id="logo" src={logo}></img>
          </div>
        </div>
        <div className="row m-0">
          <div className="left col-6">
            <h1>
              Helping women find <br />
              their inner shine.
            </h1>
            <h3>Loads of helpful humans all in one place</h3>
          </div>
          <div className="right col-6">
            <h3>Client Login</h3>
            <Email onChange={setEmail} value={email} />
            <Password
              onChange={setPassword}
              value={password}
              isReadable={isReadable}
              clickHandler={clickHandler}
              icon={icon}
            />

            <Link className="forgot-password">Forgot your password?</Link>
            <ErrorMessage error={error} />
            <Actions onAdd={loginCallback} />
            <SignupLink />
          </div>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}

export default ClientLogin;
