import { useState } from "react";

function useMatchingFrameR() {

    const [frameR, setframeR] = useState(false);
    const toggleFrameR = () => {
        setframeR(prevCheck => !prevCheck);
    }
    const [selectR1, setSelectR1] = useState(false);
    const toggleSelectR1 = () => {
        setSelectR1(prevCheck => !prevCheck);
    }
    const [selectR2, setSelectR2] = useState(false);
    const toggleSelectR2 = () => {
        setSelectR2(prevCheck => !prevCheck);
    }
    const [selectR3, setSelectR3] = useState(false);
    const toggleSelectR3 = () => {
        setSelectR3(prevCheck => !prevCheck);
    }
    const [selectR4, setSelectR4] = useState(false);
    const toggleSelectR4 = () => {
        setSelectR4(prevCheck => !prevCheck);
    }

    return {
        toggleFrameR,
        frameR,
        toggleSelectR1,
        selectR1,
        toggleSelectR2,
        selectR2,
        toggleSelectR3,
        selectR3,
        toggleSelectR4,
        selectR4,
    }
}

export default useMatchingFrameR;
