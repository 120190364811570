import { useState } from "react";

function useMatchingFrameI() {
    const [frameI, setframeI] = useState(false);
    const toggleFrameI = () => {
        setframeI(prevCheck => !prevCheck);
    }

    const [selectI1, setSelectI1] = useState(false);
    const toggleSelectI1 = () => {
        setSelectI1(prevCheck => !prevCheck);
        setSelectI2(false);
    }
    const [selectI2, setSelectI2] = useState(false);
    const toggleSelectI2 = () => {
        setSelectI1(false);
        setSelectI2(prevCheck => !prevCheck);
    }

    return {
        toggleFrameI,
        frameI,
        toggleSelectI1,
        selectI1,
        toggleSelectI2,
        selectI2,
    }
}

export default useMatchingFrameI;
