
import Asterisk from "../../../assets/images/plus-icon.png";
import useFaqsHook from "../../../hooks/faq";

function FaqForm() {

    const {
      toggleAccordion1,
      toggleAccordion2,
      toggleAccordion3,
      toggleAccordion4,
      active1,
      active2,
      active3,
      active4,
      contentRef1,
      contentRef2,
      contentRef3,
      contentRef4,
    } = useFaqsHook();
    
  return (
    <>
      <div className="faqs-section-tab">
        <button
          className={`question-section ${active1}`}
          onClick={toggleAccordion1}
        >
          <div>
            <div className="question-align">
              <img className="sidebar-navigation-asterisk" src={Asterisk}></img>
              <h4 className="question-styling padding-40">
                How can I schedule a session
              </h4>
            </div>
            <div
              ref={contentRef1}
              className={active1 ? `answer answer-divider` : `answer`}
            >
              <p className="padding-40 font-orange">
                To book a session, start by finding a therapist who resonates
                with you or by clicking on the Get Matched button. When you're
                ready to schedule, simple choose an available time slot and
                follow the steps to finalise your booking
              </p>
            </div>
          </div>
        </button>
      </div>
      <div className="faqs-section-tab">
        <button
          className={`question-section ${active2}`}
          onClick={toggleAccordion2}
        >
          <div>
            <div className="question-align">
              <img className="sidebar-navigation-asterisk" src={Asterisk}></img>
              <h4 className="question-styling padding-40">
                Can I book face-to-face appointments
              </h4>
            </div>
            <div
              ref={contentRef2}
              className={active2 ? `answer answer-divider` : `answer`}
            >
              <p className="padding-40 font-orange">Definition</p>
            </div>
          </div>
        </button>
      </div>
      <div className="faqs-section-tab">
        <button
          className={`question-section ${active3}`}
          onClick={toggleAccordion3}
        >
          <div>
            <div className="question-align">
              <img className="sidebar-navigation-asterisk" src={Asterisk}></img>
              <h4 className="question-styling padding-40">
                Nutrition or dietician... What's the difference?
              </h4>
            </div>
            <div
              ref={contentRef3}
              className={active3 ? `answer answer-divider` : `answer`}
            >
              <p className="padding-40 font-orange">Definition</p>
            </div>
          </div>
        </button>
      </div>
      <div className="faqs-section-tab">
        <button
          className={`question-section ${active4}`}
          onClick={toggleAccordion4}
        >
          <div>
            <div className="question-align">
              <img className="sidebar-navigation-asterisk" src={Asterisk}></img>
              <h4 className="question-styling padding-40">
                Who is Lumilife?
              </h4>
            </div>
            <div
              ref={contentRef4}
              className={active4 ? `answer answer-divider` : `answer`}
            >
              <p className="padding-40 font-orange">Definition</p>
            </div>
          </div>
        </button>
      </div>
    </>
  );
}

export default FaqForm;
