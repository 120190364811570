import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FrameQCard({
  toggleFrameQ,
  frameQ,
  toggleFrameE,
  frameE,
  toggleFrameO,
  frameO,
  toggleFrameP,
  frameP,
  toggleFrameR,
  frameR,
  toggleFrameS,
  frameS,
  toggleFrameT,
  frameT,
  toggleFrameN,
  frameN,
  selectQ1, 
  toggleSelectQ1,
  selectQ2, 
  toggleSelectQ2,
  selectQ3, 
  toggleSelectQ3,
  selectQ4, 
  toggleSelectQ4,
  selectQ5, 
  toggleSelectQ5,
  toggleFrameMultiple,
  frameMultiple,
}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Trauma, PTSD, Sexual abuse, Domestive abuse
          </div>
          <div className="description"></div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectQ1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectQ1}>Trauma</li>
            <li className={selectQ2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectQ2}>PTSD</li>
            <li className={selectQ3 ? "li-selected-followup-questions" : ""} onClick={toggleSelectQ3}>Sexual abuse</li>
            <li className={selectQ4 ? "li-selected-followup-questions" : ""} onClick={toggleSelectQ4}>Domestic abuse</li>
            <li className={selectQ5 ? "li-selected-followup-questions" : ""} onClick={toggleSelectQ5}>Prefer to not say</li>
          </ul>

          <div className="next" onClick={() => {
            if (selectQ1 || selectQ2 || selectQ3 || selectQ4 || selectQ5) {
              toggleFrameQ();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameQCard;
