import useMatchingFrameB from "../../../hooks/useMatchingFrameB"; // new
import { executeMultipleQuestions } from "../../../helpers/practitionerHelper";

function FrameKCard({
  toggleFrameK,
  frameK,
  toggleFrameL,
  frameL,
  selectK1, 
  toggleSelectK1,
  selectK2, 
  toggleSelectK2,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const { funnelAnswers } = useMatchingFrameB(); // new
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
    <h1>Do you currently have any desire to harm yourself or others?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          {/* <div className="question">
          Get Matched!
          </div>
          <div className="description">Are you qualified to work with diagnosed mental health conditions or personality disorders?</div> */}
          <ul className="one-col-ul" onClick={appendAnswers}>
            <li className={selectK1 ? "li-selected" : ""} onClick={toggleSelectK1} id='yes-desired-to-harm-my-self'><b>Yes</b> - we are not a crisis centre, please call 000 or lifeline on 13 11 14.</li>
            <li className={selectK2 ? "li-selected" : ""} onClick={toggleSelectK2} id='no-desired-to-harm-my-self'><b>No</b> - keep going.</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
            if (
              selectK1 ||
              selectK2
            ) {
              updateProgressStatus()
              toggleFrameK();
              toggleFrameL();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameKCard;
