import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { postLogin, postRegistration, findUserProfile, postAddCrm, postPayment, updatePractitioner, findPractitionerProfile } from "../api";
import { setUser } from "../utils/setUser";
import { createJSONStorage } from "zustand/middleware";

const initialState = {
  token: "",
  jwtData: "",
  avatar: "",
  profile: {
    _id: "",
    firstname: "",
    lastname: "",
    role: "",
    photo: "",
    name: "",
  },
};

const store = (set, get) => ({
  //state
  ...initialState,
  setJwtData: (jwtData) => set({ jwtData }),
  setProfile: (profile) => set({ profile }),
  doReset: () => set(initialState),
  doLogin: async (email, password, role = "client") => {
    try {
      let loginResult = await postLogin({
        email,
        password,
        role,
      });

      if (loginResult.error) {
        set({ jwtData: "" });
      } else {
        console.log('loginResult.result.profile::',loginResult.result.profile);
        set({
          jwtData: setUser(loginResult.result.token),
          profile: loginResult.result.profile,
        });
      }
      return loginResult;
    } catch (e) {
      const errorMessage = `User Store Error [doLogin]: ${e.message}`;
      console.log(errorMessage);
      throw Error(errorMessage);
    }
  },
  doRegistration: async (
    profession,
    firstName,
    lastName,
    email,
    password,
    password2,
    role
  ) => {
    try {
      let registrationResult = await postRegistration({
        profession,
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        password2,
        role,
      });
      console.log(console.log("doRegistration.response", registrationResult));
      return registrationResult;
    } catch (e) {
      const errorMessage = `User Store Error [doRegistration]: ${e.message}`;
      console.log("doRegistration", errorMessage);
      throw Error(errorMessage);
    }
  },
  doUserUpdate: async (data) => {
    try {
      let fetchResult = await updatePractitioner({
        data
      });
      return fetchResult;
    } catch(e) {
      const errorMessage = `Admin Store Error [doAcceptPending]: ${e.message}`;
      console.log("doAcceptPending", errorMessage);
      throw Error(errorMessage);    }
  },
  doFetchUser: async (filters) => {
    try {
      let fetchResult = await findUserProfile({
        filters,
      });
      return fetchResult;
    } catch (e) {
      const errorMessage = `User Store Error [doFetchUser]: ${e.message}`;
      console.log("doFetchUser", errorMessage);
      throw Error(errorMessage);
    }
  },
  doUpdateProfilePhoto: async (filters) => {
    console.log();
    try {
      let fetchResult = await findPractitionerProfile({
        filters,
      });
      set({ avatar : fetchResult.result[0].photo });

      return fetchResult;
    } catch (e) {
      const errorMessage = `User Store Error [doFetchUser]: ${e.message}`;
      console.log("doFetchUser", errorMessage);
      throw Error(errorMessage);
    }
  },
  doAddCrmEmail: async (
    data
  ) => {
    try {
      let addcrmResult = await postAddCrm({
        data,
      });
      console.log(console.log("addcrmResult.response", addcrmResult));
      return addcrmResult
    } catch (e) {
     
    }
  },

  doProcessCheckout: async (
    data
  ) => {
    try {
      let addcrmResult = await postPayment({
        data,
      });
      console.log(console.log("addcrmResult.response", addcrmResult));
      return addcrmResult
    } catch (e) {
      console.log('error:',e);
    }
  },

});

export const useUserStore = create(
  devtools(
    immer(
      persist(store, {
        name: "user-store",
        storage: createJSONStorage(() => localStorage),
      })
    )
  )
);
