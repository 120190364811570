import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import LockIcon from "../../assets/images/lock.svg";
import useProfile from "../../hooks/useProfile";
import useLoader from "../../hooks/useLoader";
import useUserProfile from "../../hooks/useUserProfile";
import { useUserStore } from "../../store/userStore";
import {
  inflateValuesFromSelect,
  sanitizeUserUpdateParams,
  zeroPadDateString,
} from "../../utils/utilityFunctions";
import { monthsOptions, stateOptions } from "../../utils/globals";
import { BirthDate, Password } from "./forms/form";
import usePasswordEye from "../../hooks/usePasswordEye";
import Editor from "../../components/QuillEditor";
import { updateUserProfile } from "../../api";

function ClientProfile() {
  const { _id } = useProfile();

  const { profile: userProfile, errorMessage: fetchMessage } = useUserProfile(
    _id,
    "client"
  );

  //Local state.
  const [localState, setLocalState] = useState({
    idtype: null,
    registrationnumber: null,
    verificationphoto: null,
    bankaccountname: null,
    bankaccountnumber: null,
    bsb: null,
    hourlyrate: null,
    sessionduration: null,
  });
  const stateChangeHandler = (key, value) => {
    setLocalState((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const setProfile = useUserStore((state) => state.setProfile);

  //Submit form handler
  const submitForm = async () => {
    showLoader("Saving changes...");
    let params = sanitizeUserUpdateParams(localState);
    delete params._id;
    const result = await updateUserProfile(params);
    closeMessages();
    if (result.error) {
      showError(result.message);
    } else {
      setProfile(result.result);
      showMessage("Changes have been saved successfully.");
    }
  };

  useEffect(() => {
    if (fetchMessage) showError(fetchMessage);
  }, [fetchMessage]);

  useEffect(() => {
    if (userProfile) {
      setLocalState(userProfile);
    }
  }, [userProfile]);

  const { showLoader, showMessage, closeMessages, showError } = useLoader();

  const { icon, clickHandler, isReadable } = usePasswordEye();

  return (
    <DashboardLayout>
      <div className="client-settings">
        <div className="title-bar">
          <h1>My Profile</h1>
          <div>
            <DateTimePill></DateTimePill>
          </div>
        </div>

        {userProfile ? (
          <div className="dash-client-settings-container">
            <div className="dash-client-settings-left">
              <div className="personal-details-box">
                <div className="personal-details-form">
                  <div className="input-wrapper-50">
                    <div className="personal-details-form-label">
                      First Name
                    </div>
                    <input
                      disabled
                      className="personal-details-form-input"
                      value={localState.firstname ? localState.firstname : ""}
                    ></input>
                  </div>
                  <div className="input-wrapper-50">
                    <div className="personal-details-form-label">Last Name</div>
                    <input
                      disabled
                      className="personal-details-form-input"
                      value={localState.lastname ? localState.lastname : ""}
                    ></input>
                  </div>
                  <div className="input-wrapper-100">
                    <div className="personal-details-form-label">Email</div>
                    <input
                      disabled
                      className="personal-details-form-input"
                      value={localState.email ? localState.email : ""}
                      onChange={(e) => {
                        stateChangeHandler("email", e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="input-wrapper-50">
                    <div className="personal-details-form-label">
                      Preferred Name
                    </div>
                    <input
                      className="personal-details-form-input"
                      placeholder="What's your nickname?"
                      value={localState.nickname ? localState.nickname : ""}
                      onChange={(e) => {
                        stateChangeHandler("nickname", e.target.value);
                      }}
                    ></input>
                    <div className="personal-details-form-input-checkbox">
                      <input
                        type="checkbox"
                        checked={
                          localState.usenickname
                            ? localState.usenickname
                            : false
                        }
                        onClick={(e) => {
                          stateChangeHandler("usenickname", e.target.checked);
                        }}
                      ></input>
                      Use this as my display name
                    </div>
                  </div>

                  <div className="input-wrapper-60">
                    <div className="personal-details-form-label">
                      State / Post Code
                    </div>
                    <Select
                      classNamePrefix={"postal-select"}
                      options={stateOptions}
                      isSearchable={false}
                      value={
                        localState.state
                          ? inflateValuesFromSelect([localState.state])
                          : null
                      }
                      onChange={(e) => {
                        stateChangeHandler("state", e.value);
                      }}
                    ></Select>
                  </div>

                  <div className="input-wrapper-30">
                    <div className="personal-details-form-label">&nbsp;</div>
                    <input
                      className="personal-details-form-input"
                      placeholder="Post Code"
                      value={localState.postcode ? localState.postcode : ""}
                      onChange={(e) => {
                        stateChangeHandler("postcode", e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="input-wrapper-10"></div>

                  <BirthDate
                    date={zeroPadDateString(localState.birthdate)}
                    callback={(value) => {
                      if (value) stateChangeHandler("birthdate", value);
                    }}
                  ></BirthDate>

                  <div className="input-wrapper-100">
                    <div className="personal-details-form-label">
                      Phone Number
                    </div>
                    <input
                      placeholder="How would you like be called?"
                      className="personal-details-form-input"
                      value={
                        localState.phonenumber ? localState.phonenumber : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler("phonenumber", e.target.value);
                      }}
                    ></input>
                  </div>

                  <div className="input-wrapper-50">
                    <div className="personal-details-form-label">
                      Emergency Contact
                    </div>
                    <input
                      placeholder="Name"
                      className="personal-details-form-input"
                      value={
                        localState.emergencycontactname
                          ? localState.emergencycontactname
                          : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler(
                          "emergencycontactname",
                          e.target.value
                        );
                      }}
                    ></input>
                  </div>

                  <div className="input-wrapper-50">
                    <div className="personal-details-form-label">&nbsp;</div>
                    <input
                      placeholder="Phone Number"
                      className="personal-details-form-input"
                      value={
                        localState.emergencycontactnumber
                          ? localState.emergencycontactnumber
                          : ""
                      }
                      onChange={(e) => {
                        stateChangeHandler(
                          "emergencycontactnumber",
                          e.target.value
                        );
                      }}
                    ></input>
                  </div>

                  <div className="input-wrapper-100">
                    <div className="personal-details-form-label">Password</div>
                    <Password
                      onChange={(value) => {
                        stateChangeHandler("password", value);
                      }}
                      value={localState.password ? localState.password : ""}
                      isReadable={isReadable}
                      clickHandler={clickHandler}
                      icon={icon}
                    />
                  </div>
                </div>
              </div>

              <div className="description-box">
                <h2>Say something about yourself</h2>
                <Editor
                  placeholder={""}
                  value={localState.description ? localState.description : ""}
                  setValue={(value) => {
                    stateChangeHandler("description", value);
                  }}
                ></Editor>
              </div>

              <div className="credit-card-box">
                <h2>
                  <img src={LockIcon}></img>Credit Card Information
                </h2>
                <div className="credit-card-widget">
                  <div className="credit-card-widget-inner">
                    <span className="credit-card-widget-text">
                      <b>Your credit card details are protected. </b>
                    </span>
                    <span className="credit-card-info-text">
                      We partner with Airpay so that your card details are safe.
                      Lumilife will not have access to your card info.
                    </span>
                  </div>
                  <div className="add-new-card lumi-btn">Add New Card</div>
                </div>
              </div>
              <button className="save-button lumi-btn" onClick={submitForm}>
                Save Changes
              </button>
              <div className="client-settings-separator"></div>
            </div>
            <div className="dash-client-settings-right"></div>
          </div>
        ) : (
          <div>Loading Profile Data...</div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default ClientProfile;
