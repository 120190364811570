import { useState } from "react";

function useMatchingFrameA() {
    const [frameA, setframeA] = useState(true);
    const toggleFrameA = () => {
        setframeA(prevCheck => !prevCheck);
    }
    const [selectA1, setSelectA1] = useState(false);
    const toggleSelectA1 = () => {
        setSelectA3(false);
        setSelectA2(false);
        setSelectA1(prevCheck => !prevCheck);
    }
    const [selectA2, setSelectA2] = useState(false);
    const toggleSelectA2 = () => {
        setSelectA1(false);
        setSelectA2(prevCheck => !prevCheck);
        setSelectA3(false);
    }
    const [selectA3, setSelectA3] = useState(false);
    const toggleSelectA3 = () => {
        setSelectA1(false);
        setSelectA2(false);
        setSelectA3(prevCheck => !prevCheck);
    }

    return {
        toggleFrameA,
        frameA,
        toggleSelectA1,
        selectA1,
        toggleSelectA2,
        selectA2,
        toggleSelectA3,
        selectA3,
    }
}

export default useMatchingFrameA;
