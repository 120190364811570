function FrameYCard({
  toggleFrameY,
  frameY,
  toggleFrameZ,
  frameZ,
  selectY1, 
  toggleSelectY1,
  selectY2, 
  toggleSelectY2,
  selectY3, 
  toggleSelectY3,
  selectY4, 
  toggleSelectY4,
  selectY5, 
  toggleSelectY5,
  selectY6, 
  toggleSelectY6,
  selectY7, 
  toggleSelectY7,
  updateProgressStatus,
  toggleAnswers,
  answers
})
{
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
     <h1>What are your preferred days for therapy:</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          
          </div>
          <div className="description"></div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectY1 ? "li-selected" : ""} onClick={toggleSelectY1} id='preferred-days-for-therapy-monday'>Monday</li>
            <li className={selectY2 ? "li-selected" : ""} onClick={toggleSelectY2} id='preferred-days-for-therapy-tuesday'>Tuesday</li>
            <li className={selectY3 ? "li-selected" : ""} onClick={toggleSelectY3} id='preferred-days-for-therapy-wednesday'>Wednesday</li>
            <li className={selectY4 ? "li-selected" : ""} onClick={toggleSelectY4} id='preferred-days-for-therapy-thursday'>Thursday</li>
            <li className={selectY5 ? "li-selected" : ""} onClick={toggleSelectY5} id='preferred-days-for-therapy-friday'>Friday</li>
            <li className={selectY6 ? "li-selected" : ""} onClick={toggleSelectY6} id='preferred-days-for-therapy-saturday'>Saturday</li>
            <li className={selectY7 ? "li-selected" : ""} onClick={toggleSelectY7} id='preferred-days-for-therapy-sunday'>Sunday</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
            if (
              selectY1 ||
              selectY2 ||
              selectY3 ||
              selectY4 ||
              selectY5 ||
              selectY6 ||
              selectY7
            ) {
              updateProgressStatus()
              toggleFrameY();
              toggleFrameZ();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameYCard;
