import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  pendingPractitioner,
  updatePractitioner
} from "../api";
import { getFilterType } from "../helpers/practitionerHelper";
import { createJSONStorage } from "zustand/middleware";

const initialState = {
  nameOrder: [],
};

const store = (set, get) => ({
  //state
  ...initialState,
  doReset: () => set(initialState),
  doFetchPendingApproval: async (filters) => {
    try {
      let fetchResult = await pendingPractitioner({
        filters
      });
      return fetchResult;
    } catch (e) {
      console.log(e);
    }
  },
  doFetchPendingDocumentApproval: async (filters) => {
    try {
      let fetchResult = await pendingPractitioner({
        filters
      });
      return fetchResult;
    } catch (e) {
      console.log(e);
    }
  },
  doAcceptPending: async (data) => {
    try {
      console.log('paramsData:::', data);
      let fetchResult = await updatePractitioner({
        data
      });
      return fetchResult;
    } catch(e) {
      const errorMessage = `Admin Store Error [doAcceptPending]: ${e.message}`;
      console.log("doAcceptPending", errorMessage);
      throw Error(errorMessage);    }
  }
});

export const useAdminStore = create(devtools(immer(store)));
