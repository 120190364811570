import { useEffect, useRef, useState, useCallback } from "react";

function useFaqsHook() {
  
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);
  useEffect(() => {
    contentRef1.current.style.maxHeight = active1
      ? `${contentRef1.current.scrollHeight}px`
      : "0px";
  }, [contentRef1, active1]);
  useEffect(() => {
    contentRef2.current.style.maxHeight = active2
      ? `${contentRef2.current.scrollHeight}px`
      : "0px";
  }, [contentRef2, active2]);
  useEffect(() => {
    contentRef3.current.style.maxHeight = active3
      ? `${contentRef3.current.scrollHeight}px`
      : "0px";
  }, [contentRef3, active3]);
  useEffect(() => {
    contentRef4.current.style.maxHeight = active4
      ? `${contentRef4.current.scrollHeight}px`
      : "0px";
  }, [contentRef4, active4]);

  const toggleAccordion1 = () => {
    setActive1(!active1);
  };
  const toggleAccordion2 = () => {
    setActive2(!active2);
  };
  const toggleAccordion3 = () => {
    setActive3(!active3);
  };
  const toggleAccordion4 = () => {
    setActive4(!active4);
  };

  return {
    toggleAccordion1,
    toggleAccordion2,
    toggleAccordion3,
    toggleAccordion4,
    active1,
    active2,
    active3,
    active4,
    contentRef1,
    contentRef2,
    contentRef3,
    contentRef4,
  };

}

export default useFaqsHook;
