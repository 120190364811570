import { useState } from "react";

function useMatchingFrameV() {

    const [frameV, setframeV] = useState(false);
    const toggleFrameV = () => {
        setframeV(prevCheck => !prevCheck);
    }
    const [frameW, setframeW] = useState(false);
    const toggleFrameW = () => {
        setframeW(prevCheck => !prevCheck);
    }
    const [selectV1, setSelectV1] = useState(false);
    const toggleSelectV1 = () => {
        setSelectV1(prevCheck => !prevCheck);
    }
    const [selectV2, setSelectV2] = useState(false);
    const toggleSelectV2 = () => {
        setSelectV2(prevCheck => !prevCheck);
    }
    const [selectV3, setSelectV3] = useState(false);
    const toggleSelectV3 = () => {
        setSelectV3(prevCheck => !prevCheck);
    }
    const [selectV4, setSelectV4] = useState(false);
    const toggleSelectV4 = () => {
        setSelectV4(prevCheck => !prevCheck);
    }
    const [selectV5, setSelectV5] = useState(false);
    const toggleSelectV5 = () => {
        setSelectV5(prevCheck => !prevCheck);
    }
    const [selectV6, setSelectV6] = useState(false);
    const toggleSelectV6 = () => {
        setSelectV6(prevCheck => !prevCheck);
    }
    const [selectV7, setSelectV7] = useState(false);
    const toggleSelectV7 = () => {
        setSelectV7(prevCheck => !prevCheck);
    }
    const [selectV8, setSelectV8] = useState(false);
    const toggleSelectV8 = () => {
        setSelectV8(prevCheck => !prevCheck);
    }

    return {
        toggleFrameV,
        frameV,
        toggleFrameW,
        frameW,
        toggleSelectV1,
        selectV1,
        toggleSelectV2,
        selectV2,
        toggleSelectV3,
        selectV3,
        toggleSelectV4,
        selectV4,
        toggleSelectV5,
        selectV5,
        toggleSelectV6,
        selectV6,
        toggleSelectV7,
        selectV7,
        toggleSelectV8,
        selectV8,
    }
}

export default useMatchingFrameV;
