import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import useProfile from "../../hooks/useProfile";
import usePractitioner from "../../hooks/usePractitioner";
import PractitionerCard from "./components/PractitionerCard";
import { useNavigate } from "react-router-dom";

function AdminPractitionerList({ Component: component }) {
  const { role } = useProfile();
  const { nameOrder, doFetch } = usePractitioner();
  const redirectToAdmin = (id) => navigate(`/admin/practitioner/profilepage/${id}`);

  const navigate = useNavigate();


  console.log('nameOrder::',nameOrder);

  return (
    <>
      <DashboardLayout>
        <div className="admin-practitioner-dashboard-home">
          <div className="title-bar">
            <h1><span>List of Practitioners <span className="pending-approval">({nameOrder ? nameOrder.length : 0})</span></span></h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>
          <div className="yellow-box">
            {nameOrder && nameOrder.length > 0 ? (
              nameOrder.map((item) => {
                return <PractitionerCard 
                  key={item._id}
                  firstname={item.firstname} 
                  lastname={item.lastname}
                  role={item.role}
                  status={item.status}
                  message={item.message}
                  specialties={item.specialties}
                  id={item._id}
                  redirectToAdmin={redirectToAdmin}
                  photo={item.photo}
                  lastactivity={item.lastactivity}
                />
              })
            ) : (
              <div align="center"> No matches.</div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default AdminPractitionerList;
