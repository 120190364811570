import { useState } from "react";

function useMatchingFrameX() {

    const [frameX, setframeX] = useState(false);
    const toggleFrameX = () => {
        setframeX(prevCheck => !prevCheck);
    }
    const [selectX1, setSelectX1] = useState(false);
    const toggleSelectX1 = () => {
        setSelectX1(prevCheck => !prevCheck);
        setSelectX2(false);
        setSelectX3(false);
        setSelectX4(false);
        setSelectX5(false);
        setSelectX6(false);
        setSelectX7(false);
    }
    const [selectX2, setSelectX2] = useState(false);
    const toggleSelectX2 = () => {
        setSelectX1(false);
        setSelectX2(prevCheck => !prevCheck);
        setSelectX3(false);
        setSelectX4(false);
        setSelectX5(false);
        setSelectX6(false);
        setSelectX7(false);
    }
    const [selectX3, setSelectX3] = useState(false);
    const toggleSelectX3 = () => {
        setSelectX1(false);
        setSelectX2(false);
        setSelectX3(prevCheck => !prevCheck);
        setSelectX4(false);
        setSelectX5(false);
        setSelectX6(false);
        setSelectX7(false);
    }
    const [selectX4, setSelectX4] = useState(false);
    const toggleSelectX4 = () => {
        setSelectX1(false);
        setSelectX2(false);
        setSelectX3(false);
        setSelectX4(prevCheck => !prevCheck);
        setSelectX5(false);
        setSelectX6(false);
        setSelectX7(false);
    }
    const [selectX5, setSelectX5] = useState(false);
    const toggleSelectX5 = () => {
        setSelectX1(false);
        setSelectX2(false);
        setSelectX3(false);
        setSelectX4(false);
        setSelectX5(prevCheck => !prevCheck);
        setSelectX6(false);
        setSelectX7(false);
    }
    const [selectX6, setSelectX6] = useState(false);
    const toggleSelectX6 = () => {
        setSelectX1(false);
        setSelectX2(false);
        setSelectX3(false);
        setSelectX4(false);
        setSelectX5(false);
        setSelectX6(prevCheck => !prevCheck);
        setSelectX7(false);
    }
    const [selectX7, setSelectX7] = useState(false);
    const toggleSelectX7 = () => {
        setSelectX1(false);
        setSelectX2(false);
        setSelectX3(false);
        setSelectX4(false);
        setSelectX5(false);
        setSelectX6(false);
        setSelectX7(prevCheck => !prevCheck);
    }

    return {
        toggleFrameX,
        frameX,
        toggleSelectX1,
        selectX1,
        toggleSelectX2,
        selectX2,
        toggleSelectX3,
        selectX3,
        toggleSelectX4,
        selectX4,
        toggleSelectX5,
        selectX5,
        toggleSelectX6,
        selectX6,
        toggleSelectX7,
        selectX7,
    }
}

export default useMatchingFrameX;
