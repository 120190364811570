import { useState } from "react";

function useProgressStatus() {

  const [progressStatus, setProgressStatus] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const totalProgress = 13;

  const updateProgressStatus = () => {
    setProgressStatus(progressStatus + 1);
    setPercentage(`${Math.round((progressStatus / totalProgress) * 100)}%`)
  }

    return {
        updateProgressStatus,
        percentage
    }
}

export default useProgressStatus;
