import Footer from "../../components/Footer";
import Header from "../../components/Header";
import QuoteBox from "../../components/QuoteBox";
import Doctor from "../../assets/images/dr_1.jpg";
import Pin from "../../assets/images/pin.png";
import RatingWidget from "../../components/RatingWidget";
import HIF from "../../assets/images/logo_hif.png";
import AU from "../../assets/images/logo_australianunity.png";
import { Link, useParams } from "react-router-dom";
import usePractitionerProfile from "../../hooks/usePractitionerProfile";
import useLoader from "../../hooks/useLoader";
import { stripHTMLTags, capitalize } from "../../helpers/practitionerHelper";
import DefaultAvatar from "../../assets/images/icon_default_avatar.png";



function PractitionerProfilePage() {
  const maxrating = 5;
  const reviewscount = 24;
  const { id } = useParams();
  const { profile, errorMessage } = usePractitionerProfile(id);
  const { showLoader, showMessage, closeMessages, showError } = useLoader();

  if (errorMessage) {
    closeMessages();
    showMessage(errorMessage);
  }

  console.log('profile:::', profile);

  return (
    <div className="practitioner-profile-page">
      <Header></Header>

      {profile && (
        <div className="profile-page">
          <div className="profile-header">
            <img className="photo" src={profile.photo ? profile.photo : DefaultAvatar}></img>
            <div className="details">
              <h1>{`${profile.firstname} ${profile.lastname}`}</h1>
              <h2>{profile.position} </h2>
              <div className="location">
                <img src={Pin}></img>
                {profile.consultationlocation}
              </div>
            </div>
          </div>
          <div className="profile-body">
            <div className="left">
              <div className="more-info">
                <RatingWidget
                  rating={profile.rating}
                  maxrating={maxrating}
                ></RatingWidget>
                <Link className="reviews-link">
                  {profile.reviewscount} Verified Reviews
                </Link>
                <div className="sessions-count">
                  {profile.sessionscount} Sessions
                </div>
                <button className="lumi-btn book-now">Book a Session</button>

                <span className="note">
                  You are allowed to talk directly to {profile.firstname}
                </span>

                <button className="lumi-btn send-message">Send Message</button>
              </div>
            </div>
            <div className="right">
              <h3 className="about-me">About Me</h3>
              <div dangerouslySetInnerHTML={{ __html: profile.message}} />
              <h3 className="about-me">Consultation Type</h3>
              <p>
                <ul>
                  { profile 
                   ? profile.consultationcategories.map((item) => {
                    return <li key={item}>{item}</li>;
                  }) : ""}
                </ul>
              </p>
              <h3 className="about-me">Specialties</h3>
              <p>
                <ul>
                  { profile 
                   ? profile.specialisedcategories.map((item) => {
                    return <li key={item}>{item}</li>;
                  }) : ""}
                </ul>
              </p>

              <h3 className="right-one">Am I the right one for you?</h3>
              <p>{profile.whyme}</p>
              <div className="separator"></div>
              <div className="membership-medicare">
                <div className="memberships-left">
                  <h5>Memberships</h5>
                  <div className="icons">
                    <img src={HIF}></img>
                  </div>
                </div>
                <div className="memberships-right">
                  <h5>Medicare</h5>
                  <div className="icons">
                    <img src={HIF}></img>
                    <img src={AU}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <QuoteBox></QuoteBox>
      <Footer></Footer>
    </div>
  );
}

export default PractitionerProfilePage;
