import { Link } from "react-router-dom";
import Asterisk from "../../../assets/images/sidebar_icon_asterisk.png";

function SideBarMenuItem({ name, icon, to, action, active, className }) {
  return (
    <li className={className} onClick={action}>
      <Link className={active ? "active" : ""} to={to}>
        <img className="sidebar-navigation-icon" src={icon}></img>
        <span>{name}</span>
        <img className="sidebar-navigation-asterisk" src={Asterisk}></img>
      </Link>
    </li>
  );
}

export default SideBarMenuItem;
