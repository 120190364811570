import { useState } from "react";

function useMatchingFrameO() {

    const [frameO, setframeO] = useState(false);
    const toggleFrameO = () => {
        setframeO(prevCheck => !prevCheck);
    }
    const [selectO1, setSelectO1] = useState(false);
    const toggleSelectO1 = () => {
        setSelectO1(prevCheck => !prevCheck);
    }
    const [selectO2, setSelectO2] = useState(false);
    const toggleSelectO2 = () => {
        setSelectO2(prevCheck => !prevCheck);
    }
    const [selectO3, setSelectO3] = useState(false);
    const toggleSelectO3 = () => {
        setSelectO3(prevCheck => !prevCheck);
    }
    const [selectO4, setSelectO4] = useState(false);
    const toggleSelectO4 = () => {
        setSelectO4(prevCheck => !prevCheck);
    }
    const [selectO5, setSelectO5] = useState(false);
    const toggleSelectO5 = () => {
        setSelectO5(prevCheck => !prevCheck);
    }
    const [selectO6, setSelectO6] = useState(false);
    const toggleSelectO6 = () => {
        setSelectO6(prevCheck => !prevCheck);
    }
    const [selectO7, setSelectO7] = useState(false);
    const toggleSelectO7 = () => {
        setSelectO7(prevCheck => !prevCheck);
    }
    const [selectO8, setSelectO8] = useState(false);
    const toggleSelectO8 = () => {
        setSelectO8(prevCheck => !prevCheck);
    }
    const [selectO9, setSelectO9] = useState(false);
    const toggleSelectO9 = () => {
        setSelectO9(prevCheck => !prevCheck);
    }
    const [selectO10, setSelectO10] = useState(false);
    const toggleSelectO10 = () => {
        setSelectO10(prevCheck => !prevCheck);
    }
    const [selectO11, setSelectO11] = useState(false);
    const toggleSelectO11 = () => {
        setSelectO11(prevCheck => !prevCheck);
    }

    return {
        toggleFrameO,
        frameO,
        toggleSelectO1,
        selectO1,
        toggleSelectO2,
        selectO2,
        toggleSelectO3,
        selectO3,
        toggleSelectO4,
        selectO4,
        toggleSelectO5,
        selectO5,
        toggleSelectO6,
        selectO6,
        toggleSelectO7,
        selectO7,
        toggleSelectO8,
        selectO8,
        toggleSelectO9,
        selectO9,
        toggleSelectO10,
        selectO10,
        toggleSelectO11,
        selectO11,
    }
}

export default useMatchingFrameO;
