import { executeMultipleQuestions, tagQuestionAsDone } from "../../../helpers/practitionerHelper";

function FrameNCard({
  toggleFrameN,
  frameN,
  toggleFrameE,
  frameE,
  toggleFrameO,
  frameO,
  toggleFrameP,
  frameP,
  toggleFrameQ,
  frameQ,
  toggleFrameR,
  frameR,
  toggleFrameS,
  frameS,
  toggleFrameT,
  frameT,
  selectN1, 
  toggleSelectN1,
  selectN2, 
  toggleSelectN2,
  selectN3, 
  toggleSelectN3,
  selectN4, 
  toggleSelectN4,
  selectN5, 
  toggleSelectN5,
  selectN6, 
  toggleSelectN6,
  selectN7, 
  toggleSelectN7,
  selectN8, 
  toggleSelectN8,
  selectN9, 
  toggleSelectN9,
  toggleFrameMultiple,
  frameMultiple,
}) {
  return (
    <div>
     <h1>I'd like some help with</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
          Relationship, family or parenting issues
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul-followup-questions">
            <li className={selectN1 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN1}>Family conflict/counselling (click this if you are interested in your family attending therapy together)</li>
            <li className={selectN2 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN2}>Relationship conflict</li>
            <li className={selectN3 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN3}>Intimacy concerns</li>
            <li className={selectN4 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN4}>Improving communication</li>
            <li className={selectN5 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN5}>Parenting strategies</li>
            <li className={selectN6 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN6}>Parent / child relationship and conflict</li>
            <li className={selectN7 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN7}>Pre or post natal concerns</li>
            <li className={selectN8 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN8}>Family trauma</li>
            <li className={selectN9 ? "li-selected-followup-questions" : ""} onClick={toggleSelectN9}>Domestic abuse</li>
          </ul>

          <div className="next" onClick={() => {
            if (
              selectN1 ||
              selectN2 ||
              selectN3 ||
              selectN4 ||
              selectN5 ||
              selectN6 ||
              selectN7 ||
              selectN8 ||
              selectN9
            ) {
              toggleFrameN();
              tagQuestionAsDone(frameMultiple);
              if (frameMultiple.length > 0) {
                eval(executeMultipleQuestions(frameMultiple));
              } else {
                toggleFrameE();
              }
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameNCard;
