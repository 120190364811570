import FoundersImage from "../../../assets/images/homepage_founders.png";

function Founders() {
  return (
    <div className="flex desktop:flex-row items-center m-20 tablet:mx-8 gap-16 font-body tablet:flex-col">
      <div className="flex flex-1 flex-col gap-8">
        <div className="flex flex-col">
          <h3 className="text-3xl text-lumi-rose font-semibold">
            Meet the founders
          </h3>
          <h3 className="font-summer text-lumi-yellow text-[86px] font-light">
            Jodie & Joni
          </h3>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold">
            LumiLife was born from our personal struggles to find the right
            support during some of the most challenging times in our lives.
          </p>
          <p className="font-light">
            We understand that every woman’s journey is unique, and the right
            support can be life-changing—it certainly was for us.
          </p>
          <p className="font-light">
            Our vision is simple: to create a platform where women across
            Australia can easily connect with mental health and wellness
            practitioners who truly understand their needs. LumiLife is here to
            illuminate that path to growth and healing.
          </p>
          <p className="font-light">
            As friends, mothers, and co-founders, we bring our compassion,
            resilience, and determination into every part of LumiLife. This
            isn't just a company to us—it’s a safe space for women to break free
            from feeling stuck and embrace their full potential.
          </p>
        </div>
      </div>
      <div className="flex flex-1 flex-col h-[846px] ">
        <img src={FoundersImage} className="object-contain h-full" />
      </div>
    </div>
  );
}

export default Founders;
