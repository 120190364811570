function FrameLCard({
  toggleFrameL,
  frameL,
  toggleFrameM,
  frameM,
  selectL1, 
  toggleSelectL1,
  selectL2, 
  toggleSelectL2,
  selectL3, 
  toggleSelectL3,
  selectL4, 
  toggleSelectL4,
  selectL5, 
  toggleSelectL5,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
    <h1>What type of support most aligns with you</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          {/* <div className="question">
          Get Matched!
          </div>
          <div className="description">Are you qualified to work with diagnosed mental health conditions or personality disorders?</div> */}
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectL1 ? "li-selected" : ""} onClick={toggleSelectL1} id='talk-based-therapy'>Talk based therapy (psychology/counselling) </li>
            <li className={selectL2 ? "li-selected" : ""} onClick={toggleSelectL2} id='supporting-the-mind-body-through-holistic-therapies'>Supporting the mind & body through holistic therapies</li>
            <li className={selectL3 ? "li-selected" : ""} onClick={toggleSelectL3} id='supporting-the-mind-body-with-the-support-of-a-qualified-dietitian-or-nutritionist'>Supporting the mind & body with the support of a qualified dietitian or nutritionist</li>
            <li className={selectL4 ? "li-selected" : ""} onClick={toggleSelectL4} id='coaching-to-help-me-set-and-achieve-goals-either-personally-professionally-or-financially'>Coaching to help me set and achieve goals, either personally, professionally or financially</li>
            <li className={selectL5 ? "li-selected" : ""} onClick={toggleSelectL5} id='unsure'>Unsure</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
              if (
              selectL1 ||
              selectL2 ||
              selectL3 ||
              selectL4 ||
              selectL5
            ) {
              updateProgressStatus()
              toggleFrameL();
              toggleFrameM();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameLCard;
