import { useEffect } from "react";
import { useUserStore } from "../store/userStore";

function useRoleAccessCheck(role) {
  const userRole = useUserStore((state) => state.profile.role);

  useEffect(() => {
    //Check if role prop is defined. If not do nothing, it means page is open for all.
    if (role && userRole) {
      if (role !== userRole) {
        window.location = `/${userRole}/dashboard`;
      }
    }
  }, [role, userRole]);
}

export default useRoleAccessCheck;
