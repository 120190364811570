const HeroAuthSection = ({ children }) => {
  return (
    <div className="flex justify-center mt-4">
      <div className="tablet:flex-col flex desktop:flex-row flex-1 tablet:gap-8 ">
        <div className="bg-hero bg-cover bg-center flex flex-1 flex-col align-center justify-center rounded-tr-[364px] tablet:rounded-tr-[128px] h-[901px] tablet:py-24 tablet:mr-8 medium:h-[700px]">
          <div className="flex flex-1 flex-col justify-center gap-4 px-24 tablet:px-8">
            <h1 className="font-title text-white tablet:text-4xl text-7xl font-medium tablet:2xl">
              Connect with women who need you
            </h1>
            <p className="font-body text-white text-2xl tablet:text-lg">
              We’re looking for a range of mental health, coaching, holistic,
              and nutrition professionals to join us at LumiLife.
            </p>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default HeroAuthSection;
