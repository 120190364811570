import Phones from "../../../assets/images/phones.png";
import BGCircles from "../../../assets/images/bg_circles.png";

function Benefits() {
  return (
    <>
      <div id="benefits">
        <div className="row pb-4">
          <div id="phones" className="col-5">
            <img id="phones" src={Phones}></img>
          </div>
          <div id="content" className="col-7">
            <div className="content-box">
              <h1>Why choose LumiLife</h1>

              <ul className="list">
                <li>
                  <div className="num">1</div>
                  <div className="text">
                    A dedicated platform empowering you to heal, grow and thrive
                  </div>
                </li>
                <li>
                  <div className="num">2</div>
                  <div className="text">
                    Find the right support in minutes. Say goodbye to overwhelm
                  </div>
                </li>
                <li>
                  <div className="num">3</div>
                  <div className="text">
                    Explore a full range of Australian-based wellness practitioners
                  </div>
                </li>
                <li>
                  <div className="num">4</div>
                  <div className="text">
                    Our smart matching connects you with the right expert
                  </div>
                </li>
                <li>
                  <div className="num">5</div>
                  <div className="text">
                    Access from anywhere in Australia — online or
                    in-person
                  </div>
                </li>
                <li>
                  <div className="num">6</div>
                  <div className="text">
                    Medicare and insurance rebates available where applicable
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="benefits-mobile">
        <div id="phone-container">
          <img id="phones" src={Phones}></img>

          <div id="content">
            <div className="content-box">
              <h1>Why choose LumiLife</h1>

              <ul className="list">
                <li>
                  <div className="num">1</div>
                  <div className="text">
                    A dedicated platform empowering you to heal, grow and thrive
                  </div>
                </li>
                <li>
                  <div className="num">2</div>
                  <div className="text">
                    Find the right support in minutes. Say goodbye to overwhelm
                  </div>
                </li>
                <li>
                  <div className="num">2</div>
                  <div className="text">
                    Explore a full range of Australian-based wellness practitioners
                  </div>
                </li>
                <li>
                  <div className="num">3</div>
                  <div className="text">
                    Our smart matching connects you with the right expert
                  </div>
                </li>
                <li>
                  <div className="num">4</div>
                  <div className="text">
                    Access from anywhere in Australia — online or
                    in-person
                  </div>
                </li>
                <li>
                  <div className="num">5</div>
                  <div className="text">
                    Medicare and insurance rebates available where applicable
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefits;
