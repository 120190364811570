import Doctor from "../../../assets/images/dr_1.jpg";
import { Link } from "react-router-dom";
import ContactIcon from "../../../assets/images/icon_small_contact.png";
import EmailIcon from "../../../assets/images/icon_small_email.png";
import CalendarIcon from "../../../assets/images/icon_calendar.png";
import Certifications from "../../PractitionerCerts/components/CertificationCard";


function PendingApprovalCard({
  firstname,
  lastname,
  position,
  document_status,
  doAcceptPending,
  id,
  handler,
  showLoader,
  showMessage,
  closeMessages,
  showError,
  accepted,
  hasAccepted,
  redirectToAdmin,
  certificationuploads,
  picc,
  icf,
  daa,
  wwc,
}) {
  let documents = [certificationuploads, picc, icf, daa, wwc];
  let allDocuments = [].concat(...documents);
  return (
    <>
      <div className="session-card">
        <div className="row-container">
          <div className="half ul-list">
            <div
              onClick={() => {
                redirectToAdmin(id);
              }}
            >
              <img className="photo" src={Doctor}></img>
              <h3>
                Dr. {firstname} {lastname}
              </h3>
              <h4>{position}</h4>
            </div>
            {/* <p><a href={certificationuploads}><img src={certificationuploads}></img></a></p> */}
            {allDocuments && allDocuments.length > 0 ? (
              allDocuments.map((item) => {
                if (item.pending_status != "pending") {
                  return <Certifications isAdmin={true} cert={item.cert} />;
                }
              })
            ) : (
              <div align="center"> No certifications uploaded. </div>
            )}
          </div>
        </div>
        <div className="row-container mt-4">
          {/* <div className="half">
            <button onClick={async () => {}} className="lumi-btn join-call">
              Accept
            </button>
          </div> */}
        </div>
        <div className="row-container mt-4">
          <div className="half">
            {/* <Link>Document Status: {document_status}</Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingApprovalCard;
