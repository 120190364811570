import { useState } from "react";
import { useFunnelStore } from "../store/funnelStore"; // new


function useMatchingFrameB() {

    const doFetchFunnel = useFunnelStore(
        (state) => state.doFetchFunnelAnswers
    );

    const [stateArray, setStateArray] = useState([]);

    const funnelAnswers = useFunnelStore((state) => state.funnelAnswers); // new
    
    const [frameB, setframeB] = useState(false);
    const toggleFrameB = () => {
        setframeB(prevCheck => !prevCheck);
    }
    const [frameE, setframeE] = useState(false);
    const toggleFrameE = () => {
        setframeE(prevCheck => !prevCheck);
        setframeB(false);
    }

    const [selectB1, setSelectB1] = useState(false);
    const toggleSelectB1 = (e) => {
        setSelectB1(prevCheck => !prevCheck);
        // doFetchFunnel([e.target.innerHTML]) // new
    }
    const [selectB2, setSelectB2] = useState(false);
    const toggleSelectB2 = (e) => {
        setSelectB2(prevCheck => !prevCheck);
    }
    const [selectB3, setSelectB3] = useState(false);
    const toggleSelectB3 = (e) => {
        setSelectB3(prevCheck => !prevCheck);
    }
    const [selectB4, setSelectB4] = useState(false);
    const toggleSelectB4 = () => {
        setSelectB4(prevCheck => !prevCheck);
    }
    const [selectB5, setSelectB5] = useState(false);
    const toggleSelectB5 = () => {
        setSelectB5(prevCheck => !prevCheck);
    }
    const [selectB6, setSelectB6] = useState(false);
    const toggleSelectB6 = () => {
        setSelectB6(prevCheck => !prevCheck);
    }
    const [selectB7, setSelectB7] = useState(false);
    const toggleSelectB7 = () => {
        setSelectB7(prevCheck => !prevCheck);
    }
    const [selectB8, setSelectB8] = useState(false);
    const toggleSelectB8 = () => {
        setSelectB8(prevCheck => !prevCheck);
    }
    const [selectB9, setSelectB9] = useState(false);
    const toggleSelectB9 = () => {
        setSelectB9(prevCheck => !prevCheck);
    }
    const [selectB10, setSelectB10] = useState(false);
    const toggleSelectB10 = () => {
        setSelectB10(prevCheck => !prevCheck);
    }
    const [selectB11, setSelectB11] = useState(false);
    const toggleSelectB11 = () => {
        setSelectB11(prevCheck => !prevCheck);
    }
    const [selectB12, setSelectB12] = useState(false);
    const toggleSelectB12 = () => {
        setSelectB12(prevCheck => !prevCheck);
    }
    const [selectB13, setSelectB13] = useState(false);
    const toggleSelectB13 = () => {
        setSelectB13(prevCheck => !prevCheck);
    }
    const [selectB14, setSelectB14] = useState(false);
    const toggleSelectB14 = () => {
        setSelectB14(prevCheck => !prevCheck);
    }
    const [selectB15, setSelectB15] = useState(false);
    const toggleSelectB15 = () => {
        setSelectB15(prevCheck => !prevCheck);
    }
    const [selectB16, setSelectB16] = useState(false);
    const toggleSelectB16 = () => {
        setSelectB16(prevCheck => !prevCheck);
    }
    const [selectB17, setSelectB17] = useState(false);
    const toggleSelectB17 = () => {
        setSelectB17(prevCheck => !prevCheck);
    }

    return {
        toggleFrameB,
        frameB,
        toggleFrameE,
        frameE,
        toggleSelectB1,
        selectB1,
        toggleSelectB2,
        selectB2,
        toggleSelectB3,
        selectB3,
        toggleSelectB4,
        selectB4,
        toggleSelectB5,
        selectB5,
        toggleSelectB6,
        selectB6,
        toggleSelectB7,
        selectB7,
        toggleSelectB8,
        selectB8,
        toggleSelectB9,
        selectB9,
        toggleSelectB10,
        selectB10,
        toggleSelectB11,
        selectB11,
        toggleSelectB12,
        selectB12,
        toggleSelectB13,
        selectB13,
        toggleSelectB14,
        selectB14,
        toggleSelectB15,
        selectB15,
        toggleSelectB16,
        selectB16,
        toggleSelectB17,
        selectB17,
        funnelAnswers
    }
}

export default useMatchingFrameB;
