import { useState } from "react";

function useMatchingFrameD() {
    const [frameE, setframeE] = useState(false);
    const toggleFrameE = () => {
        setframeE(prevCheck => !prevCheck);
    }

    const [selectD1, setSelectD1] = useState(false);
    const toggleSelectD1 = () => {
        setSelectD1(prevCheck => !prevCheck);
    }
    const [selectD2, setSelectD2] = useState(false);
    const toggleSelectD2 = () => {
        setSelectD2(prevCheck => !prevCheck);
    }
    const [selectD3, setSelectD3] = useState(false);
    const toggleSelectD3 = () => {
        setSelectD3(prevCheck => !prevCheck);
    }
    const [selectD4, setSelectD4] = useState(false);
    const toggleSelectD4 = () => {
        setSelectD4(prevCheck => !prevCheck);
    }
    const [selectD5, setSelectD5] = useState(false);
    const toggleSelectD5 = () => {
        setSelectD5(prevCheck => !prevCheck);
    }
    const [selectD6, setSelectD6] = useState(false);
    const toggleSelectD6 = () => {
        setSelectD6(prevCheck => !prevCheck);
    }
    const [selectD7, setSelectD7] = useState(false);
    const toggleSelectD7 = () => {
        setSelectD7(prevCheck => !prevCheck);
    }
    const [selectD8, setSelectD8] = useState(false);
    const toggleSelectD8 = () => {
        setSelectD8(prevCheck => !prevCheck);
    }
    const [selectD9, setSelectD9] = useState(false);
    const toggleSelectD9 = () => {
        setSelectD9(prevCheck => !prevCheck);
    }
    const [selectD10, setSelectD10] = useState(false);
    const toggleSelectD10 = () => {
        setSelectD10(prevCheck => !prevCheck);
    }
    const [selectD11, setSelectD11] = useState(false);
    const toggleSelectD11 = () => {
        setSelectD11(prevCheck => !prevCheck);
    }
    const [selectD12, setSelectD12] = useState(false);
    const toggleSelectD12 = () => {
        setSelectD12(prevCheck => !prevCheck);
    }
    const [selectD13, setSelectD13] = useState(false);
    const toggleSelectD13 = () => {
        setSelectD13(prevCheck => !prevCheck);
    }
    const [selectD14, setSelectD14] = useState(false);
    const toggleSelectD14 = () => {
        setSelectD14(prevCheck => !prevCheck);
    }
    const [selectD15, setSelectD15] = useState(false);
    const toggleSelectD15 = () => {
        setSelectD15(prevCheck => !prevCheck);
    }
    const [selectD16, setSelectD16] = useState(false);
    const toggleSelectD16 = () => {
        setSelectD16(prevCheck => !prevCheck);
    }
    const [selectD17, setSelectD17] = useState(false);
    const toggleSelectD17 = () => {
        setSelectD17(prevCheck => !prevCheck);
    }

    return {
        toggleFrameE,
        frameE,
        toggleSelectD1,
        selectD1,
        toggleSelectD2,
        selectD2,
        toggleSelectD3,
        selectD3,
        toggleSelectD4,
        selectD4,
        toggleSelectD5,
        selectD5,
        toggleSelectD6,
        selectD6,
        toggleSelectD7,
        selectD7,
        toggleSelectD8,
        selectD8,
        toggleSelectD9,
        selectD9,
        toggleSelectD10,
        selectD10,
        toggleSelectD11,
        selectD11,
        toggleSelectD12,
        selectD12,
        toggleSelectD13,
        selectD13,
        toggleSelectD14,
        selectD14,
        toggleSelectD15,
        selectD15,
        toggleSelectD16,
        selectD16,
        toggleSelectD17,
        selectD17,
    }
}

export default useMatchingFrameD;
