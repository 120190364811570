import { ReactComponent as StarLike } from "../../../assets/icons/star_like.svg";

function BenefitItem({ title, subtitle }) {
  return (
    <div className="flex flex-row gap-4">
      <StarLike className=" min-h-[48px] min-w-[48px] mt-2 tablet:h-[32px] tablet:w-[32px] tablet:mt-0" />
      <div className="flex flex-col">
        <h3 className="font-body text-[40px] font-bold text-lumi-pink py-2">
          {title}
        </h3>
        <p className="text-lumi-body-text font-extralight">{subtitle}</p>
      </div>
    </div>
  );
}

export default BenefitItem;
