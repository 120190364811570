import { professionOptions } from "../../utils/globals";
import Select from "react-select";

export const Profession = ({ value, setValue, professions, setValueText }) => {
    // const lists = [...value, {_id: '001', value: 'selectdefault', label: 'Select New Role'}];
    const select_default = [{_id: null, value: 'selectdefault', label: 'Select New Role'}];
    const lists = [...select_default, ...professions];
  return (
    <>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setValueText(e.target.options[e.target.selectedIndex].text);
        }}
      >
        {lists.map((item) => (
         <option 
            value={item.value} 
            key={item._id}
         >
            {item.label}
        </option>
        ))}
      </select>
    </>
  );
};

// export const Profession = ({ value, setValue, professions, setValueText }) => {
//     return (
//       <>
//         <select
//           value={value}
//           onChange={(e) => {
//             setValue(e.target.value);
//             setValueText(e.target.options[e.target.selectedIndex].text);
//           }}
//           defaultValue={professions[4]}
//         >
//           {professions.map((item) => (
//            <option 
//               value={item.value}
//            >
//               {item.label}
//           </option>
//           ))}
//         </select>
//       </>
//     );
//   };

export const Actions = ({ onAdd }) => (
  <>
    <button onClick={onAdd} className="save-button">Admin Update</button>
  </>
);

export const Update = ({ onAdd, showUpdateRole, setUpdateRole, profile }) => (
    <>
    {profile.position} {}
      <button onClick={onAdd} className="update-button">Update {showUpdateRole}</button>
    </>
  );
  
  

