import useIsMobile from "../../hooks/useIsMobile";
import RatingWidget from "../RatingWidget/";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Avatar from "../../assets/images/icon_default_avatar.png";

function ListViewCard({
  _id,
  firstname,
  lastname,
  toprated,
  photo,
  rating,
  maxrating,
  position,
  message,
  categories: specialties,
  clientage,
  rate,
  currency,
  availability,
  reviewscount,
  verification,
  location,
  meeting,
}) {
  return (
    <div className="practitioner-card-border" key={_id}>
      <div className="practitioner-card">
        <div className="photo-container">
          <img className="photo" src={photo}></img>
          {toprated && <div className="top-badge">TOP RATED</div>}
          <RatingWidget rating={rating} maxrating={maxrating}></RatingWidget>
          <Link className="reviews-link">
            {reviewscount ? reviewscount : 0} Verified Reviews
          </Link>
        </div>
        <div className="more-info">
          <div className="name">{`${firstname} ${lastname}`}</div>
          <div className="position">{position}</div>
          <div className="message">{message}</div>
          <div className="location">
            <h3>Meeting Preferrence</h3>
            <p>{meeting}</p>
          </div>
          <div className="location">
            <h3>location</h3>
            <p>{location}</p>
          </div>
          <div className="specialties">
            <h3>Specialties</h3>
            <ul>
              {specialties.map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="separator"></div>
        <div className="booking-details">
          <div className="client-age">{clientage}</div>
          <div className="rate">
            <span className="figure">
              {currency} ${verification.hourlyrate}
            </span>
            <span className="persession">per session</span>
          </div>
          <div className="availability">
            <span>Available </span>
            <span className="when">{availability}</span>
          </div>
          <Link
            to={"/practitioner/profilepage/" + _id}
            className="book-button lumi-btn"
          >
            Book Now
          </Link>
        </div>
      </div>
    </div>
  );
}

function GridViewCard({
  _id,
  firstname,
  lastname,
  toprated,
  photo,
  rating,
  maxrating,
  position,
  message,
  categories: specialties,
  clientage,
  rate,
  currency,
  availability,
  reviewscount,
  verification,
}) {
  return (
    <div className="practitioner-card-border" key={_id}>
      <div className="practitioner-card">
        <div className="photo-container">
          <img className="photo" src={photo}></img>
          {toprated && <div className="top-badge">TOP RATED</div>}
          <RatingWidget rating={rating} maxrating={maxrating}></RatingWidget>
          <Link className="reviews-link">
            {reviewscount ? reviewscount : 0} Verified Reviews
          </Link>
        </div>
        <div className="more-info">
          <div className="name">{`${firstname} ${lastname}`}</div>
          <div className="position">{position}</div>
          <div className="message">{message}</div>
        </div>
        <div className="specialties">
          <h3>Specialties</h3>
          <ul>
            {specialties.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
        </div>
        <div className="separator"></div>
        <div className="booking-details">
          <div className="left">
            <div className="client-age">{clientage}</div>
            <div className="rate">
              <span className="figure">
                {currency} ${verification.hourlyrate}
              </span>
              <span className="persession">per session</span>
            </div>
            <div className="availability">
              <span>Available </span>
              <span className="when">{availability}</span>
            </div>
          </div>
          <div className="right">
            <Link
              to={"/practitioner/profilepage/" + _id}
              className="book-button lumi-btn"
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function MobileViewCard({
  _id,
  firstname,
  lastname,
  toprated,
  photo,
  rating,
  maxrating,
  position,
  message,
  categories: specialties,
  clientage,
  rate,
  currency,
  availability,
  reviewscount,
  verification,
}) {
  return (
    <div className="practitioner-card-border" key={_id}>
      <div className="practitioner-card">
        <div className="photo-container">
          <img className="photo" src={photo}></img>
          {toprated && <div className="top-badge">TOP RATED</div>}
        </div>
        <div className="more-info">
          <div className="name">{`${firstname} ${lastname}`}</div>
          <div className="position">{position}</div>
          <RatingWidget rating={rating} maxrating={maxrating}></RatingWidget>
          <Link className="reviews-link">
            {reviewscount ? reviewscount : 0} Verified Reviews
          </Link>
        </div>
        <div className="message">{message}</div>
        <div className="specialties">
          <h3>Specialties</h3>
          <ul>
            {specialties.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
        </div>
        <div className="separator"></div>
        <div className="booking-details">
          <div className="left">
            <div className="client-age">{clientage}</div>
            <div className="rate">
              <span className="figure">
                {currency} ${verification.hourlyrate}
              </span>
              <span className="persession">per session</span>
            </div>
          </div>
          <div className="right">
            <div className="availability">
              <span>Available </span>
              <span className="when">{availability}</span>
            </div>
            <Link
              to={"/practitioner/profilepage/" + _id}
              className="book-button lumi-btn"
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function PractitionersList({ mode, nameOrder }) {
  let practitioners = nameOrder;
  const isMobile = useIsMobile();

  //Return empty if list is null to avoid crash.
  if (!nameOrder) {
    return <></>;
  }

  practitioners = practitioners.map((item) => {
    let practitioner = item;

    if (!practitioner.photo) practitioner.photo = Avatar;

    for (const key in practitioner) {
      try {
        if (["message", "background", "position", "specialties"].includes(key))
          practitioner[key] = parse(practitioner[key]);
      } catch (e) {}
    }
    return practitioner;
  });

  //If mobile view, override mode selected from UI.
  if (isMobile) mode = "mobile";

  return (
    <div className={`practitioners-${mode} col-12`}>
      {practitioners && practitioners.length > 0 ? (
        practitioners.map((item) => {
          if (isMobile) {
            return <MobileViewCard {...item} key={item._id}></MobileViewCard>;
          }
          if (mode == "list") {
            return <ListViewCard {...item} key={item._id}></ListViewCard>;
          } else {
            return <GridViewCard {...item} key={item._id}></GridViewCard>;
          }
        })
      ) : (
        <div align="center"> No matches.</div>
      )}
    </div>
  );
}

export default PractitionersList;
