import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import usePasswordEye from "../../hooks/usePasswordEye";
import Header from "../../components/Header/index.js";
import { Link } from "react-router-dom";
import { useUserStore } from "../../store/userStore";
import {
  Name,
  Email,
  Password,
  Password2,
  Actions,
  ErrorMessage,
} from "./form";
import { validateEmail } from "../../utils/utilityFunctions";
import useLoader from "../../hooks/useLoader";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

function ClientSignUp() {
  const { icon, clickHandler, isReadable } = usePasswordEye();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [role, setRole] = useState("client");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { showLoader, showMessage, closeMessages, showError } = useLoader();

  const doRegistration = useUserStore((state) => state.doRegistration);

  const passwordIsMatch = () => {
    return password == password2 ? false : true;
  };

  return (
    <>
      <Header></Header>
      <div className="client-signup">
        <div className="header">
          <div>
            <img id="logo" src={logo}></img>
          </div>
        </div>
        <div className="row m-0">
          <div className="left col-6">
            <h1>
              Helping women find <br />
              their inner shine.
            </h1>
            <h3>Loads of helpful humans all in one place.</h3>
            <div className="question">
              <h3>Are you a practitioner?</h3>
              <Link to="/practitioner-signup" className="join">
                Join the platform
              </Link>
            </div>
          </div>
          <div className="right col-6">
            <h3>Create An Account</h3>

            <Name
              firstName={firstname}
              lastName={lastname}
              onFirstNameChange={setFirstname}
              onLastNameChange={setLastname}
            />

            <Email value={email} onChange={setEmail}></Email>

            <div className="labels mt-4">
              Timezone<span className="required">*</span>
            </div>
            <div className="input-wrapper">
              <select>
                <option>Australian Eastern Daylight Time (GMT+11)</option>
                <option>Australian Eastern Daylight Time (GMT+11)</option>
                <option>Australian Eastern Daylight Time (GMT+11)</option>
                <option>Australian Eastern Daylight Time (GMT+11)</option>
                <option>Australian Eastern Daylight Time (GMT+11)</option>
              </select>
            </div>

            <Password
              value={password}
              onChange={setPassword}
              icon={icon}
              clickHandler={clickHandler}
              isReadable={isReadable}
            ></Password>

            <Password2
              value={password2}
              onChange={setPassword2}
              icon={icon}
              clickHandler={clickHandler}
              isReadable={isReadable}
            ></Password2>

            <ErrorMessage error={error} />

            {/*<div className="should-match">
              {passwordIsMatch() ? "Password should match" : ""}
            </div>*/}

            <div className="terms">
              By creating an account, you agree to Lumilife's
              <br />
              <Link>Terms of Service</Link> and <Link>Privacy Policy</Link>
            </div>

            <Actions
              onAdd={async () => {
                try {
                  setError("");

                  if (!firstname) {
                    setError("Please provide your first name.");
                    return;
                  }

                  if (!lastname) {
                    setError("Please provide your last name.");
                    return;
                  }

                  if (!email || !validateEmail(email)) {
                    setError("Please provide a valid email.");
                    return;
                  }

                  if (!password) {
                    setError("Please provide a password.");
                    return;
                  }

                  if (password != password2) {
                    setError("Passwords should match.");
                    return;
                  }

                  showLoader("Processing. Please wait..");
                  const res = await doRegistration(
                    "",
                    firstname,
                    lastname,
                    email,
                    password,
                    password2,
                    role
                  );
                  closeMessages();
                  if (res.error) {
                    showError(res.message);
                    return;
                  }

                  // setTimeout(() => navigate("/signin"), 8000); staging
                  setTimeout(() => navigate("/practitioner-login"), 8000);
                  showMessage("Success. Redirecting you to sign in.");
                } catch (error) {
                  console.log(error.message);
                  closeMessages();
                  showError("Something went wrong.");
                }
              }}
            ></Actions>

            <h5>
              <Link to="/signin">Sign In</Link> if you have an account.
            </h5>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
}

export default ClientSignUp;
