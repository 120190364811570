import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";

function AdminDashboardHome({ Component: component }) {
  return (
    <>
      <DashboardLayout>
        <div className="client-dashboard-home">
          <div className="title-bar">
            <h1>Admin Dashboard</h1>
            <div>
              <DateTimePill></DateTimePill>
            </div>
          </div>
          <div className="yellow-box">
          
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default AdminDashboardHome;
