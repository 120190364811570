import eye from "../assets/images/icon_eye.png";
import eyex from "../assets/images/icon_eyex.png";
import { useState } from "react";

function usePasswordEye() {
  const [isReadable, setReadable] = useState(false);

  return {
    icon: isReadable ? eyex : eye,
    isReadable: isReadable,
    clickHandler: () => {
      setReadable(!isReadable);
    },
  };
}

export default usePasswordEye;
