function FrameUCard({
  toggleFrameU,
  frameU,
  toggleFrameV,
  frameV,
  selectU1, 
  toggleSelectU1,
  selectU2, 
  toggleSelectU2,
  selectU3, 
  toggleSelectU3,
  selectU4, 
  toggleSelectU4,
  selectU5, 
  toggleSelectU5,
  selectU6, 
  toggleSelectU6,
  selectU7, 
  toggleSelectU7,
  selectU8, 
  toggleSelectU8,
  selectU9, 
  toggleSelectU9,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
     <h1>What would you like to get out of your sessions?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question"></div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectU1 ? "li-selected" : ""} onClick={toggleSelectU1} id='tools-strategies-to-live-a-better-life'>Tools & strategies to live a better life </li>
            <li className={selectU2 ? "li-selected" : ""} onClick={toggleSelectU2} id='help-with-improving-my-relationships'>Help with improving my relationships</li>
            <li className={selectU3 ? "li-selected" : ""} onClick={toggleSelectU3} id='improve-energy-and-or-regulating-my-moods'>Improve energy and/or regulating my moods</li>
            <li className={selectU4 ? "li-selected" : ""} onClick={toggleSelectU4} id='processing-feelings-or-my-past'>Processing feelings or my past </li>
            <li className={selectU5 ? "li-selected" : ""} onClick={toggleSelectU5} id='goal-setting-someone-to-keep-me-accountable'>Goal Setting - someone to keep me accountable</li>
            <li className={selectU6 ? "li-selected" : ""} onClick={toggleSelectU6} id='someone-to-listen-and-create-a-supportive-environment'>Someone to listen and create a supportive environment</li>
            <li className={selectU7 ? "li-selected" : ""} onClick={toggleSelectU7} id='gain-a-deeper-understanding-of-myself'>Gain a deeper understanding of myself</li>
            <li className={selectU8 ? "li-selected" : ""} onClick={toggleSelectU8} id='someone-to-stimulate-and-question-my-thoughts'>Someone to stimulate and question my thoughts</li>
            <li className={selectU9 ? "li-selected" : ""} onClick={toggleSelectU9} id='other'>Other</li>
          </ul>

          <div className="next" onClick={() => {
              console.log('answers::',answers);
              if (
              selectU1 ||
              selectU2 ||
              selectU3 ||
              selectU4 ||
              selectU5 ||
              selectU6 ||
              selectU7 ||
              selectU8 ||
              selectU9
            ) {
              updateProgressStatus()
              toggleFrameU();
              toggleFrameV();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameUCard;
