import { useUserStore } from "../store/userStore";
import { useEffect } from "react";

function useProfile() {
  const avatar = useUserStore((state) => state.avatar);
  const role = useUserStore((state) => state.profile.role);
  const firstName = useUserStore((state) => state.profile.firstname);
  const lastName = useUserStore((state) => state.profile.lastname);
  const name = useUserStore((state) => state.profile.name);
  const jwtData = useUserStore((state) => state.jwtData);
  const _id = useUserStore((state) => state.profile._id);
  const photo = useUserStore((state) => state.profile.photo);
  const token = useUserStore((state) => state.jwtData);
  const coach = useUserStore((state) => state.profile.coach);

  const doUpdateProfilePhoto = useUserStore((state) => state.doUpdateProfilePhoto);

  useEffect(() => {
    doUpdateProfilePhoto();
  }, []);


  return { firstName, lastName, role, jwtData, _id, photo, token, coach, name, avatar, doUpdateProfilePhoto };
}

export default useProfile;
