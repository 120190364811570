function FrameECard({
  toggleFrameE,
  frameE,
  toggleFrameK,
  frameK,
  selectE1, 
  toggleSelectE1,
  selectE2, 
  toggleSelectE2,
  selectE3, 
  toggleSelectE3,
  selectE4, 
  toggleSelectE4,
  selectE5, 
  toggleSelectE5,
  selectE6, 
  toggleSelectE6,
  selectE7, 
  toggleSelectE7,
  selectE8, 
  toggleSelectE8,
  selectE9, 
  toggleSelectE9,
  selectE10, 
  toggleSelectE10,
  selectE11, 
  toggleSelectE11,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const appendAnswers = (e) => {
    toggleAnswers(e);
  };
  return (
    <div>
    <h1>Have you ever been diagnosed with or have any of the following mental health conditions or personality disorders?</h1>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">
         
          </div>
          <div className="description">Select one or multiple</div>
          <ul className="two-col-ul" onClick={appendAnswers}>
            <li className={selectE11 ? "li-selected" : ""} onClick={toggleSelectE11} id='no-prefer-not-to-say'>No/Prefer not to say</li>
            <li className={selectE1 ? "li-selected" : ""} onClick={toggleSelectE1} id='general-anxiety-disorder'>General anxiety disorder</li>
            <li className={selectE2 ? "li-selected" : ""} onClick={toggleSelectE2} id='depression'>Depression</li>
            <li className={selectE3 ? "li-selected" : ""} onClick={toggleSelectE3} id='adhd-add'>ADHD/ ADD</li>
            <li className={selectE4 ? "li-selected" : ""} onClick={toggleSelectE4} id='asd'>ASD</li>
            <li className={selectE5 ? "li-selected" : ""} onClick={toggleSelectE5} id='ocd'>OCD</li>
            <li className={selectE6 ? "li-selected" : ""} onClick={toggleSelectE6} id='bipolar-disorder'>Bipolar disorder</li>
            <li className={selectE7 ? "li-selected" : ""} onClick={toggleSelectE7} id='borderline-personality-disorder'>Borderline personality disorder</li>
            <li className={selectE8 ? "li-selected" : ""} onClick={toggleSelectE8} id='narcissistic-personality-disorder'>Narcissistic personality disorder</li>
            <li className={selectE9 ? "li-selected" : ""} onClick={toggleSelectE9} id='schizophrenia'>Schizophrenia</li>
            <li className={selectE10 ? "li-selected" : ""} onClick={toggleSelectE10} id='other-not-listed-here'>Other not listed here</li>
          </ul>

          <div className="next " onClick={() => {
            console.log('answers::',answers);
            if (selectE1 
              || selectE2
              || selectE3
              || selectE4
              || selectE5
              || selectE6
              || selectE7
              || selectE8
              || selectE9
              || selectE10
              || selectE11
            ) {
              updateProgressStatus()
              toggleFrameE(); // hide
              toggleFrameK();
            }
          }}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrameECard;
