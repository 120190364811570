import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  findPractitioner,
  findPractitionerProfile,
  updatePractitioner,
  fetchProfessions,
  fetchAllUsers
} from "../api";
import { getFilterType } from "../helpers/practitionerHelper";
import { createJSONStorage } from "zustand/middleware";

const initialState = {
  nameOrder: [],
  professions: [],
  users: [],
};

const store = (set, get) => ({
  //state
  ...initialState,
  doReset: () => set(initialState),
  doFetch: async (filters, sort) => {
    try {
      let fetchResult = await findPractitioner({
        filters,
        sort,
      });

      set({
        nameOrder: fetchResult.result,
      });
      return fetchResult;
    } catch (e) {
      const errorMessage = `User Store Error [doFetch]: ${e.message}`;
      console.log("doFetch", errorMessage);
      throw Error(errorMessage);
    }
  },
  doFetchProfessions: async () => {
    try {
      let fetchResult = await fetchProfessions({});
      set({
        professions: fetchResult.result,
      });
      return fetchResult;
    } catch(e) {
      const errorMessage = `Professions Store Error [doFetch]: ${e.message}`;
      console.log("doFetch", errorMessage);
      throw Error(errorMessage);
    }
  },
  doFetchUsers: async () => {
    try {
      let fetchResult = await fetchAllUsers({});
      set({
        users: fetchResult.result,
      });
      return fetchResult;
    } catch(e) {
      const errorMessage = `Professions Store Error [doFetch]: ${e.message}`;
      console.log("doFetch", errorMessage);
      throw Error(errorMessage);
    }
  },
  doFetchPractitioner: async (filters) => {
    try {
      let fetchResult = await findPractitionerProfile({
        filters,
      });
      return fetchResult;
    } catch (e) {
      const errorMessage = `Practitioner Store Error [doFetchPractitioner]: ${e.message}`;
      console.log("doFetchPractitioner", errorMessage);
      throw Error(errorMessage);
    }
  },
  doUpdatePractitioner: async (data) => {
    try {
      console.log(data);
      let fetchResult = await updatePractitioner({
        data,
      });
      return fetchResult;
    } catch (e) {
      const errorMessage = `Practitioner Store Error [doFetchPractitioner]: ${e.message}`;
      console.log("doFetchPractitioner", errorMessage);
      throw Error(errorMessage);
    }
  },
});

export const usePractitionerStore = create(devtools(immer(store)));
