import useDateTimePill from "../../hooks/useDateTimePill";

function DateTimePill() {
  const { date, day, time } = useDateTimePill();

  return (
    <div className="datetime-pill">
      <div className="date">{date}</div>
      <div className="day">{day}</div>
      <div className="time">{time}</div>
    </div>
  );
}

export default DateTimePill;
