function AnswersACard({
  pracLists,
  updateProgressStatus,
  toggleAnswers,
  answers
}) {
  const convertToText = (str) => {
    return str.replace(/-/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase());
    // return y.replace('-', ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase());
}
  return (
    <div>
      <div className="get-started-box">
        <div className="get-started-box-content">
          <div className="question">Practitioner Profession list:</div>
          <div className="description">
            {pracLists.map((list, index, array) => {
              return <div>{list}</div>;
            })}
          </div>

          <div className="question">User Answers:</div>
          <div className="description">
            {answers.map((list, index, array) => {
              return <div>{convertToText(list)}</div>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnswersACard;
