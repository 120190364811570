import asterisk from "../../assets/images/asterisk_noborder.png";

function QuoteBox() {
  return (
    <div className="quote-box">
      <img src={asterisk}></img>Helping women find their inner shine
    </div>
  );
}

export default QuoteBox;
